import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import { __ } from "services/Translator";

export default function RecoverPassword() {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (formData.email < 2 || formData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }
    if (!emailValidate(formData.email)) {
      errorsTmp = { ...errorsTmp, email: __("Your email not valid") };
      valid = false;
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const [acceptField, setAcceptField] = useState({
    email: false,
  });

  const formInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: __("Your email not valid") });
      }
    }

    updateFormData(field, value);
  };
  const [formData, setFormData] = useState({
    email: "",
    code: "",
    id: "",
  });

  const sendAction = () => {
    var valid = validateForm();
    if (valid) {
      swal({
        title: __("Done!"),
        text: __("instructions has been sent to your email."),
        icon: "success",
      });
      history.push("/login");
    }
  };
  console.log("Data", formData);
  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-10 col-md-8 col-lg-4 offset-1 offset-col-1 offset-sm-1 offset-md-2 offset-lg-4">
            <div className="form_area">
              <div className="common_headline text-center">
                {__("Forgot Your Password?")}
              </div>
              <br />
              <div className="input_item">
                <div className="name text-center reg_field">
                  {__("Enter your registered email address below and password reset instructions will be sent to you.")}
                </div>
              </div>

              <div className="input_item">
                <div className="name reg_field">{__("Email")}</div>
                <input
                  onChange={formInputChange}
                  type="email"
                  name="email"
                  className={
                    acceptField.email
                      ? "form-control name_value border_success"
                      : "form-control name_value"
                  }
                  placeholder={__("Your email")}
                  value={formData.email}
                />
                <div className="error_msg">{errors.email}</div>
              </div>
              <div className="login_button">
                <Button onClick={sendAction} className="login_btn">
                  {__("Send")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
