import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import { __ } from "services/Translator";

export default function PromotionDetails(props) {
  const params = useParams();
  var id = parseInt(params.id);

  const [promotions, setPromotions] = useState([]);
  const [pageContent, setPageContent] = useState({});
  const getResults = async () => {
    var response = await api.get("/promotions");

    if (response.status === 200) {
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const [mainPromo, setMainPromo] = useState({});
  const [otherPromos, setOtherPromos] = useState([]);

  const sortPromotions = () => {
    var mainPromo = promotions.filter(
      (item, index) => item.promotion_id === id
    );
    var otherPromos = promotions.filter(
      (item, index) => item.promotion_id !== id
    );
    if (mainPromo.length) {
      setMainPromo(mainPromo[0]);
    } else {
      setMainPromo([]);
    }
    setOtherPromos(otherPromos);
  };

  useEffect(() => {
    getResults();
  }, []);

  useEffect(() => {
    sortPromotions();
  }, [id, promotions]);

  return (
    <div className="promotions">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <img className="promotions_details_image" src={mainPromo.image} />
          </div>
          <div className="col-12 col-md-6">
            <h3 className="dark_white weight_bold">{mainPromo.title}</h3>
            <div className="paragraph dark_white">
              <h4>{mainPromo.subtitle}</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(mainPromo.details),
                }}
              ></p>
              <Link className="terms_and_condition_link" to="/terms-and-conditions/bonus-terms">
                {__("Terms and conditions")}
              </Link>
            </div>

            <Link to="/dashboard/cashier" className="load_more_btn ">
              {__("Deposit")}
            </Link>
          </div>
        </div>
        {!!otherPromos.length && (
          <div className="row">
            <div className="col-12 col-md-8">
              <br />
              <div className="paragraph">{pageContent.page_title}</div>
              <div
                className="paragraph"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(pageContent.content),
                }}
              ></div>

              <Link className="terms_and_condition_link" to="/terms-and-conditions/bonus-terms">
                {__("Terms and conditions")}
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <div className="other_active_promotion_block">
                <h4 className="dark_white">{__("Other active promotions")}</h4>
                {otherPromos.map((item, index) => (
                  <div className="single_item">
                    <Link to={"/promotion/" + item.promotion_id}>
                      <h6 className="dark_white">{item.title}</h6>
                      <img
                        className="promotions_details_image"
                        src={item.image}
                      />
                    </Link>
                  </div>
                ))}

                <Link className="vip_promotion" to="#">
                  <div className="vip_promotion_text">
                    <div className="heading">{__("Exclusive VIP Space Journey")}</div>
                    <span className="description">
                      {__("Join our VIP Team in the Ultimate Crypto Journey Trough Space, Receive Astronomical Rewards that propel you to the MOON")}
                      
                    </span>
                  </div>
                  <div className="vip_promotion_image">
                    <img
                      src={require("assets/images/icons/rocket.png").default}
                    />
                  </div>
                </Link>
                <Link className="all_promotion_link" to="/promotions">
                  {__("VIEW ALL PROMOTIONS")}
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
