import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import { __ } from "services/Translator";

export default function Register() {
  const history = useHistory();

  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const registerValidateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (registerData.first_name < 2 || registerData.first_name.length > 250) {
      errorsTmp = { ...errorsTmp, first_name: __("Please enter first name") };
      valid = false;
    }
    if (registerData.last_name < 2 || registerData.last_name.length > 250) {
      errorsTmp = { ...errorsTmp, last_name: __("Please enter last name") };
      valid = false;
    }
    if (registerData.email < 2 || registerData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }
    if (!emailValidate(registerData.email)) {
      errorsTmp = { ...errorsTmp, email: __("Your email not valid") };
      valid = false;
    }
    if (registerData.password < 2 || registerData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter password") };
      valid = false;
    }

    if (registerData.accept_terms !== "yes") {
      errorsTmp = {
        ...errorsTmp,
        accept_terms: __("Please read & accept terms & privacy policy"),
      };
      valid = false;
    }
    if (registerData.accept_country_limit !== "yes") {
      errorsTmp = {
        ...errorsTmp,
        accept_country_limit: __("Please read & accept country policy"),
      };
      valid = false;
    }
    setErrors(errorsTmp);
    return valid;
  };

  const updateRegisterForm = (field, value) => {
    setRegisterData({ ...registerData, [field]: value });
  };

  const [acceptField, setAcceptField] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm_password: false,
  });

  const registerInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    // accept_country_limit: false,

    if (field == "accept_terms") {
      var checked = ev.target.checked;
      if (checked) {
        value = "yes";
      } else {
        value = "no";
      }
    }
    if (field == "accept_country_limit") {
      var checked = ev.target.checked;
      if (checked) {
        value = "yes";
      } else {
        value = "no";
      }
    }

    if (field == "first_name") {
      if (value.length > 2) {
        setAcceptField({ ...acceptField, first_name: true });
        setErrors({ ...errors, first_name: "" });
      } else {
        setAcceptField({ ...acceptField, first_name: false });
        setErrors({ ...errors, first_name: __("Please enter first name") });
      }
    }
    if (field == "last_name") {
      if (value.length > 2) {
        setAcceptField({ ...acceptField, last_name: true });
        setErrors({ ...errors, last_name: "" });
      } else {
        setAcceptField({ ...acceptField, last_name: false });
        setErrors({ ...errors, last_name: __("Please enter last name") });
      }
    }
    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: __("Your email not valid") });
      }
    }
    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            __("Your password must contain between 8 and 30 characters, including at least one number, one special character, one lowercase and one upercase. Do not use your email address or username as your password."),
        });
      }
    }
    if (field == "confirm_password") {
      var password = registerData.password;
      if (value === password) {
        setAcceptField({ ...acceptField, confirm_password: true });
        setErrors({ ...errors, confirm_password: "" });
      } else {
        setAcceptField({ ...acceptField, confirm_password: false });
        setErrors({ ...errors, confirm_password: __("password doesn't match") });
      }
    }

    updateRegisterForm(field, value);
  };
  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    accept_terms: "no",
    accept_country_limit: "no",
  });

  console.log("Data", registerData);

  const registerAction = () => {
    var valid = registerValidateForm();
    if (valid) {
      swal({
        title: __("Congratulations!"),
        text: __("Your account just created, please check your email and verify your account."),
        icon: "success",
      });
      history.push("/login");
    }
  };

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-10 col-md-8 col-lg-4 offset-1 offset-col-1 offset-sm-1 offset-md-2 offset-lg-4">
            <div className="form_area">
              <div className="common_headline text-center">{__("Sign up")}</div>
              <div className="login_button">
                <Button className="login_btn facebook_btn">
                  <i className="fab fa-facebook-square facebook_icon"></i> {__("Sign Up with Facebook")}
                </Button>
                <div className="input_item">
                  <div className="name or_text text-center">{__("or")}</div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="input_item">
                    <div className="name reg_field">{__("First Name")}</div>
                    <input
                      onChange={registerInputChange}
                      type="text"
                      name="first_name"
                      className={
                        acceptField.first_name
                          ? "form-control name_value border_success"
                          : "form-control name_value"
                      }
                      placeholder={__("First name")}
                      value={registerData.first_name}
                    />
                    <div className="error_msg">{errors.first_name}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="input_item">
                    <div className="name reg_field">{__("Last Name")}</div>
                    <input
                      onChange={registerInputChange}
                      type="text"
                      name="last_name"
                      className={
                        acceptField.last_name
                          ? "form-control name_value border_success"
                          : "form-control name_value"
                      }
                      placeholder={__("Last name")}
                      value={registerData.last_name}
                    />
                    <div className="error_msg">{errors.last_name}</div>
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="name reg_field">{__("Email")}</div>
                <input
                  onChange={registerInputChange}
                  type="email"
                  name="email"
                  className={
                    acceptField.email
                      ? "form-control name_value border_success"
                      : "form-control name_value"
                  }
                  placeholder={__("Your email")}
                  value={registerData.email}
                />
                <div className="error_msg">{errors.email}</div>
              </div>
              <div className="input_item">
                <div className="name reg_field">{__("Password")}</div>
                <input
                  onChange={registerInputChange}
                  type="password"
                  name="password"
                  className={
                    acceptField.password
                      ? "form-control name_value border_success"
                      : "form-control name_value"
                  }
                  placeholder={__("Create a password")}
                  value={registerData.password}
                />
                <div className="error_msg">{errors.password}</div>
              </div>
              <div className="input_item">
                <div className="name reg_field">{__("Confirm Password")}</div>
                <input
                  onChange={registerInputChange}
                  type="password"
                  name="confirm_password"
                  className={
                    acceptField.confirm_password
                      ? "form-control name_value border_success"
                      : "form-control name_value"
                  }
                  placeholder={__("Confirm password")}
                  value={registerData.confirm_password}
                />
                <div className="error_msg">{errors.confirm_password}</div>
              </div>
              <div className="input_item">
                <div className="form-check">
                  <label
                    for="register_tos-accept-checkbox"
                    className="form-check-label"
                  >
                    <input
                      id="register_tos-accept-checkbox"
                      name="accept_terms"
                      className="form-check-input"
                      type="checkbox"
                      onChange={registerInputChange}
                      value={registerData.accept_terms}
                    />
                   {__("I confirm the following: I am at least 18 years old; I agree to the")}{" "}
                    <Link target="_blank" rel="noopener noreferrer" to="/terms">
                      {__("Terms of Use")}
                    </Link>{" "}
                    {__("and")}{" "}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="/privacy"
                    >
                      {__("Privacy Policy")}
                    </Link>{" "}
                    ({__("including, for California residents, the")}{" "}
                    <Link target="_blank" rel="noopener noreferrer" to="#">
                      {__("California Privacy Notice")}
                    </Link>
                    ); {__("I do not have any existing iCryptoGaming accounts. I understand iCryptoGaming has the right to cancel all duplicate accounts and withold winnings made with such accounts.")}
                  </label>
                </div>
                <div className="error_msg">{errors.accept_terms}</div>
              </div>
              <div className="input_item">
                <div className="form-check">
                  <label
                    for="register_region-accept-checkbox"
                    className="form-check-label"
                  >
                    <input
                      onChange={registerInputChange}
                      id="register_region-accept-checkbox"
                      name="accept_country_limit"
                      className="form-check-input "
                      type="checkbox"
                      value={registerData.accept_country_limit}
                    />
                    I understand that if I live outside United States or Canada, or within Washington State, Idaho or Quebec, I cannot redeem my Sweeps Coins due to local laws.
                  </label>
                </div>
                <div className="error_msg">{errors.accept_country_limit}</div>
              </div>
              <div className="login_button">
                <Button onClick={registerAction} className="login_btn">
                  {__("Create an Account")}
                </Button>
              </div>

              <div className="input_item pad_top">
                <div className="name text-center">
                  <Link to="/login">{__("Already have an account?")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
