import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "services/api";
import { Accordion } from "react-bootstrap";
import { __ } from "services/Translator";

export default function HelpLists(props) {
  const params = useParams();
  const [faqCategory, setFaqCategory] = useState(params.category);
  const [faqData, setFaqData] = useState([]);
  // const [defaultActiveFaq, setDefaultActiveFaq] = useState("account");
  // const [faqTitle, setFaqTitle] = useState("Account");

  const getFaq = async () => {
    var response = await api.post("/get-faqs-by-category", {
      supportCategory: faqCategory,
    });

    if (response.status === 200 && response.data) {
      setFaqData(response.data);
      console.log("response.dataresponse.data", response.data);
    }
  };

  useEffect(async () => {
    getFaq();
  }, []);

  return (
    <div className="help">
      <div className="help_banner">
        <div className="container">
          <div className="col-md-10 offset-md-1">
            
            <br />
            <h2 className="dark_white">
              {__("Advice and answers from the Blizz casino Team")}
            </h2>
            <br />
            <div className="searchbox">
              <input
                type="text"
                className="search_input"
                placeholder={__("Search for articles...")}
              />
              <div className="search_icon">
                <svg
                  version="1.1"
                  rotate="180"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  enableBackground="new 0 0 24 24"
                  space="preserve"
                >
                  <path
                    d="M23.849,22.147l-5.218-5.217c-0.097-0.099-0.225-0.15-0.365-0.15H17.7c1.354-1.568,2.174-3.609,2.174-5.844
							C19.874,6,15.874,2,10.937,2C6,2,2,6,2,10.937c0,4.938,4,8.938,8.937,8.938c2.234,0,4.275-0.82,5.844-2.174v0.565
							c0,0.141,0.058,0.269,0.15,0.365l5.217,5.218c0.2,0.201,0.527,0.201,0.73,0l0.971-0.971C24.05,22.675,24.05,22.348,23.849,22.147z
							 M10.937,17.812c-3.798,0-6.875-3.076-6.875-6.875c0-3.798,3.077-6.875,6.875-6.875c3.799,0,6.875,3.077,6.875,6.875
							C17.812,14.735,14.735,17.812,10.937,17.812z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="help_content">
        <div className="container">
          <div className="col-md-10 offset-md-1">
            <div className="help_category">
              {faqData != "" && (
                <>
                  <div className="bredcumb">
                    <span className="item">
                      <Link to="/help">{__("All Collections")}</Link>
                    </span>
                    <span className="item icon">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                    <span className="item">{faqData.category}</span>
                  </div>
                  <br />

                  <div className="faq_heading_area">
                    <div className="faq_icon">
                      {faqData.category_group == "account" && (
                        <svg viewBox="0 0 48 48">
                          <g
                            id="user-check"
                            stroke-width="2"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <path
                              d="M42 34l-6.05 6L32 35.99"
                              stroke-linecap="round"
                            ></path>
                            <path d="M25 29v-5.72m-10 .126V29L4.98 32.577A6 6 0 0 0 1 38.227V43h22"></path>
                            <path d="M29.976 13c0 6.627-4.476 12-10 12-5.522 0-10-5.373-10-12s4.478-12 10-12c5.524 0 10 5.373 10 12z"></path>
                            <path d="M29.938 11.938c-.336.04-.62-.004-.96.06-3.408.66-5.596-.576-7.47-3.857-1.125 2.16-4.642 3.86-7.53 3.86a8.035 8.035 0 0 1-3.873-.96M47 37c0 5.523-4.478 10-10 10-5.524 0-10-4.477-10-10s4.476-10 10-10c5.522 0 10 4.477 10 10z"></path>
                          </g>
                        </svg>
                      )}

                      {(faqData.category_group == "deposit" ||
                        faqData.category_group == "withdrawal") && (
                        <svg viewBox="0 0 48 48">
                          <g
                            id="biz-dollar"
                            stroke-width="2"
                            fill="none"
                            fillRule="evenodd"
                            stroke-linejoin="round"
                          >
                            <path d="M47 24c0 12.703-10.297 23-23 23C11.3 47 1 36.703 1 24 1 11.298 11.3 1 24 1c12.703 0 23 10.298 23 23z"></path>
                            <path
                              d="M19 29a5 5 0 0 0 5 5 5 5 0 0 0 0-10 5 5 0 0 1 0-10 5 5 0 0 1 5 5m-5-8v26"
                              stroke-linecap="round"
                            ></path>
                          </g>
                        </svg>
                      )}

                      {faqData.category_group == "bonus" && (
                        <svg role="img" viewBox="0 0 48 48">
                          <g
                            id="tools-dashboard"
                            stroke-width="2"
                            fill="none"
                            fillRule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M27 31a3 3 0 0 1-6 0 3 3 0 0 1 6 0zm-.88-2.12l9.9-9.9M5 32h4m34 .002L39 32m2.553-8.27l-3.696 1.53M31.27 13.447l-1.53 3.695M24 12v4m-7.27-2.553l1.53 3.695m-7.694.422l2.826 2.83M6.447 23.73l3.695 1.53"></path>
                            <path d="M24 8C11.297 8 1 18.3 1 31v9h46v-9C47 18.3 36.703 8 24 8z"></path>
                          </g>
                        </svg>
                      )}
                      {faqData.category_group == "games" && (
                        <svg role="img" viewBox="0 0 48 48">
                          <g
                            id="info-exclamation"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <path
                              d="M45.997 23.613c.21 12.15-9.847 22.172-21.997 22.383-12.148.212-21.786-9.468-21.996-21.617C1.792 12.23 11.85 2.203 24 1.992c12.15-.21 21.786 9.47 21.997 21.617zM24 26.993v-14"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M24 31.993h-.035a2 2 0 0 0 .035 4h.035a2 2 0 0 0-.034-4"
                              fill="#231F1F"
                            ></path>
                          </g>
                        </svg>
                      )}
                      {faqData.category_group == "other" && (
                        <svg role="img" viewBox="0 0 48 48">
                          <g
                            id="book-bookmark"
                            stroke-width="2"
                            fill="none"
                            fillRule="evenodd"
                            stroke-linecap="round"
                          >
                            <path d="M35 31l-6-6-6 6V7h12v24z"></path>
                            <path
                              d="M35 9h6v38H11a4 4 0 0 1-4-4V5"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M39 9V1H11a4 4 0 0 0 0 8h12"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </svg>
                      )}
                    </div>
                    <div className="faq_heading_details">
                      <div className="faq_category">{faqData.category}</div>
                      <div className="faq_category_sub">
                        {faqData.category_group == "account" && (
                          <b>{__("Account related questions")}</b>
                        )}
                        {faqData.category_group == "deposit" && (
                          <b>{__("Deposit related questions")}</b>
                        )}
                        {faqData.category_group == "withdrawal" && (
                          <b>{__("Withdrawal related questions")}</b>
                        )}
                        {faqData.category_group == "bonus" && (
                          <b>{__("Bonus related questions")}</b>
                        )}
                        {faqData.category_group == "games" && (
                          <b>{__("Games related questions")}</b>
                        )}
                        {faqData.category_group == "other" && (
                          <b>{__("Other related questions")}</b>
                        )}
                      </div>
                      <div className="faq_count_text">
                        {/* {faqData.faqs.length} articles in this collection */}
                      </div>
                    </div>
                  </div>

                  <div className="support_questions">
                    <ul>
                      {faqData.faqs.length > 0 &&
                        faqData.faqs.map((value, index) => (
                          <li>
                            <Link to={"/help-details/" + value.faq_group}>
                              {value.title}
                            </Link>
                            <h4 className="faq_dsc">
                              {value.description.substring(0, 100)} ...
                            </h4>
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              )}
              {/* <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion Item #1</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
