import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "services/api";
import { Accordion } from "react-bootstrap";
import { __ } from "services/Translator";

export default function HelpDetails(props) {
  const params = useParams();
  const [faqSlug, setFaqSlug] = useState(params.slug);
  const [faqData, setFaqData] = useState([]);
  // const [defaultActiveFaq, setDefaultActiveFaq] = useState("account");
  // const [faqTitle, setFaqTitle] = useState("Account");

  const getFaq = async () => {
    var response = await api.post("/get-faqs-details", {
      faqSlug: faqSlug,
    });

    if (response.status === 200 && response.data) {
      setFaqData(response.data);
      console.log("response.dataresponse.data", response.data);
    }
  };

  useEffect(async () => {
    getFaq();
  }, []);

  return (
    <div className="help">
      <div className="help_banner">
        <div className="container">
          <div className="col-md-10 offset-md-1">
            <div className="help_banner_top">
              
              <Link to="/">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#FFF"
                    fill="none"
                    fillRule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M11.5 6.73v6.77H.5v-11h7.615M4.5 9.5l7-7M13.5 5.5v-5h-5"></path>
                  </g>
                </svg>
                {__("Go to iCryptoGaming casino")}
              </Link>
            </div>
            <br />
            <h2 className="dark_white">
              {__("Advice and answers from the iCryptoGaming casino Team")}
            </h2>
            <br />
            <div className="searchbox">
              <input
                type="text"
                className="search_input"
                placeholder={__("Search for articles...")}
              />
              <div className="search_icon">
                <svg
                  version="1.1"
                  rotate="180"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  enableBackground="new 0 0 24 24"
                  space="preserve"
                >
                  <path
                    d="M23.849,22.147l-5.218-5.217c-0.097-0.099-0.225-0.15-0.365-0.15H17.7c1.354-1.568,2.174-3.609,2.174-5.844
							C19.874,6,15.874,2,10.937,2C6,2,2,6,2,10.937c0,4.938,4,8.938,8.937,8.938c2.234,0,4.275-0.82,5.844-2.174v0.565
							c0,0.141,0.058,0.269,0.15,0.365l5.217,5.218c0.2,0.201,0.527,0.201,0.73,0l0.971-0.971C24.05,22.675,24.05,22.348,23.849,22.147z
							 M10.937,17.812c-3.798,0-6.875-3.076-6.875-6.875c0-3.798,3.077-6.875,6.875-6.875c3.799,0,6.875,3.077,6.875,6.875
							C17.812,14.735,14.735,17.812,10.937,17.812z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="help_content">
        <div className="container">
          <div className="col-md-10 offset-md-1">
            <div className="help_category">
              {faqData != "" && (
                <>
                  <div className="bredcumb">
                    <span className="item">
                      <Link to="/help">{__("All Collections")}</Link>
                    </span>
                    <span className="item icon">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                    <span className="item">{faqData.category.category}</span>
                    <span className="item icon">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                    <span className="item">{faqData.title}</span>
                  </div>
                  <br />

                  <div className="faq_details_container">
                    <h1 className="faq_heading">{faqData.title}</h1>
                    <div className="faq_details">{faqData.description}</div>
                  </div>
                </>
              )}
              {/* <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion Item #1</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
