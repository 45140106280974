import React, { useState, useEffect, useRef } from "react";
import Banner from "elements/Banner";
import Flickity from "react-flickity-component";
import { Button } from "react-bootstrap";
import { __ } from "services/Translator";
import api from "services/api";
export default function AffiliateProgramme(props) {
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const [pageContent, setPageContent] = useState([]);
  const [pageUid, setPageUid] = useState("affiliate_programme");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: pageUid,
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <section className="about">
        <div className="container">
          <div className="about_content">
            <div className="about_article">
              <h1 className="dark_blue">
                <strong>
                  {pageContent != "" && pageContent.content_data.page_title}
                </strong>
              </h1>
              <div className="paragraph">
                {pageContent != "" &&
                  renderHTML(pageContent.content_data.content)}
              </div>
            </div>
          </div>
        </div>
      </section>

      
    </>
  );
}
