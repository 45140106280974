import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { __ } from "services/Translator";

export default function Terms(props) {
  const params = useParams();
  const topic = params.topic;
  const [topicTitle, setTopicTitle] = useState("Player Terms");

  return (
    <section className="about">
      <div className="container">
        <div className="about_content">
          <div className="about_menu">
            <div className="select_box  d-block d-lg-none">
              <DropdownButton className="select_btn" title={topicTitle}>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Player Terms");
                    }}
                    to="/terms-and-conditions/player-terms"
                  >
                    {__("Player Terms")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Affiliate Terms");
                    }}
                    to="/terms-and-conditions/affiliate-terms"
                  >
                    {__("Affiliate Terms")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bonus Terms");
                    }}
                    to="/terms-and-conditions/bonus-terms"
                  >
                    {__("Bonus Terms")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("VIP Terms");
                    }}
                    to="/terms-and-conditions/vip-terms"
                  >
                    {__("VIP Terms")}
                  </Link>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <ul className="d-none d-lg-block">
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Player Terms");
                  }}
                  className={topic === "player-terms" ? "active" : ""}
                  to="/terms-and-conditions/player-terms"
                >
                  {__("Player Terms")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Affiliate Terms");
                  }}
                  className={topic === "affiliate-terms" ? "active" : ""}
                  to="/terms-and-conditions/affiliate-terms"
                >
                  {__("Affiliate Terms")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bonus Terms");
                  }}
                  className={topic === "bonus-terms" ? "active" : ""}
                  to="/terms-and-conditions/bonus-terms"
                >
                  {__("Bonus Terms")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("VIP Terms");
                  }}
                  className={topic === "vip-terms" ? "active" : ""}
                  to="/terms-and-conditions/vip-terms"
                >
                  {__("VIP Terms")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="about_article">
            {topic === "player-terms" && (
              <div>
                <h1 className="dark_blue">
                  <strong>{__("Terms and Conditions")}</strong>
                </h1>
                <div className="paragraph">
                  The Terms and Conditions stipulated below are effective as of
                  May 26, 2014.
                  <br />
                  The Terms and Conditions were last updated on November 4,
                  2021.
                </div>
                <h4 className="dark_blue">1 GENERAL</h4>
                <div className="paragraph">
                  1.1 The website www.iCryptoGaming.com ('Casino', 'Website',
                  'Company', 'We', 'Us', 'Our') is owned and operated by Dama
                  N.V., a company registered and established under the laws of
                  Curacao. Dama N.V. is licensed and regulated by Antillephone
                  N.V. (license no. 8048/JAZ2020-013). Dama N.V.’s registration
                  number is 152125 and its registered address is Julianaplein
                  36, Willemstad, Curaçao. Strukin Ltd. is a subsidiary of Dama
                  N.V., acting as an Agent on behalf of Dama N.V., registered in
                  Cyprus with registered address Pavlov Nirvana &amp; Aipeias,
                  4, ALPHA TOWER, Floor 1, Flat 11, 3021, Limassol, Cyprus, and
                  registration number ΗΕ 407624.
                  <br />
                  Dama N.V. is officially registered by the Government of
                  Curaçao as an Information Provider legally authorised to
                  conduct online gaming operations from Curaçao. Remote gaming
                  licensing and supervision is provided by Antillephone N.V.
                </div>
                <div className="paragraph">
                  1.2 Before using the website www.iCryptoGaming.com, please read
                  Terms and Conditions carefully. The fact of using the website
                  confirms your consent with the Terms and Conditions.
                </div>
                <div className="paragraph">
                  1.3 These Terms and Conditions come into force as soon as you
                  click the check the box on signup, by which you signify to the
                  casino that you have read the Terms and Conditions, accept
                  them and are fully bound by them.
                </div>
                <div className="paragraph">
                  1.4 The casino reserves the right to modify and amend the
                  Terms and Conditions with or without notice at any time.
                </div>
                <div className="paragraph">
                  1.5 These Terms and Conditions may be published in several
                  languages for informational purposes and ease of access by
                  players. The English version is the only legal basis of the
                  relationship between you and us and in the case of any
                  discrepancy with respect to a translation of any kind, the
                  English version of these Terms and Conditions shall prevail.
                </div>
                <h4 className="dark_blue">2 ACCEPTED PLAYERS</h4>
                <div className="paragraph">
                  2.1 The Casino accepts strictly adult players (the minimum age
                  is 18) only from those countries and geographic regions where
                  online gambling is allowed by law.
                  <br />
                  The Company reserves the right to ask for proof of age from
                  the player and limit access to the Website or suspend the
                  Player Account to those players who fail to meet this
                  requirement.
                </div>
                <div className="paragraph">
                  2.2 You are solely responsible for determining whether your
                  accessing and/or use of the website is compliant with
                  applicable laws in your jurisdiction and you warrant to us
                  that gambling is not illegal in the territory where you
                  reside. Any claim against the Company brought by you for any
                  reason whatsoever in regard to the above mentioned will be
                  considered void and shall not be accepted.
                </div>
                <div className="paragraph">
                  2.3 Users from the following countries (“Restricted
                  Countries”) are not allowed to deposit and play real money
                  games: United States of America and its territories, United
                  Kingdom, Portugal, Slovakia, Spain, Israel, France and its
                  overseas territories (Guadeloupe, Martinique, French Guiana,
                  Réunion, Mayotte, French Polynesia, Wallis and Futuna, New
                  Caledonia), Netherlands, Lithuania, Dutch West Indies,
                  Curacao, Belgium. The Casino cannot guarantee successful
                  processing of withdrawals or refunds in the event that player
                  breaches this Restricted Countries policy.
                </div>
                <div className="paragraph">
                  Any bonuses are not available to players from Sweden,
                  including participation in any kind of promotional programs,
                  receiving VIP rewards, as well as exchange of comp points.
                </div>
                <div className="paragraph">
                  2.4 The website only accepts adult players (the minimum age is
                  18) and players who have reached the age specified by the
                  jurisdiction of player’s place of residence as eligible for
                  online gaming. It is the player’s sole responsibility to
                  inquire about the existing laws and regulations of the given
                  jurisdiction regarding the age limitation for online gambling.
                </div>
                <div className="paragraph">
                  2.4.1 The Company reserves the right to ask for the proof of
                  age from the player and limit access to the website or suspend
                  the player’s account to those players who fail to meet this
                  requirement.
                </div>
                <div className="paragraph">
                  2.5 Certain games may be unavailable in certain jurisdictions,
                  as required by policies of game providers which may change
                  from time to time.
                </div>
                <div className="paragraph">
                  2.5.1 Players from Canada are not allowed to play NYX
                  (NextGen) games.
                </div>
                <div className="paragraph">
                  2.5.2 NetEnt games are unavailable for _Afghanistan, Albania,
                  Algeria, Angola, Australia, Bahamas, Botswana, Cambodia,
                  Ecuador, Ethiopia, Ghana, Guyana, Hong Kong, Iran, Iraq,
                  Israel, Kuwait, Lao, Myanmar, Namibia, Nicaragua, North Korea,
                  Pakistan, Panama, Papua New Guinea, Philippines, Sri Lanka,
                  Singapore, Sudan, Switzerland, Syria, Taiwan, Trinidad and
                  Tobago, Tunisia, Uganda, Yemen, Zimbabwe,_ as well as Belgium,
                  Bulgaria, Czech Republic, Denmark, Estonia, France, Italy,
                  Latvia, Lithuania, Mexico, Portugal, Romania, Serbia,
                  Slovakia, Spain, United States of America and the United
                  Kingdom.
                </div>
                <div className="paragraph">
                  2.5.3 In addition to the above, Jumanji, Emoji planet, Guns
                  &amp; Roses, Jimi Hendrix &amp; Motörhead, Planet of the Apes,
                  Vikings and all other Netent Branded games are unavailable in
                  Australia, Azerbaijan, Canada, China, India, Indonesia,
                  Malaysia, Qatar, Russia, South Korea, Thailand, Tunisia,
                  Turkey, Ukraine. Additionally, Universal Monsters series
                  (Frankenstein, the Bride of Frankenstein, Dracula, The Mummy,
                  The Wolf Man, Creature from the Black Lagoon and The Invisible
                  Man) are only available in these countries: Andorra, Armenia,
                  Azerbaijan, Belarus, Bosnia and Herzegovina, Brazil, Georgia,
                  Iceland, Liechtenstein, Moldova, Monaco, Montenegro, Norway,
                  Russia, San Marino, Serbia, Switzerland, Ukraine, Croatia,
                  Macedonia, Turkey, Austria, Bulgaria, Cyprus, Czech Republic,
                  Finland, France, Germany, Greece, Hungary, Ireland, Latvia,
                  Lithuania, Luxembourg, Malta, the Netherlands, Peru, Poland,
                  Portugal, Slovakia, Slovenia, and Sweden.
                </div>
                <div className="paragraph">
                  2.5.4 Players from the following countries are not eligible to
                  win any jackpots from jackpot games offered by NetEnt:
                  Australia, Azerbaijan, China, Denmark, India, Israel, Italy,
                  Japan, Malaysia, Qatar, Russia, Portugal, Sweden, Slovakia,
                  Spain, Thailand, Tunisia, Turkey, United Arab Emirates,
                  Ukraine. The Casino will make reasonable efforts to prevent
                  players from these countries to reach the games, but if
                  players from any of the stated countries would win the
                  jackpot, the jackpot win will be annulled.
                </div>
                <div className="paragraph">
                  2.6 NetEnt’s privacy policy applies when playing casino slots
                  developed by NetEnt. It can be found here: NetEnt Privacy
                  Policy.
                </div>
                <div className="paragraph">
                  2.7 2.7 Using a proxy, VPN, or other artificial means of
                  spoofing your location is prohibited.
                </div>
                <h4 className="dark_blue">3 ACCEPTED CURRENCIES</h4>
                <div className="paragraph">
                  3.1 The website allows playing for the following currencies:
                  BTC (Bitcoin), BCH (Bitcoin Cash), LTC (Litecoin), ETH
                  (Ethereum), DOGE (Dogecoin), USDT (Tether USD).
                </div>
                <div className="paragraph">
                  3.2 ETH (Ethereum) and USDT (Tether USD) deposits and cashouts
                  are made through ERC-20 blockchain technology, and other
                  technologies are currently not supported. Smart Contract is
                  not supported for deposits and cashouts made in ETH (Ethereum)
                  and USDT (Tether USD), and Tokens are not supported when
                  sending ETH (Ethereum) transactions.
                </div>
                <div className="paragraph">
                  3.3 Currency exchange is NOT possible on-site. That means
                  that, once you deposit in one currency, you will only be able
                  to play games that accept said currency. Games that use other
                  currencies will not be playable.
                </div>
                <div className="paragraph">
                  3.4 Minimum deposit amounts for each currency are: 0.0001 for
                  BTC, 0.001 for BCH, 0.01 for ETH, 0.01 for LTC, 1 for DOGE,
                  and 1 for USDT (Tether USD). Please do NOT attempt to deposit
                  below the mentioned amounts, as the funds will not reach your
                  player account and will become permanently lost.
                </div>
                <div className="paragraph">
                  3.5 BTC can be used in games from the following providers:
                  Amatic, Belatra, Betsoft, BGaming, Booming, Endorphina,
                  Evolution, Evoplay Entertainment, Ezugi, GameArt, Habanero,
                  Isoftbet, KA Gaming, Lucky, Mascot, MrSlotty, Nolimit,
                  Onlyplay, Platipus, Play'n Go, Pragmatic Play, Pragmatic Play
                  Live, Spinomenal, Truelab, Wazdan, Zilion. BCH can be used in
                  games from the following providers: Belatra, Betsoft, BGaming,
                  Booming, Endorphina, Evolution, Evoplay Entertainment, Ezugi,
                  GameArt, Habanero, KA Gaming, Lucky, Mascot, MrSlotty,
                  Nolimit, Onlyplay, Platipus, Pragmatic Play Live, Spinomenal,
                  Truelab, Wazdan, Zilion. <br /> ETH can be used in games from
                  the following providers: Belatra, Betsoft, BGaming, Booming,
                  Endorphina, Evolution, Evoplay Entertainment, Ezugi, GameArt,
                  Habanero, KA Gaming, Lucky, Mascot, MrSlotty, Nolimit,
                  Onlyplay, Platipus, Pragmatic Play, Pragmatic Play Live,
                  Spinomenal, Truelab, Wazdan, Zilion. <br /> LTC can be used in
                  games from the following providers: Amatic, Belatra, Betsoft,
                  BGaming, Booming, Endorphina, Evolution, Evoplay
                  Entertainment, Ezugi, GameArt, Habanero, KA Gaming, Lucky,
                  Mascot, MrSlotty, Nolimit, Onlyplay, Platipus, Pragmatic Play,
                  Pragmatic Play Live, Spinomenal, Truelab, Wazdan, Zilion.
                  <br /> DOGE can be used in games from the following providers:
                  Belatra, Betsoft, BGaming, Booming, Endorphina, Ezugi,
                  GameArt, Habanero, KA Gaming, Lucky, Mascot, MrSlotty,
                  Nolimit, Onlyplay, Platipus, Pragmatic Play Live, Spinomenal,
                  Truelab, Wazdan, Zilion. <br /> USDT can be used in games from
                  the following providers: Belatra, Betsoft, BGaming, Booming,
                  Endorphina, Evoplay Entertainment, GameArt, Habanero, KA
                  Gaming, Lucky, Mascot, MrSlotty, Nolimit, Onlyplay, Platipus,
                  Pragmatic Play Live, Spinomenal, Truelab, Wazdan, Zilion.
                </div>
                <div className="paragraph">
                  3.6 Requesting a withdrawal towards your account's deposit
                  address is strictly prohibited. If you attempt to request a
                  withdrawal towards your account's deposit address, the
                  transaction will be flagged and your account may be
                  temporarily locked by the system.
                </div>
                <h4 className="dark_blue">4 FEES AND TAXES</h4>
                <div className="paragraph">
                  4.1 The player is fully responsible for paying all fees and
                  taxes applied to their winnings according to the laws of the
                  jurisdiction of player’s residence.
                </div>
                <h4 className="dark_blue">5 GAME RULES</h4>
                <div className="paragraph">
                  5.1 You confirm that you know and understand the rules of
                  games offered by the website.
                </div>
                <div className="paragraph">
                  5.2 It is at your discretion to know the payout percentage of
                  each game.
                </div>
                <div className="paragraph">
                  5.3 If we mistakenly credit your Member Account with winnings
                  that do not belong to you, whether due to a technical, error
                  in the pay-tables, or human error or otherwise, the amount
                  will remain our property and will be deducted from your Member
                  Account. If you have withdrawn funds that do not belong to you
                  prior to us becoming aware of the error, the mistakenly paid
                  amount will (without prejudice to other remedies and actions
                  that may be available at law) constitute a debt owed by you to
                  us. In the event of an incorrect crediting, you are obliged to
                  notify us immediately by email.
                </div>
                <div className="paragraph">
                  5.4 Delaying any game round in any game, including free spins
                  features and bonus features, to a later time when you have no
                  more wagering requirement and/or performing new deposit(s)
                  while having free spins features or bonus features still
                  available in a game is prohibited. Players doing so agree to
                  have their bonus and associated winnings declared void.
                </div>
                <h4 className="dark_blue">6 DISCLAIMER OF LIABILITIES</h4>
                <div className="paragraph">
                  6.1 You confirm that you are aware of the fact that gambling
                  at the website may lead to losing money. The Company is not
                  liable for any possible financial damage arising from the use
                  of the website.
                </div>
                <div className="paragraph">
                  6.2 The Company is taking effective measures to protect
                  player’s private data from any unauthorized use and is only
                  making it available to parties involved in providing of
                  gambling services through the website. Notwithstanding this,
                  the Company is not responsible for how the information is
                  further treated by third parties, for example third party
                  software providers or affiliates. Treatment of player’s
                  private data by such parties is subject to terms and
                  conditions of these parties, if any.
                </div>
                <div className="paragraph">
                  6.3 The Company is not liable of any hardware or software,
                  defects, unstable or lost Internet connections, or any other
                  technical errors that may limit player’s access to the website
                  or prevent player from an uninterrupted play.
                </div>
                <div className="paragraph">
                  6.4 In the unlikely case where a wager is confirmed or a
                  payment is performed by us in error, the Company reserves the
                  right to cancel all wagers accepted containing such an error,
                  or to correct the mistake by re-settling all the wagers at the
                  correct terms that should have been available at the time that
                  the wager was placed in the absence of the error.
                </div>
                <h4 className="dark_blue">7 PLAYER ACCOUNT</h4>
                <div className="paragraph">
                  7.1 Each player can create only one personal user account.
                  Creating multiple user accounts will lead to termination of
                  the accounts. All betting accounts you try to open may be
                  blocked or closed and any bets may be voided. Depending on the
                  severity of the case iCryptoGaming Casino holds the right to withhold
                  or take any funds deposited to pay for damages and/or the
                  prevention of future misconduct.
                </div>
                <div className="paragraph">
                  7.1.1 If you notice that you have more than one registered
                  Member Account you must notify us immediately. Failure to do
                  so may lead to your Member account being blocked for access.
                </div>
                <div className="paragraph">
                  7.2 Player’s account can only be accessed with the unique ID
                  and password of the players. The player is responsible for
                  keeping their login information confidential and making sure
                  it cannot be accessed by another person.
                </div>
                <div className="paragraph">
                  7.2.1 As part of the registration process, you will have to
                  choose a username and password for your login into the
                  Website(s). It is your sole and exclusive responsibility to
                  ensure that your login details are kept securely. You must not
                  disclose your login details to anyone. We are not responsible
                  for any abuse or misuse of your Member Account by third
                  parties due to your disclosure, whether intentional or
                  accidental, whether active or passive, of your login details
                  to any third party.
                </div>
                <div className="paragraph">
                  7.3 The player shall not provide access to his or her user
                  account or allow using the website to any third party,
                  including but not limited to minors.
                </div>
                <div className="paragraph">
                  7.4 The website can only be used for personal purposes and
                  shall not be used for any type of commercial profit.
                </div>
                <div className="paragraph">
                  7.5 The player must provide valid personal data during
                  registration, including valid telephone number.
                </div>
                <div className="paragraph">
                  7.6 iCryptoGaming Casino reserves the right to refuse or close a Member
                  Account at its sole discretion, but any contractual
                  obligations already made by iCryptoGaming Casino shall be honored
                  accordingly.
                </div>
                <div className="paragraph">
                  7.7 iCryptoGaming Casino reserves the right to declare a wager void,
                  partially or in full, if iCryptoGaming Casino, at its own discretion,
                  deems it obvious that there was an error, mistake, misprint or
                  technical error on the pay-table, odds or software.
                </div>
                <div className="paragraph">
                  7.8 If it is discovered that a deposit is the result of a
                  double-spend, it will be considered an act of fraud. In the
                  instance that a deposit you make to your iCryptoGaming player account
                  does not clear the Blockchain within three hours, it will be
                  considered fraud and our bitcoin payment processor will
                  automatically flag those transactions. Flagged transactions
                  will cause your player account to automatically be disabled.
                  All play associated with that deposit will be null and void
                  and your account will reset to the balance before the flagged
                  deposit. Likewise, if a deposit is intentionally delayed by
                  the player through the Blockchain, it will be considered
                  fraud. Additional stipulations to double-spends, delayed
                  transactions, and invalid play are outlined in Section 8.4 of
                  these Terms.
                </div>
                <div className="paragraph">
                  7.9 in relation to deposits and withdrawals of funds into and
                  from your Member Account, you shall only use Bitcoins that are
                  valid and lawfully belong to you.
                </div>
                <h4 className="dark_blue">8 DEPOSITING FUNDS</h4>
                <div className="paragraph">
                  8.1 To deposit funds into your Member Account, you can
                  transfer funds from your personal cryptocurrency wallets to
                  the corresponding wallets on your Deposit page. Please contact
                  our support team at support@iCryptoGaming.com or on live chat to
                  inquire about the payment methods which are most favorable for
                  your country of residence.
                </div>
                <div className="paragraph">
                  8.2 iCryptoGaming Casino does not accept third party payments. You must
                  make deposits only from a supported wallet for your currency
                  of choice which rightfully belongs to you. If we determine
                  during the security checks that you have violated this
                  condition your winnings will be confiscated and original
                  deposit returned to the owner of the payment account. The
                  casino is not responsible for the lost funds deposited from
                  third party accounts.
                </div>
                <div className="paragraph">
                  8.3 In the instance that a deposit is unable to be verified by
                  the Blockchain within a reasonable period, it will be
                  considered fraud. All play and winnings associated with that
                  deposit will be null and void. Your account will be
                  temporarily closed and all costs incurred by iCryptoGaming Casino,
                  including provider and platform fee retrieval, affiliate
                  commissions, and iCryptoGaming administrative costs associated with
                  these transactions will be noted on your account. A KYC is
                  required to reopen a suspended or deactivated account. In the
                  instance your account is reopened, all debts determined by
                  iCryptoGaming Casino must be satisfied before you may play Games or
                  make a withdrawal in the future
                </div>
                <div className="paragraph">
                  8.4 If we find a suspicious betting pattern upon review of
                  your play, that includes but is not limited to betting with a
                  deposit that was not confirmed at the moment of play, iCryptoGaming
                  Casino reserves the right to void all bets.
                </div>
                <div className="paragraph">
                  8.5 When effecting deposits into your Account, iCryptoGaming Casino
                  reserves the right to use additional procedures and means to
                  verify your identity.
                </div>
                <h4 className="dark_blue">9 WITHDRAWAL POLICY</h4>
                <div className="paragraph">
                  9.1 Withdrawals will be made to your stated wallet address. To
                  withdraw any funds which have been deposited, we require the
                  blockchain to give at a minimum 4 confirmations of your
                  deposit before a withdrawal can be made. If you deposit with a
                  small mining fee, the confirmations will take up to a couple
                  of hours.
                </div>
                <div className="paragraph">
                  9.2 If we mistakenly credit your Member Account with winnings
                  that do not belong to you, whether due to a technical error in
                  the pay-tables, human error, or otherwise, the amount will
                  remain property of us and the amount will be deducted from
                  your Member Account. If you have withdrawn funds that do not
                  belong to you prior to us becoming aware of the error, the
                  mistakenly paid amount will (without prejudice to other
                  remedies and actions that may be available at law) constitute
                  a debt owed by you to us. In the event of an incorrect
                  crediting, you are obliged to notify us immediately by email.
                </div>
                <div className="paragraph">
                  9.3 The payment team at iCryptoGaming casino reserve the right to carry
                  out additional verification procedures for any payout
                  exceeding the equivalent of 1 Bitcoin, and further reserves
                  the right to carry out such verification procedures in case of
                  lower payouts. Account Holders who wish to recover funds held
                  in a closed, locked or excluded account, are advised to
                  contact Customer Support. <br /> 9.3.a All cashouts requested
                  to a wallet address that was unused in our system before will
                  require confirmation via email before being processed. If you
                  did not receive an email notification, please contact our live
                  support team. Any cashouts without a confirmed wallet
                  addresses will be returned to the player's account.
                </div>
                <div className="paragraph">
                  9.4 All transactions shall be checked in order to prevent
                  money laundering. If the Member becomes aware of any
                  suspicious activity relating to any of the Games of the
                  Website, he must report this to the Company immediately. iCryptoGaming
                  Casino may suspend, block or close a Member Account and
                  withhold funds if requested to do so in accordance with the
                  Prevention of Money Laundering Act. Enhanced due diligence may
                  be done in respect of withdrawals of funds not used for
                  wagering. A standard 1x wagering requirement applies to
                  deposits before they become cashable while playing slot games.
                  A standard 10x wagering requirement applies to deposits before
                  they become cashable while playing table games and any other
                  games.
                </div>
                <div className="paragraph">
                  9.5 Please be advised that our products are consumed instantly
                  when playing. Thus, we cannot provide returns of goods,
                  refunds or cancellation of your service when playing. If you
                  play a game with Bitcoins or any other currency available
                  inside your account, the money will be drawn from your player
                  account instantly.
                </div>
                <div className="paragraph">
                  9.6 If you win 10 Bitcoins or more, we reserve the right to
                  divide the payout into ten instalments, paid with 10 percent
                  every month for 10 months until the full amount is paid out.
                  Maximum withdrawal amounts processed to a player within a
                  7-day period is 3 BTC (or currency equivalent), and 10 BTC
                  within a 30-day period, unless otherwise specified in the
                  promotional Terms &amp; Conditions, where exceptions can be
                  made to players with a higher VIP status and players who win a
                  progressive jackpot. This is determined solely at the
                  discretion of the casino.
                </div>
                <div className="paragraph">
                  9.7 You will not get any interest on outstanding amounts and
                  you shall not treat the Company as a financial institution.
                </div>
                <div className="paragraph">
                  9.8 If you are eligible for a bonus, that is for example a
                  login bonus or a deposit bonus of 110% up to a certain amount,
                  wagering requirements will apply before you are eligible to
                  make any cash-outs of the bonus or winnings. The wagering
                  requirements, which can vary, will be displayed when receiving
                  the bonus. If you would like to request a withdrawal before
                  the wagering requirements are fulfilled, iCryptoGaming Casino will
                  deduct the whole bonus amount as well as any winnings before
                  approving any withdrawal. Wagering at Video Poker, roulette
                  (any roulette), Black Jack (any Black Jack) or any other Table
                  Game does not count in the wagering requirements. iCryptoGaming Casino
                  reserves the right to impose, at our own discretion,
                  geographical limitations to individual bonus schemes. Local
                  wagering requirements may be applied. Bonuses/Free spins at
                  iCryptoGaming Casino can only be received once per household / IP.
                  Risk-free bets on any games do not qualify for iCryptoGaming Casino’s
                  wagering requirements.
                </div>
                <div className="paragraph">
                  9.9 The casino reserves the right to check player’s identity
                  (KYC procedure) prior to processing payouts and to hold
                  withdrawals for the time needed to check the player’s
                  identity. Failure or denial to pass the identity check will
                  result in confiscation of winnings and refund of the last
                  deposit.
                </div>
                <div className="paragraph">
                  9.10 It is the responsibility of the player to ensure that all
                  documents as a part of the KYC process are genuine. In case of
                  false personal data provided by the players, the withdrawal
                  will be refused and the user account will be terminated. The
                  player will be informed thereof by email. In case of the faked
                  or fraudulent documents provided will result in confiscation
                  of deposits and potential winnings of the player.
                </div>
                <div className="paragraph">
                  9.11 Player’s identity check may require a selfie, a selfie
                  with a note to the casino, a phone call or verification of a
                  player via live video call (Skype).
                </div>
                <div className="paragraph">
                  9.12 We reserve the right to make a phone call to the number
                  provided in your member account, which at your own discretion
                  can be a necessary part of the KYC procedure. In case the
                  provided number is incorrect, missing or false, or the player
                  will not answer the phone call we reserve the right to
                  confiscate your winnings or/and disable your member account.
                  We'll make reasonable efforts trying to contact you regarding
                  the withdrawal of the funds, but if we are not able to reach
                  you (by email or phone) in two weeks, the funds will be
                  retained by the casino.
                </div>
                <h4 className="dark_blue">10. REFUND POLICY</h4>
                <div className="paragraph">
                  10.1 A refund request will only be considered if it is
                  requested within the first twenty-four (24) hours of the
                  alleged transaction, or within thirty (30) calendar days if a
                  Player alleges that another individual has accessed his/her
                  Player Account.
                </div>
                <div className="paragraph">
                  10.2 If you have funding your account with a Credit Card we
                  reserve the right to pay all withdrawal requests up to the
                  total amount deposited as refunds against the purchases you
                  have made. If your withdrawals exceed the total amount
                  deposited, any excess amount will be paid to you via one of
                  our alternative methods available.
                </div>
                <div className="paragraph">
                  10.3 Before a refund is processed all bonuses and winnings in
                  your balance will be deducted prior to calculating the amount
                  to be refunded.
                </div>
                <div className="paragraph">
                  10.4 In case any Credit Card purchases are considered to carry
                  an unacceptable risk for security or legal reasons either by
                  our Payment processors or by the Casino, we will initiate
                  refunds for all such transactions back to the Сredit Сard, and
                  notify all the appropriate authorities and parties.
                </div>
                <h4 className="dark_blue">11 ANTI-FRAUD POLICY</h4>
                <div className="paragraph">
                  11.1 The Company has a strict anti-fraud policy and utilises
                  various anti-fraud tools and techniques. If the player is
                  suspected of fraudulent actions including, but not limited to:
                  <br />- participating in any type of collusion with other
                  players
                  <br />- development of strategies aimed at gaining of unfair
                  winnings
                  <br />- fraudulent actions against other online casinos or
                  payment providers
                  <br />- chargeback transactions with a credit card or denial
                  of some payments made
                  <br />- creating two or more accounts
                  <br />- other types of cheating
                  <br />
                  or becomes a bankrupt in the country of their residence, the
                  Company reserves the right to terminate such Player Account
                  and suspend all payouts to the player. This decision is at the
                  sole discretion of the Company and the player will not be
                  notified or informed about the reasons of such actions. The
                  Company also reserves the right to inform applicable
                  regulatory bodies of the fraudulent actions performed by the
                  player.
                </div>
                <div className="paragraph">
                  11.2 The Casino has zero tolerance to advantage play. Any
                  player who will try to gain advantage of casino welcome offers
                  or other promotions agrees that Company reserves the right to
                  void bonuses and any winnings from such bonuses, for the
                  reasons of:
                  <br />- use of stolen cards; <br />- chargebacks; <br />-
                  creating more than one account in order to get advantage from
                  casino promotions; <br />- providing incorrect registration
                  data; <br />- any other actions which may damage the Casino.
                </div>
                <div className="paragraph">
                  11.3 The Casino reserves the right to close your Player
                  Account and to refund to you the amount on your account
                  balance, subject to deduction of relevant withdrawal charges,
                  at Casino’s absolute discretion and without any obligation to
                  state a reason or give prior notice.
                </div>
                <div className="paragraph">
                  11.4 In order to verify player\`s account casino management
                  require documents (ID, payment systems, utility bills еtc) in
                  Latin or Cyrillic alphabet. In case player doesn’t have an
                  opportunity to provide documents in above-mentioned alphabets
                  casino reserves the right to demand video verification where
                  player shows his/her documents.
                </div>
                <div className="paragraph">
                  11.5 The Casino reserves the right to retain payments, if
                  suspicion or evidence exists of manipulation of the casino
                  system. Criminal charges will be brought against any user or
                  any other person(s) who has/have manipulated the casino system
                  or attempted to do so. The Casino reserves the right to
                  terminate and/or change any games or events being offered on
                  the Website.
                </div>
                <div className="paragraph">
                  11.6 Should you become aware of any possible errors or
                  incompleteness in the software, you agree to refrain from
                  taking advantage of them. Moreover, you agree to report to the
                  Casino any error or incompleteness immediately. Should you
                  fail to fulfill such obligations, the Casino has a right to
                  full compensation for all costs related to the error or
                  incompleteness, including any costs incurred in association
                  with the respective error/incompleteness and the failed
                  notification.
                </div>
                <div className="paragraph">
                  11.7 Please note that if you requested a withdrawal, but the
                  sum of bets made since last deposit is less than three (3)
                  times the size of that deposit, the Casino reserves the right
                  to charge you the costs of transaction processing for deposit
                  and withdrawals. This decision is at the sole discretion of
                  the Casino.
                </div>
                <div className="paragraph">
                  11.8 The casino is not a financial institution and thus should
                  not be treated as such. Your account will not bear any
                  interests and no conversion or exchange services (including
                  fiat-crypto exchange) will be offered at any time.
                </div>
                <h4 className="dark_blue">12 DORMANT ACCOUNTS</h4>
                <div className="paragraph">
                  12.1 If the player’s account stays inactive during 12 months
                  (the account holder performs no deposits, bets or
                  withdrawals), it is considered a Dormant Account. The casino
                  reserves the right to charge a monthly administrative fee of
                  10 EUR or cryptocurrency equivalent (or the current balance of
                  your Player Account, if less than 10 EUR) as long as the
                  balance of your Player Account remains positive. While the
                  account is dormant, you authorize iCryptoGaming casino to deduct the fee
                  from your Player Account at the beginning of the month
                  following the day on which your Player Account is deemed
                  dormant, and at the beginning of each subsequent month that
                  your Player Account remains dormant. The casino will stop
                  deducting the fee if the account balance is zero or if the
                  account is re-activated. If the player decides to re-activate
                  their Dormant Account, they shall contact casino support team.
                </div>
                <h4 className="dark_blue">13 PRIVACY AND SECURITY</h4>
                <div className="paragraph">
                  13.1 iCryptoGaming Casino is complying with the data protection and
                  privacy laws and is treating all customer information as
                  strictly confidential.
                </div>
                <div className="paragraph">
                  13.2 We collect players’ private information when players
                  register within our system, respond to our communication, or
                  contact our support team. The information may include player’s
                  name, date of birth, private address, email address, IP
                  address, credit and debit card details, and some other
                  information personal data. The personal data of players are
                  available to iCryptoGaming Casino employees, employees of the software
                  provider Dama N.V., to payment systems and other third parties
                  which assist iCryptoGaming Casino in providing gambling services to the
                  end users. All parties to whom the players’ information is
                  disclosed are treating it as confidential according to the
                  corresponding agreements and laws. The data is fully protected
                  from unauthorized access.
                </div>
                <div className="paragraph">
                  13.3 iCryptoGaming Casino is keeping information on players’ deposits,
                  winnings and cash-outs as strictly confidential and is not
                  disclosing it to any third parties without prior consent of
                  the player unless otherwise required by the law.
                </div>
                <div className="paragraph">
                  13.4 iCryptoGaming Casino is using players’ information for marketing
                  purposes. However, we respect players’ privacy. If players do
                  not wish to receive any promotional materials, they can choose
                  such an option during registration or unsubscribe at any time
                  when using iCryptoGaming Casino’s gambling services.
                </div>
                <div className="paragraph">
                  13.5 In case iCryptoGaming Casino discovers that a player has
                  participated in any type of fraudulent actions, such as game
                  manipulation, payment fraud, provision of false personal data,
                  money laundering, using stolen credit cards, etc., the company
                  reserves the right to disclose such player’s personal data to
                  the relevant authorities.
                </div>
                <div className="paragraph">
                  13.6 iCryptoGaming Casino is committed to provide 100% secure gambling
                  services to players to ensure that no data is stolen, lost or
                  misused. We implement the latest technological achievements to
                  provide for the safety of all personal data of the players.
                </div>
                <div className="paragraph">
                  13.7 We use 128-bit SSL (Secure Socket Layer), the technology
                  preferred by many legal and financial institutions, to
                  guarantee safety of all transactions performed on the website.
                </div>
                <div className="paragraph">
                  13.8 We only work with the most reputable and trustable
                  payment processing providers to make sure that players’
                  deposits and cashouts are handled carefully and according to
                  the corresponding standards.
                </div>
                <h4 className="dark_blue">14 COOKIE POLICY</h4>
                <div className="paragraph">
                  14.1 The iCryptoGaming Casino website is using cookies to store your
                  preferences and guarantee a more pleasant gaming experience.
                  For any custom privacy settings, players shall contact our
                  support team at
                  <a href="mailto:support@iCryptoGaming.com">
                    support@iCryptoGaming.com
                  </a>
                </div>
                <h4 className="dark_blue">15 RESPONSIBLE GAMING</h4>
                <div className="paragraph">
                  15.1 Gambling at an online casino is mostly done for
                  entertainment purposes. However, there is a certain percentage
                  of people who lose control over themselves while gambling.
                  Before starting to play, it is important to realize that
                  gaming shall not be viewed as a source of income or means of
                  recovery from debts. It is useful to keep track of the time
                  and the amount of money spent at an online casino daily.
                </div>
                <div className="paragraph">
                  15.2 If you think that you start spending more money than you
                  can afford, or gaming starts interfering with your normal
                  daily routines, the following measures may help:
                </div>
                <h4 className="dark_blue">SELF-EXCLUSION</h4>
                <div className="paragraph">
                  You may contact our Support Team at support@iCryptoGaming.com or
                  on live chat and inform us about your decision to stop
                  gambling at the website for a certain period of time. We will
                  take all measures to block your access to the website and make
                  sure that you receive no promotional materials.
                </div>
                <h4 className="dark_blue">EXTERNAL HELP</h4>
                <div className="paragraph">
                  You may contact any of the following organizations for
                  consultation and support:
                </div>
                <div className="paragraph">Gambling Anonymous</div>
                <div className="paragraph">GamCare</div>
                <div className="paragraph">Gambling Therapy</div>
                <div className="paragraph">
                  15.3 As a player, you have the opportunity to put a deposit /
                  wager / loss limit on your account. To clarify the
                  functionality of the loss limit, the loss is based on the
                  deposit made by the player and not winnings attributed to the
                  deposited amount. If a player for example deposits 50 mBTC,
                  has a loss limit of 10 mBTC and the player goes on and wins
                  1,000 mBTC, the player can still lose more than 10 mBTC of the
                  1,000 mBTC balance as it's based on the initial deposit
                  instead of the winnings.
                </div>
                <h4 className="dark_blue">16 PROTECTION OF MINORS</h4>
                <div className="paragraph">
                  16.1 iCryptoGaming is only accepting players who are at least 18
                  years old and uses all available methods to stop any attempts
                  of minors to register and play at our casino. The casino
                  reserves the right to ask for proof of identity and in case
                  the player has not reached the legal age to play, access to
                  the website will be denied. However, we realize that due to a
                  wide availability of Internet people under age still have a
                  chance to register and play at an online casino. We therefore
                  strongly encourage parents to cooperate in protecting their
                  children from free access to gaming websites. There is special
                  software that can help in this matter. Please visit the
                  following websites for more information.
                </div>
                <ul>
                  <li className="paragraph">
                    [http://www.netnanny.com/](http://www.netnanny.com/
                    "http://www.netnanny.com/")
                  </li>
                  <li className="paragraph">
                    [http://www.cyberpatrol.com/](http://www.cyberpatrol.com/
                    "http://www.cyberpatrol.com/")
                  </li>
                  <li className="paragraph">
                    [http://www.gamblock.com/](http://www.gamblock.com/
                    "http://www.gamblock.com/")
                  </li>
                  <li className="paragraph">
                    [http://www.solidoak.com/](http://www.solidoak.com/
                    "http://www.solidoak.com/")
                  </li>
                </ul>
                <h4 className="dark_blue">17. Complaints</h4>
                <div className="paragraph">
                  17.1 Casino is to acknowledge a complaint started by the
                  account holder only. It is forbidden to hand over or sell your
                  complaint to the third party. Casino will dismiss the
                  complaint if the matter is handed over to be conducted by the
                  third party and not the original account owner.
                </div>
                <div className="paragraph">
                  17.2 iCryptoGaming Casino will use best efforts to resolve a reported
                  matter promptly.
                </div>
                <div className="paragraph">
                  17.3 If, for some reason, you are not satisfied with the
                  resolution of your complaint by iCryptoGaming Casino, you may report a
                  complaint to Antillephone N.V. <br /> E-mail:
                  <a href="mailto:complaints@gaminglicences.com">
                    complaints@gaminglicences.com
                  </a>
                </div>
                <h4 className="dark_blue">18. Miscarried and aborted games</h4>
                <div className="paragraph">
                  18.1 The Company is not liable for any downtime, server
                  disruptions, lagging, or any technical or political
                  disturbance to the game play. Refunds may be given solely at
                  the discretion of the iCryptoGaming Casino management.
                </div>
                <div className="paragraph">
                  18.2 The Company shall accept no liability for any damages or
                  losses which are deemed or alleged to have arisen out of or in
                  connection with website or its content, including but without
                  limitation to: delays or interruptions in operation or
                  transmission, loss or corruption of data, communication or
                  lines failure, any person's misuse of the site or its content,
                  or any errors or omissions in content.
                </div>
                <div className="paragraph">
                  18.3 In the event of a Casino system malfunction all wagers
                  are void.
                </div>
                <div className="paragraph">
                  18.4 In the event a game is started but miscarried because of
                  a failure of the system, the Company shall refund the amount
                  wagered in the game to the User by crediting it to the User’s
                  Account or, if the account no longer exists, by paying it to
                  the User in an approved manner; and if the User has an accrued
                  credit at the time the game miscarried, credit to the User’s
                  Account the monetary value of the credit or, if the account no
                  longer exists, pay it to the User in an approved manner.
                </div>
                <h4 className="dark_blue">19. Limitation of Liability</h4>
                <div className="paragraph">
                  19.1 You enter the Website and participate in the Games at
                  your own risk. The Websites and the Games are provided without
                  any warranty whatsoever, whether express or implied.
                </div>
                <div className="paragraph">
                  19.2 Without prejudice to the generality of the preceding
                  provision, we, our directors, employees, partners, service
                  providers:
                </div>
                <div className="paragraph">
                  19.2.1 do not warrant that the software, Games and the
                  Websites are fit for their purpose;
                </div>
                <div className="paragraph">
                  19.2.2 do not warrant that the software, Games and the
                  Websites are free from errors;
                </div>
                <div className="paragraph">
                  19.2.3 do not warrant that the software, Games and the
                  Websites will be accessible without interruptions;
                </div>
                <div className="paragraph">
                  19.2.4 shall not be liable for any loss, costs, expenses or
                  damages, whether direct, indirect, special, consequential,
                  incidental or otherwise, arising in relation to your use of
                  the Websites or your participation in the Games.
                </div>
                <div className="paragraph">
                  19.3 You understand and acknowledge that, if there is a
                  malfunction in a Game or its interoperability, any bets made
                  during such malfunctioning shall be void. Funds obtained from
                  a malfunctioning Game shall be considered void, as well as any
                  subsequent game rounds with said funds, regardless of what
                  Games are played using such funds.
                </div>
                <div className="paragraph">
                  19.4 You hereby agree to fully indemnify and hold harmless us,
                  our directors, employees, partners, and service providers for
                  any cost, expense, loss, damages, claims and liabilities
                  howsoever caused that may arise in relation to your use of the
                  Website or participation in the Games.
                </div>
                <div className="paragraph">
                  19.5 To the extent permitted by law, our maximum liability
                  arising out of or in connection with your use of the Websites,
                  regardless of the cause of actions (whether in contract, tort,
                  breach of warranty or otherwise), will not exceed €100.
                </div>
                <h4 className="dark_blue">Community Chat Guidelines</h4>
                <div className="paragraph">
                  20.1 Be nice and respectful! Engage with other players in a
                  friendly and polite manner. Any comments that contain
                  discriminatory remarks of any kind, be it racist, sexist,
                  threatening, obscene, hateful or offensive will be deleted and
                  the player will be muted. We have zero tolerance towards
                  verbal abuse.
                </div>
                <div className="paragraph">
                  20.2 Don't spam! The community chat has a limited volume of
                  posts that can be displayed at a time so spamming will use
                  that up pretty quickly. Spam messages will be deleted and the
                  player will be muted.
                </div>
                <div className="paragraph">
                  20.3 Don't ask for loans from other players! Don't ask for
                  funds from other players and don't post any crypto addresses
                  with this intent. Such posts will be deleted on the spot and
                  the player will be muted.
                </div>
                <div className="paragraph">
                  20.4 Don't post personal account queries or bonus requests.
                  Any personal queries or complaints will be muted in order to
                  protect the chat for the community. These can only be
                  addressed and resolved individually with our Live Support
                  team. They are available 24/7 and more than happy to help.
                  Also, bonus and spins requests can only be made with the Live
                  Support team or via email.
                </div>
                <div className="paragraph">
                  20.5 Don't advertise other sites/services. This is a community
                  chat run by iCryptoGaming players for iCryptoGaming players. Any posts
                  containing advertisements of other sites and services
                  unrelated to iCryptoGaming will be deleted and the player will be
                  muted.
                </div>
                <div className="paragraph">
                  20.6 Don't disclose personal information of other players. You
                  made a friend here and they shared personal (contact)
                  information with you? Please don't share any of that on the
                  community chat, including but not limited to name, email
                  address, phone number, address, etc. Respect each other's
                  privacy!
                </div>
                <div className="paragraph">
                  20.7 Enjoy! Feel free to share game, music, book
                  recommendations, and cookie recipes with your new friends.
                </div>
              </div>
            )}
            {topic === "affiliate-terms" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Affiliate Terms &amp; Conditions</strong>
                </h1>
                <div className="paragraph">
                  By completing the affiliate application to the iCryptoGaming.com
                  Affiliate Program (the "Affiliate Program") and clicking " I
                  agree to the Terms and Conditions (the “Terms”)” within the
                  registration form, you (hereinafter the "Affiliate") hereby
                  agree to abide by all the terms and conditions set out in this
                  Agreement. The outlined commission structure in ARTICLE 20 of
                  this Agreement shall be deemed to form an integral part
                  thereof. iCryptoGaming.com reserves the right to amend, alter,
                  delete or extend any provisions of this Agreement, at any time
                  and at its sole discretion, without giving any advance notice
                  to the Affiliate subject to the Terms set out in this
                  Agreement. You hereby comply with:
                </div>
                <ol>
                  <li className="paragraph">
                    The participation in the Affiliate Program
                  </li>
                  <li className="paragraph">
                    The usage of the iCryptoGaming.com affiliate website and/or
                    iCryptoGaming.com marketing tools (as hereafter defined).
                  </li>
                  <li className="paragraph">
                    The condition that the acceptance of any affiliate
                    commissions from iCryptoGaming confirms your irrevocable
                    acceptance of this Agreement and any modifications thereto.
                  </li>
                </ol>
                <div className="paragraph">
                  Therefore you shall be obliged to continuously comply with the
                  Terms of this Agreement as well as to comply with the General
                  Terms and Conditions and Privacy Policy of the website
                  iCryptoGaming.com, as well as with any other from time to time
                  brought forward rules and/or guidelines. An Agreement between
                  the Company and the Affiliate shall be coming into effect on
                  the date when the affiliate application was approved.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 1</strong>
                </div>
                <div className="paragraph">Purpose:</div>
                <ol>
                  <li className="paragraph">
                    The Affiliate maintains and operates one or more websites on
                    the Internet (hereinafter collectively referred to as "the
                    Website"), and/or refers potential customers through other
                    channels.
                  </li>
                  <li className="paragraph">
                    This Agreement governs the terms and conditions which are
                    related to the promotion of the website www.iCryptoGaming.com
                    by the Affiliate, hereinafter referred to as
                    "iCryptoGaming.com", whereby the Affiliate will be paid a
                    commission as defined in this Agreement depending on the
                    traffic sent to iCryptoGaming.com and as subject to the Terms
                    in this Agreement.
                  </li>
                  <li className="paragraph">
                    The definition of the term Net Gaming Revenue can be found
                    within ARTICLE 20 of the Terms. In case of an introduction
                    of another product, or group of products in the future,
                    iCryptoGaming.com reserves the right to use an individual
                    definition of the term Net Gaming Revenue for each product.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 2</strong>
                </div>
                <div className="paragraph">Acceptance of an Affiliate:</div>
                <div className="paragraph">
                  The Company reserves the right to refuse any registration in
                  its sole and absolute discretion.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 3</strong>
                </div>
                <div className="paragraph">Qualifying Conditions:</div>
                <div className="paragraph">
                  The Affiliate hereby warrants that he/she:
                </div>
                <ul>
                  <li className="paragraph">
                    Is of legal age in the applicable jurisdiction in order to
                    agree to and to enter into an Agreement.
                  </li>
                  <li className="paragraph">
                    Is competent and duly authorized to enter into binding
                    Agreements.
                  </li>
                  <li className="paragraph">
                    Is the proprietor of all rights, licenses and permits to
                    market, promote and advertise iCryptoGaming.com in accordance
                    with the provisions of this Agreement.
                  </li>
                  <li className="paragraph">
                    Will comply with all applicable rules, laws and regulations
                    in correlation with the promotion of iCryptoGaming.com.
                  </li>
                  <li className="paragraph">
                    Fully understands and accepts the Terms of the Agreement.
                  </li>
                  <li className="paragraph">
                    Is responsible for ensuring that the laws applicable to them
                    do not prohibit them from participating in the COMPANY
                    Affiliate network and performing your obligations hereunder.
                  </li>
                  <li className="paragraph">
                    Will provide any documentation required by Company to verify
                    the Affiliate Application and to verify the Affiliate
                    Account information at any time during the term of the
                    Affiliate Agreement. This documentation may include but is
                    not limited to: bank statements, individual or corporate
                    identity papers and proof of address.
                  </li>
                  <li className="paragraph">
                    Will signup with a legitimate email address and will provide
                    us with a Telegram or other alternate means if
                    communication. Disposable email addresses are not accepted.
                  </li>
                </ul>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 4</strong>
                </div>
                <div className="paragraph">
                  Responsibilities and Obligations of the Company:
                </div>
                <ol>
                  <li className="paragraph">
                    The Company shall provide the Affiliate with all required
                    information and marketing material for the implementation of
                    the tracking link.
                  </li>
                  <li className="paragraph">
                    The Company shall administer the turnover generated via the
                    tracking links, record the revenue and the total amount of
                    commission earned via the link, provide the Affiliate with
                    commission statistics, and handle all customer services
                    related to the business. Unique tracking identification
                    code/s will be assigned to all referred customers.
                  </li>
                  <li className="paragraph">
                    The Company shall pay the Affiliate the amount due depending
                    on the traffic generated subject to the Terms of this
                    Agreement.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 5</strong>
                </div>
                <div className="paragraph">
                  Responsibilities and Obligations of the Affiliate
                </div>
                <div className="paragraph">The Affiliate hereby warrants:</div>
                <ol>
                  <li className="paragraph">
                    To use its best efforts to actively and effectively
                    advertise, market and promote iCryptoGaming.com as widely as
                    possible in order to maximize the benefit to the parties and
                    to abide by the guidelines of the Company as they may be
                    brought forward from time to time and/or as being published
                    online.
                  </li>
                  <li className="paragraph">
                    To market and refer potential players to iCryptoGaming.com at
                    its own cost and expense. The Affiliate will be solely
                    responsible for the distribution, content and manners of its
                    marketing activities. All of the Affiliate's marketing
                    activities must be professional, proper and lawful under
                    applicable laws and must be in accordance with this
                    Agreement.
                  </li>
                  <li className="paragraph">
                    To use only a tracking link provided within the scope of the
                    affiliate program, otherwise no warranty whatsoever can be
                    assumed for proper registration and sales accounting. Also,
                    not to change or modify in any way any link or marketing
                    material without prior written authorization from the
                    Company.
                  </li>
                  <li className="paragraph">
                    To be responsible for the development, operation, and the
                    maintenance of its website as well as for all material
                    appearing on its website. <br /> If the affiliate provides
                    users with false, misleading, obsolete information, we
                    reserve the right to lock the affiliate account and
                    terminate the partnership. The affiliate will be notified
                    through email regarding any promotions, bonus offers, or any
                    other changes made to the website. If the casino information
                    is not updated within 90 days from the time of the email, we
                    reserve the right to lock the Affiliate account and
                    terminate the partnership.
                  </li>
                  <li className="paragraph">
                    That it will not perform any act which is libelous,
                    discriminatory, obscene, unlawful or otherwise unsuitable or
                    which contains sexually explicit, pornographic, obscene or
                    graphically violent materials.
                  </li>
                  <li className="paragraph">
                    That it will not actively target any person who is under the
                    legal age for gambling.
                  </li>
                  <li className="paragraph">
                    That it will not actively target any jurisdiction where
                    gambling and the promotion thereof is illegal.
                  </li>
                  <li className="paragraph">
                    That it will not present its website in such a way that it
                    might evoke any risk of confusion with iCryptoGaming.com and/or
                    the Company and or convey the impression that the website of
                    the contracting party is partly or fully originated with
                    iCryptoGaming.com and/or the Company.
                  </li>
                  <li className="paragraph">
                    That it will not attempt to benefit from traffic not
                    generated in good faith. If you have any reasonable
                    suspicion that any Customer referred by you is in any way
                    associated to bonus abuse, money laundering, fraud, or other
                    abuse of remote gaming websites, you will immediately notify
                    us of this.
                  </li>
                  <li className="paragraph">
                    Without prejudice to the marketing material as may be
                    forwarded by the Company and/or made available online
                    through the website
                    [https://www.iCryptoGaming.com/](https://www.iCryptoGaming.com/
                    "https://www.iCryptoGaming.com/") the affiliate may not use
                    iCryptoGamingcasino.com or other terms, trademarks and other
                    intellectual property rights that are vested in the Company
                    unless the Company consents to such use in writing.
                  </li>
                  <li className="paragraph">
                    To set a strong password and keep the login details safe. It
                    is the Affiliate’s sole obligation and responsibility to
                    ensure that login details for the Affiliate Account are kept
                    confidential and secure at all times. <br /> Any
                    unauthorized use of your Affiliate Account resulting from
                    your failure to adequately guard your login information
                    shall be your sole responsibility, and you remain solely
                    responsible and liable for all activities occurring under
                    your Affiliate Account user ID and password (whether such
                    activity was undertaken by you or not). It is your
                    obligation to inform us immediately if you suspect illegal
                    or unauthorized use of your Affiliate Account.
                  </li>
                  <li className="paragraph">
                    That the Affiliate Program is intended for your direct
                    participation. Opening an Affiliate Account for a third
                    party, brokering or transferring an Affiliate Account is not
                    accepted. Affiliates wishing to transfer an account to
                    another beneficial owner must contact us and request
                    permission. You shall not open more than one Affiliate
                    Account without our prior written consent.
                  </li>
                  <li className="paragraph">
                    To be responsive to any emails, important announcements or
                    any other types of communication and will take action
                    accordingly within 30 days from the time we have reached out
                    to them. Failure to respond within the 30 days period may
                    lead to suspended Affiliate payments.
                  </li>
                  <li className="paragraph">
                    That it will not generate traffic to Blizzcasino.com by
                    illegal or fraudulent activity, particularly but not limited
                    to:
                  </li>
                  <ul>
                    <li className="paragraph">Sending spam.</li>{" "}
                    <li className="paragraph">Incorrect metatags.</li>
                    <li className="paragraph">
                      Registering as a player or making deposits directly or
                      indirectly to any player account through his tracker(s)
                      for its own personal use and/or the use of its relatives,
                      friends, employees or other third parties, or in any other
                      way attempt to artificially increase the commission
                      payable or to otherwise defraud the Company. Violation of
                      this provision shall be deemed to be fraud, any
                      commissions will be confiscated and the affiliate account
                      will be permanently suspended.
                    </li>
                  </ul>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 6</strong>
                </div>
                <div className="paragraph">Payment:</div>
                <ol>
                  <li className="paragraph">
                    The Company agrees to pay the Affiliate a commission based
                    on the Net Gaming Revenue generated from new customers
                    referred by the Affiliate’s website and/or other channel.
                    New customers are those customers of the Company who do not
                    yet have a gaming account and who access the Website via the
                    tracking link and who properly register and make bitcoin
                    transfers at least equivalent to the minimum deposit into
                    their Blizzcasino.com betting account. The commission shall
                    be deemed to be inclusive of value added tax or any other
                    tax if applicable.
                  </li>
                  <li className="paragraph">
                    The commission shall be a percentage of the NGR in
                    accordance with what is set out in the commission structures
                    for the particular product. The calculation is product
                    specific and it is set out in every product-specific
                    commission structure.
                  </li>
                  <li className="paragraph">
                    The commission is calculated at the end of each month and
                    payments shall be performed by the fifth of each calendar
                    month. Affiliate needs at least 3 depositing players
                    (active) before their first commission is paid. Minimum
                    withdrawals for affiliates is 0.02 BTC, €100 or currency
                    equivalent. Should you have earned a lesser amount, the
                    money will remain pending in the system until you have
                    collected enough money for us to process the cashout.
                  </li>
                  <li className="paragraph">
                    Due to strict anti money laundering requirements we require
                    one or more forms of documentation to verify your identity
                    before we can make a first payment out to you. If we do not
                    receive the required documentation within 180 days from the
                    time of request, your account will become dormant. <br />
                    Upon becoming dormant we will inform you through email and
                    allow a 90 days period for the documentation to be uploaded.
                    If the documentation is not provided within this 90 days
                    period, you forfeit all the funds present inside the
                    Affiliate account and the Affiliate account will be
                    terminated.
                  </li>
                  <li className="paragraph">
                    Payment of commissions shall be made only to the designated
                    affiliate account. Such account shall be submitted by the
                    affiliate under the „Commissions“ page in the „Affiliate
                    Program“ backend. If an error is made in calculating the
                    commission, the Company reserves the right to correct such
                    calculation at any time and will immediately pay out
                    underpayment or reclaim overpayment made to the Affiliate.
                  </li>
                  <li className="paragraph">
                    Acceptance of payment by the Affiliate shall be deemed to be
                    full and final settlement of the balance due for the period
                    indicated.
                  </li>
                  <li className="paragraph">
                    If the Affiliate disagrees with the balance due as reported,
                    it shall send an email to the Company within a period of
                    seven (7) days to affiliates@Blizzcasino.com and indicate the
                    reasons of such dispute. Failure to send an email within the
                    prescribed time limit shall be deemed to be considered as an
                    irrevocable acknowledgment of the balance due for the period
                    indicated.
                  </li>
                  <li className="paragraph">
                    The Company may delay payment of any balance to the
                    Affiliate for up to sixty (60) days, while it investigates
                    and verifies that the relevant transactions comply with the
                    provisions of the Terms.
                  </li>
                  <li className="paragraph">
                    No payment shall be due when the traffic generated is
                    illegal or contravenes any provision of these Terms.
                  </li>
                  <li className="paragraph">
                    You hereby agree that any Customer found to be a bonus
                    abuser, money launderer or fraudster or who assists in any
                    form of affiliate fraud (whether notified by you or later
                    discovered by us) does not count as a valid Customer under
                    the Affiliate Agreement, and thereby no Commission shall be
                    payable in relation to such Customers.
                  </li>
                  <li className="paragraph">
                    The Affiliate agrees to return all commissions received
                    based on fraudulent or falsified transactions, plus all
                    costs for legal causes or actions that may be brought
                    against the Affiliate to the fullest extent of the law.
                  </li>
                  <li className="paragraph">
                    For the sake of clarity the parties specifically agree that
                    upon termination of this Agreement by either party, the
                    Affiliate shall no longer be entitled to receive any payment
                    whatsoever from the Company, provided that payments already
                    due (earned and unpaid commissions) shall be paid out.
                  </li>
                  <li className="paragraph">
                    At the sole discretion of the Company the Affiliate may be
                    given the opportunity to restructure its commission
                    structure. Examples of alternative revenue sources could
                    include a Cost Per Acquisition (CPA) module. The Company
                    hereby states to the Affiliate that only one type of revenue
                    structure may be applied and it shall not be possible for
                    two different revenue structures to co-exist. Therefore once
                    an Affiliate opts to accept the Company’s offer for a
                    different revenue structure to the standard commission
                    structure outlined in ARTICLE 20, then the Affiliate is
                    aware and hereby agrees and understands that the new
                    proposed revenue structure shall replace his existing
                    commission structure in its entirety. Notwithstanding the
                    above, all the Affiliate’s obligations assumed under this
                    present Agreement will still continue to apply to the
                    Affiliate up until the termination of this Agreement and
                    thereafter in accordance with the terms and conditions
                    contained in this Agreement.
                  </li>
                  <li className="paragraph">
                    The Affiliate shall be exclusively responsible for the
                    payment of any and all taxes, levies, fees, charges and any
                    other money payable or due both locally and abroad (if any)
                    to any tax authority, department or other competent entity
                    by the Affiliate as a result of the commission generated
                    under this Agreement. The Company shall in no manner
                    whatsoever be held liable for any amounts unpaid but found
                    to be due by the Affiliate and the Affiliate hereby
                    indemnifies the Company in that regard.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 7</strong>
                </div>
                <div className="paragraph">Termination:</div>
                <ol>
                  <li className="paragraph">
                    This Agreement may be terminated by either party by giving a
                    thirty (30) days written notification to the other party.
                    Written notification may be given by an email.
                  </li>
                </ol>
                <div className="paragraph">
                  The contracting parties hereby agree that upon the termination
                  of this Agreement:
                </div>
                <ol>
                  <li className="paragraph">
                    The Affiliate must remove all references to Blizzcasino.com
                    from the Affiliate's websites and/or other marketing channel
                    and communications, irrespectively of whether the
                    communications are commercial or non-commercial.
                  </li>
                  <li className="paragraph">
                    All rights and licenses granted to the Affiliate under this
                    Agreement shall immediately terminate and all rights shall
                    revert to the respective licensors, and the Affiliate will
                    cease the use of any trademarks, service marks, logos and
                    other designations vested in the Company.
                  </li>
                  <li className="paragraph">
                    The Affiliate will be entitled only to those earned and
                    unpaid commissions as of the effective date of
                    termination;however provided, the Company may withhold the
                    Affiliate's final payment for a reasonable time to ensure
                    that the correct amount is paid. The Affiliate will not be
                    eligible to earn or receive commissions after this
                    termination date.
                  </li>
                  <li className="paragraph">
                    If this Agreement is terminated by the Company on the basis
                    of the Affiliate's breach, the Company shall be entitled to
                    withhold the Affiliate’s earned but unpaid commissions as of
                    the termination date as collateral for any claim arising
                    from such breach. It is further specified that termination
                    by the Company due to a breach by the Affiliate of any of
                    the clauses in this Agreement shall not require a notice
                    period and such termination shall have immediate effect upon
                    simple notification by the Company to the Affiliate.
                  </li>
                  <li className="paragraph">
                    The Affiliate must return to the Company any and all
                    confidential information (and all copies and derivations
                    thereof) in the Affiliate's possession, custody and control.
                  </li>
                  <li className="paragraph">
                    The Affiliate will release the Company from all obligations
                    and liabilities occurring or arising after the date of such
                    termination, except with respect to those obligations that
                    by their nature are designed to survive termination.
                    Termination will not relieve the Affiliate from any
                    liability arising from any breach of this Agreement, which
                    occurred prior to termination and/or to any liability
                    arising from any breach of confidential information even if
                    the breach arises at any time following the termination of
                    this Agreement. The Affiliate’s obligation of
                    confidentiality towards the Company shall survive the
                    termination of this Agreement.
                  </li>
                  <li className="paragraph">
                    a. If your Affiliate Account is inactive, your Agreement and
                    participation in the Affiliate Network will automatically
                    terminate.
                    <br />
                    b. In this Section, “Inactive” means where you have not
                    registered new FTD for 60 days or more where automatic
                    termination occurs, we will notify you through email in
                    order for you to withdraw your funds from the affiliate
                    account. If we do not receive any response from you within
                    30 days, you forfeit any funds present inside your account.
                    <br />
                    c. Due to exchange rate fluctuations, all funds should be
                    withdrawn within 90 days. if no withdrawals are requested in
                    90 days, we will notify the Affiliate through email and the
                    Affiliate needs to withdraw the funds within 30 days from
                    the time of notification. If the Affiliate does not manage
                    to withdraw within the 30 days period, you forfeit all the
                    funds present inside the Affiliate account and the Affiliate
                    account will be terminated.
                    <br />
                    d. If the Affiliate Account does not generate any revenue
                    and/or does not register FTD for 60 days, we reserve the
                    right to decrease the Affiliate commissions. The Affiliate
                    will be notified through email and needs to withdraw any
                    funds within 30 days. If we do not receive any response from
                    the Affiliate within 30 days, you forfeit any funds present
                    inside the account and further decrease the Affiliate
                    commissions. If the Affiliate remains inactive for 30 more
                    days after the second decrease of commissions, you forfeit
                    any remaining funds and terminate the Affiliate account.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 8</strong>
                </div>
                <div className="paragraph">Warranties:</div>
                <ol>
                  <li className="paragraph">
                    The Affiliate expressly acknowledges and agrees that the use
                    of the Internet is at its risk and that this affiliate
                    program is provided "as is" and "as available" without any
                    warranties or conditions whatsoever, express or implied. No
                    guarantee is made that it will make access to its website
                    possible at any particular time or any particular location.
                  </li>
                  <li className="paragraph">
                    The Company shall in no event be liable to the Affiliate or
                    anyone else for any inaccuracy, error or omission in, or
                    loss, injury or damage caused in whole or in part by
                    failures, delays or interruptions of the www.Blizzcasino.com
                    website or the affiliate program.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 9</strong>
                </div>
                <div className="paragraph">Indemnification:</div>
                <div className="paragraph">
                  The Affiliate agrees to defend, indemnify and hold the Company
                  and its affiliates, successors, officers, employees, agents,
                  directors, shareholders and attorneys, free and harmless from
                  and against any and all claims and liabilities, including
                  reasonable attorneys' and experts' fees, related to or arising
                  from:
                </div>
                <ol>
                  <li className="paragraph">
                    Any breach of the Affiliate's representations, warranties or
                    covenants under this Agreement.
                  </li>
                  <li className="paragraph">
                    The Affiliate's use (or misuse) of the marketing materials.
                  </li>
                  <li className="paragraph">
                    All conduct and activities occurring under the Affiliate's
                    user ID and password.
                  </li>
                  <li className="paragraph">
                    Any defamatory, libelous or illegal material contained
                    within the Affiliate’s website or as part of the Affiliate's
                    information and data.
                  </li>
                  <li className="paragraph">
                    Any claim or contention that the Affiliate’s website or the
                    Affiliate's information and data infringes any third party's
                    patent, copyright, trademark, or other intellectual property
                    rights or violates any third party's rights of privacy or
                    publicity.
                  </li>
                  <li className="paragraph">
                    Third party access or use of the Affiliate’s website or to
                    the Affiliate's information and data.
                  </li>
                  <li className="paragraph">
                    Any claim related to the Affiliate website.
                  </li>
                  <li className="paragraph">
                    Any violation of this Agreement.
                  </li>
                </ol>
                <div className="paragraph">
                  The Company reserves the right to participate, at its own
                  expense in the defense of any matter.
                </div>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 10</strong>
                </div>
                <div className="paragraph">Company Rights:</div>
                <ol>
                  <li className="paragraph">
                    The Company and/or Blizzcasino.com may refuse any player or
                    close a player's account if it is necessary to comply with
                    the Company's and/or Blizzcasino.com’s policy and/or protect
                    the interest of the Company.
                  </li>
                  <li className="paragraph">
                    The Company may refuse any applicant and/or may close any
                    Affiliate’s account if it is necessary to comply with the
                    Company's policy and/or protect the interest of the Company.
                    If the Affiliate is in breach of this Agreement or the
                    Company’s Terms or other rules, policies and guidelines of
                    the Company, the Company may besides closing the Affiliate’s
                    account take any other steps at law to protect its
                    interests.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 11</strong>
                </div>
                <div className="paragraph">
                  Governing Law &amp; Jurisdictions:
                </div>
                <ol>
                  <li className="paragraph">
                    This Agreement shall be governed and construed in accordance
                    with the laws of Curacao and any action or dispute relating
                    to this Agreement must be brought in Curacao and the
                    Affiliate irrevocably consents to the jurisdiction of the
                    Curacao law courts.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 12</strong>
                </div>
                <div className="paragraph">Assignment:</div>
                <ol>
                  <li className="paragraph">
                    The Affiliate may not assign this Agreement, by operation of
                    law or otherwise, without obtaining the prior written
                    consent of the Company. In the event that the affiliate
                    acquires or otherwise obtains control of another affiliate
                    of Blizzcasino.com, then accounts will coexist on individual
                    terms.
                  </li>
                  <li className="paragraph">
                    The Company may assign this Agreement, by operation of law
                    or otherwise, at any time without obtaining the prior
                    consent of the Affiliate.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 13</strong>
                </div>
                <div className="paragraph">Non-Waiver:</div>
                <ol>
                  <li className="paragraph">
                    The Company's failure to enforce the Affiliate's adherence
                    to the Terms outlined in this Agreement shall not constitute
                    a waiver of the right of the Company to enforce said terms
                    at any time.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 14</strong>
                </div>
                <div className="paragraph">Force Majeure:</div>
                <ol>
                  <li className="paragraph">
                    Neither party shall be liable to the other for any delay or
                    failure to perform its obligations under this Agreement if
                    such delay or failure arises from a cause beyond the
                    reasonable control of and is not the fault of such party,
                    including but not limited to labor disputes, strikes,
                    industrial disturbances, acts of God, acts of terrorism,
                    floods, lightning, utility or communications failures,
                    earthquakes or other casualty. If a force majeure event
                    occurs, the non-performing party is excused from whatever
                    performance is prevented by the force majeure event to the
                    extent prevented. Provided that, if the force majeure event
                    subsists for a period exceeding thirty (30) days then either
                    party may terminate the Agreement without notice.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 15</strong>
                </div>
                <div className="paragraph">Relationship of the Parties:</div>
                <ol>
                  <li className="paragraph">
                    Nothing contained in this Agreement, nor any action taken by
                    any party to this Agreement, shall be deemed to constitute
                    either party (or any of such party's employees, agents, or
                    representatives) an employee, or legal representative of the
                    other party, nor to create any partnership, joint venture,
                    association, or syndication among or between the parties,
                    nor to confer on either party any express or implied right,
                    power or authority to enter into any Agreement or commitment
                    on behalf of (nor to impose any obligation upon) the other
                    party.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 16</strong>
                </div>
                <div className="paragraph">Severability / Waiver:</div>
                <ol>
                  <li className="paragraph">
                    Whenever possible, each provision of this Agreement shall be
                    interpreted in such a manner as to be effective and valid
                    under applicable law, but if any provision of this Agreement
                    is held to be invalid, illegal or unenforceable in any
                    respect, such provision will be ineffective only to the
                    extent of such invalidity, or unenforceability, without
                    invalidating the remainder of this Agreement. No waiver will
                    be implied from conduct or failure to enforce any rights and
                    must be in writing to be effective.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 17</strong>
                </div>
                <div className="paragraph">Confidentiality:</div>
                <ol>
                  <li className="paragraph">
                    All information, including but not limited to business and
                    financial, lists of customers and buyers, as well as price
                    and sales information and any information relating to
                    products, records, operations, business plans, processes,
                    product information, business know-how or logic, trade
                    secrets, market opportunities and personal data of the
                    Company shall be treated confidentially. Such information
                    must not be used for own commercial or other purposes or
                    divulged to any person or third party neither direct nor
                    indirectly unless the prior explicit and written consent of
                    the Company This provision shall survive the termination of
                    this Agreement.
                  </li>
                  <li className="paragraph">
                    The Affiliate obliges himself not to use the confidential
                    information for any purpose other than the performance of
                    its obligations under this Agreement.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 18</strong>
                </div>
                <div className="paragraph">Changes to this Agreement:</div>
                <ol>
                  <li className="paragraph">
                    The Company reserves the right to amend, alter, delete or
                    add to any of the provisions of this Agreement, at any time
                    and at its sole discretion, without giving any advance
                    notice to the Affiliate subject to the Terms set out in this
                    Agreement. Any such changes will be published on
                    Blizzcasino.com.
                  </li>
                  <li className="paragraph">
                    In case of any discrepancy between the meanings of any
                    translated versions of this Agreement, the English language
                    version shall prevail.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 19</strong>
                </div>
                <div className="paragraph">Trademarks:</div>
                <ol>
                  <li className="paragraph">
                    Nothing contained in this Agreement will grant either party
                    any right, title or interest in the trademarks, trade names,
                    service marks or other intellectual property rights
                    \[hereinafter referred to simply as ‘Marks’\] of the other
                    party. At no time during or after the term will either party
                    attempt or challenge or assist or allow others to challenge
                    or to register or to attempt to register the Marks of the
                    other party or of any company within the group of companies
                    of the other party. Provided also that neither of the
                    parties will register nor attempt to register any Mark which
                    is basically similar to and/or confusingly similar to any
                    Mark which belongs to the other party or to any company
                    contained within the other party’s group of companies.
                  </li>
                </ol>
                <div className="paragraph"></div>
                <div className="paragraph">
                  <strong>ARTICLE 20</strong>
                </div>
                <div className="paragraph">Commission structure:</div>
                <ol>
                  <li className="paragraph">
                    Commissions are paid out as a percentage of the Net Gaming
                    Revenue.
                  </li>
                  <li className="paragraph">
                    For Affiliates which signed up after the 21st of December
                    2013: <br /> NGR = (((Bets – Wins) – Paid Bonuses) - Admin
                    Fee) - Bonus Cost. <br /> Whereof the Admin Fee is a value
                    which contains the License Fee, Game Provider Fee and
                    Platform Fee.
                  </li>
                  <li className="paragraph">
                    For affiliates who registered after 1st of September 2014
                    the Net Gaming Revenue Share will be dependant on monthly
                    active users and monthly gaming revenue. The exact
                    commission structure can be viewed from the Affiliates Page.
                  </li>
                  <li className="paragraph">
                    In this case Paid Bonuses means bonuses credited to the
                    customer (virtual money), not Bonus Cost. The difference is
                    that a Paid Bonus is the actual credited amount of bonus
                    money. Bonus Cost is the actual cost that is calculated off
                    lost Paid Bonus and is charged by the Game Provider.
                  </li>
                </ol>
              </div>
            )}

            {topic === "bonus-terms" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Bonus Terms &amp; Conditions</strong>
                </h1>
                <h4 className="dark_blue">Free Spins</h4>
                <div className="paragraph">
                  1. Free spins are offered as part of various promotions.
                  Depending on the promotion they are part of, the number of
                  free spins and the eligible games may differ.
                </div>
                <div className="paragraph">
                  2. Winning from the free spins will be credited to your
                  balance as bonus funds and will have a wagering requirement
                  attached to it. You will need to fulfil the wagering
                  requirement prior to asking for a withdrawal of those funds.
                </div>
                <div className="paragraph">
                  3. Unless mentioned otherwise, the wagering requirement for
                  winnings derived from free spins is 40x. Please check the
                  Casino Bonus Payments paragraph for an in-depth view of the
                  wagering contribution.&nbsp;
                </div>
                <div className="paragraph">
                  3.1 Unless mentioned otherwise, free spins bonuses are
                  available for 2 days from the date of issue, after which they
                  expire. Winnings gained from free spins are valid for 14 days.
                  If during this period no wagers or insufficient wagers are
                  made, the Free Spins Winnings expire and are deducted from the
                  player's account. Winnings credited to a player’s account as a
                  result of the wagering of Bonus amounts may also be deducted
                  when the bonus expires.
                </div>
                <div className="paragraph">
                  4. Until the play through requirements have been met, the
                  maximum bet that can be placed is 0.003 BTC / 0.55 LTC / 0.10
                  BCH / 0.12 ETH / 10660 DOGE / 28 USDT. This includes the
                  Gamble feature (doubles up the bet winnings) that may be found
                  on some games. If a player has an active bonus and attempts to
                  place bets over the maximum allowed, the bonus and winnings
                  will be forfeited.
                </div>
                <div className="paragraph">
                  4.1 Any bonus and winnings will be forfeited if a player has
                  an active bonus and attempts to use the funds on any of the
                  games that do not contribute to the wagering requirements.
                  Wagering can ONLY be done in SLOTS. Table Games, Live Games,
                  Sportsbook, or any other games CANNOT be used to wager the
                  bonus or while the bonus is active. Please refer to our Casino
                  Bonus Payments section to find out more about contributions.
                  If you made a bet on any of the restricted games by mistake,
                  please contact our live support.
                </div>
                <div className="paragraph">
                  5. Should the free spins be granted as a no deposit bonus, the
                  maximum possible payout for this type of free spins is of 6
                  mBTC (or currency equivalent). Any winnings that exceed the
                  aforementioned amount will be forfeited.
                </div>
                <div className="paragraph">
                  6. Players from the following countries are restricted from
                  all no deposit free spin offers: Afghanistan (AF),Albania
                  (AL),Algeria (DZ),Angola (AO),Austria (AT) and Serbia
                  (RS),Bahrain (BH),Bangladesh (BD),Belarus (BY),Benin (BJ)
                  Bolivia (BO),Bosnia and Herzegovina (BA),Botswana (BW),Burkina
                  Faso (BF),Bulgaria (BG),Bulgaria (BG),Burundi (BI),Cameroon
                  (CM),Cape Verde (CV),Central African Republic (CA),Chad
                  (TD),Comoros (KM),Congo Republic (CG),Congo Democratic
                  Republic (CD),Croatia (HR),Cyprus (CY),Czech Republic
                  (CZ),Côte d'Ivoire (CI),Denmark (DK),Djibouti (DJ),Egypt
                  (EG),Egypt (EG),Equatorial Guinea (GQ),Eritrea (ER),Ethiopia
                  (ET),Finland (FI), France (FR),Gabon (GA),Gambia (GM),Georgia
                  (GE),Ghana (GH),Greece (GR),Guinea (GN),Guinea-Bissau
                  (GW),Guyana (GY),Haiti (HT),Honduras (HN) Hungary (HU),India
                  (IN),Indonesia (ID),Iran,Iraq (IQ),Islamic Republic of
                  (IR),Jordan (JO),Kazakhstan (KZ),Kenya (KE),Kuwait (KW),Latvia
                  (LV),Lebanon (LB),Lesotho (LS),Liberia (LR),Lithuania (LT),
                  Macedonia (MK),Madagascar (MG),Malawi (MW),Malaysia (MY),Mali
                  (ML),Mauritania (MR),Mauritius (MU),Mauritius (MU),Moldova
                  (MD),Mongolia (MN),Montenegro (ME),Morocco (MA),Mozambique
                  (MZ),Namibia (NA),Nepal (NP),Nicaragua (NI),Niger (NE),Nigeria
                  (NG),North Korea (DPRK),Oman (OM),Pakistan (PK),Philippines
                  (PH),Poland (PL),Portugal (PT),Romania (RO),Russian Federation
                  (RU),Rwanda (RW),Sao Tome and Principe (ST),Senegal
                  (SN),Seychelles (SC),Sierra Leone (SL),Slovakia (SK),Slovenia
                  (SI),Somalia (SO),South Sudan (SS),State of Palestine
                  (PS),Sudan (SD),Swaziland (SZ),Syrian Arab Republic
                  (SY),Thailand (TH),Togo (TG),Tunisia (TN),Uganda (UG),Ukraine
                  (UA),United Arab Emirates (AE),United Republic of Tanzania
                  (TZ),Vietnam (VN),Yemen (YE),Zambia (ZM),Zimbabwe (ZW).
                </div>
                <div className="paragraph">
                  7. Any bonus offers are available only for cryptocurrency,
                  unless stated otherwise in the promotional material.
                </div>
                <h4 className="dark_blue">Cash Bonuses</h4>
                <div className="paragraph">
                  1. Cash bonuses are also called "No Deposit Bonuses". They are
                  given during specific promotions, without any deposit
                  required.
                </div>
                <div className="paragraph">
                  1.a. The maximum possible payout for a no deposit bonus is 6
                  mBTC (or currency equivalent). Any winnings that exceed the
                  aforementioned amounts will be forfeited.
                </div>
                <div className="paragraph">
                  1.b. Unless mentioned otherwise, the wagering requirement for
                  no deposit bonuses is of 35x &nbsp;the bonus amount.
                </div>
                <div className="paragraph">
                  1.c. To withdraw the winnings generated by a no deposit bonus,
                  a minimum deposit is required in the same currency as the no
                  deposit bonus.
                </div>
                <div className="paragraph">
                  2. Cash Bonuses may be awarded to player's account as real
                  cash or as bonus cash available for real money play. They are
                  issued with conditions on wagering, cashier withdrawals, and
                  winnings.
                </div>
                <div className="paragraph">
                  3. Unless mentioned otherwise, cash bonuses are valid for 14
                  days from the date of issue. If during this period no wagers
                  or insufficient wagers are made, the Cash Bonuses expire and
                  are deducted from the player's account. Winnings credited to a
                  player’s account as a result of the wagering of Bonus amounts
                  may also be deducted when the bonus expires.
                </div>
                <div className="paragraph">
                  4. We reserve the right to void any winnings generated from an
                  expired bonus.
                </div>
                <h4 className="dark_blue">Deposit Bonuses</h4>
                <div className="paragraph">
                  1. Some deposit bonuses are enabled by default at the time of
                  signup. The First Deposit Bonus, Second Deposit Bonus, Third
                  Deposit Bonus, some reload bonuses and Cashback bonuses will
                  be added automatically to your account at the time of
                  depositing or at the end of the promotion period unless you
                  opt out of bonuses.&nbsp;
                </div>
                <div className="paragraph">
                  2. Should you wish to opt out and play without bonuses, you
                  can tick the “I don’t want to receive bonuses” checkbox
                  located on the Bonuses section from your dashboard before you
                  make your deposit. Alternatively, you can contact our live
                  support operators and ask them to disable automatic bonuses
                  for your account.
                </div>
                <div className="paragraph">
                  3. All deposit bonuses must be claimed while the deposit is
                  intact in your balance. If you are eligible for a bonus and
                  said bonus does not get added automatically upon depositing,
                  please contact our Live Support team right away, prior to
                  placing any bet.&nbsp;
                </div>
                <div className="paragraph">
                  3.1 If the bonus was not redeemed at the time of deposit,
                  before playing (due to an insufficient deposit amount, due to
                  player opting out of bonuses, or any other reasons that are
                  outside the control of Blizzcasino), it cannot be claimed at a
                  later date. Deposit-related bonuses cannot be claimed
                  retroactively.
                </div>
                <div className="paragraph">
                  4. Any bonus and winnings will be forfeited if a player has an
                  active bonus and attempts to use the funds on any of the games
                  that do not contribute to the wagering requirements. Please
                  refer to our Casino Bonus Payments section to find out more
                  about contributions. If you made a bet on any of the
                  restricted games by mistake, please contact our live support.
                </div>
                <div className="paragraph">
                  5. The First, Second and Third Deposit Bonuses can be claimed
                  once per account, on your first, second and third deposit,
                  respectively, regardless of the currency of choice. For
                  example, if you make your first deposit using Bitcoin, the
                  First Deposit Bonus will be added; making a subsequent deposit
                  in another currency will be considered a second deposit (even
                  if it is technically the first deposit in that specific
                  currency), and will receive the Second Deposit Bonus.
                </div>
                <div className="paragraph">
                  5.1 The First, Second and Third Deposit Bonuses are not
                  available for players from Finland. This restriction applies
                  to any and all bonuses and/or promotions which are offered as
                  replacement for the First, Second or Third Deposit Bonuses. In
                  order to become eligible for any bonuses and/or promotions,
                  users from Finland need to have at least 3 deposits at
                  Blizzcasino.
                </div>
                <div className="paragraph">
                  6. When a deposit bonus is attached, all funds are locked
                  under the wager requirement. In order for the cash balance to
                  be unlocked and become cashable, the wager requirement needs
                  to be met.
                </div>
                <div className="paragraph">
                  6.1 While playing with a deposit bonus attached, the deposit
                  funds are used first, then the bonus funds. All winnings
                  derived from play with an attached bonus are considered bonus
                  funds, and fall under the bonus’ wagering requirement.
                </div>
                <div className="paragraph">
                  6.2 Unless mentioned otherwise, deposit bonuses are valid for
                  14 days from the date of issue. If during this period no
                  wagers or insufficient wagers are made, the Deposit Bonuses
                  expire and are deducted from the player's account. Winnings
                  credited to a player’s account as a result of the wagering of
                  Bonus amounts may also be deducted when the bonus expires.
                </div>
                <div className="paragraph">
                  7. In order to claim any promotional offers which include free
                  spins that are offered as part of a deposit bonus, you must
                  deposit at least 0.005 BTC / 0.12 ETH / 0.4 BCH / 1.2 LTC /
                  4,800 DOGE / 200 USDT on the deposit associated with this
                  bonus. These conditions apply to all promotional offers unless
                  stated otherwise in the promotional material.
                </div>
                <div className="paragraph">
                  8. Any bonus offers are available only for cryptocurrency,
                  unless stated otherwise in the promotional material.
                </div>
                <h4 className="dark_blue">Daily VIP Cashback</h4>
                <div className="paragraph">
                  1. To become eligible for the Cashback, you must accumulate a
                  loss of a minimum 1 mBTC (or currency equivalent). Only
                  bonus-free deposits count towards the cashback.
                </div>
                <div className="paragraph">
                  1.a. If your bonus is not applied automatically, please
                  contact us via live support. If you do not want to receive
                  your bonus, please contact us via live chat and let us know.
                </div>
                <div className="paragraph">
                  1.b. One Cashback Bonus is allowed per one person, household,
                  address, shared computer or IP address. Players may not create
                  duplicate accounts.
                </div>
                <div className="paragraph">
                  1.c. The daily VIP cashback runs on UTC time zone from 00:00
                  to 23:59 GMT. Cashback is issued at 7 AM GMT.
                </div>
                <div className="paragraph">
                  2. The Cashback bonus percentage will be issued based on the
                  VIP Planet that the player is a part of. As an example,
                  players from "Planet Earth" will receive a 2% Cashback Bonus,
                  where players from "Planet Bitcoin" will receive a 20%
                  Cashback Bonus.
                </div>
                <div className="paragraph">
                  2.a. The cashback is added based on real money losses. Any
                  bonuses do not count towards the cashback.
                </div>
                <div className="paragraph">
                  2.b. Any cashouts are counted as winnings and subtracted from
                  the real money loss.
                </div>
                <div className="paragraph">
                  2.c. Maximum cashback amount is 0.100 BTC, 3.120 ETH, 1.60
                  BCH, 6.460 LTC, 182,000 DOG, and 100 USDT. These values may be
                  updated periodically to account for fluctuations in coin
                  prices.
                </div>
                <div className="paragraph">
                  3. The Cashback Bonus will expire after 1 day of issuing the
                  bonus (UTC timezone). Unless wagering is complete by that
                  time, all winnings + bonus will expire.
                </div>
                <div className="paragraph">
                  4. Based on the VIP group you're part of, the Cashback Bonus
                  has to be wagered at least 5 (five) times. Please refer to our
                  Casino Bonus Payments section to find out more about
                  contributions towards the wagering requirement.
                </div>
                <div className="paragraph">
                  5. Players from the following countries are restricted from
                  the promotion: Bulgaria (BG), Cyprus (CY), Russian Federation
                  (RU), Lithuania (LT), Romania (RO), Ukraine (UA), Slovakia
                  (SK), Slovenia (SI), Serbia (RS), Latvia (LV), Denmark (DK),
                  and France (FR).
                </div>
                <div className="paragraph">
                  6. Only one bonus can be claimed at a time. Deposit-related
                  bonuses cannot be “stacked”. Blizz management reserves the
                  right to void any bonuses and/or winnings obtained by
                  fraudulent behavior.
                </div>
                <div className="paragraph">
                  7. One Cashback Bonus is allowed per one person, household,
                  address, shared computer or IP address. Players may not create
                  duplicate accounts. If it is discovered that multiple accounts
                  have been created, Blizzcasino reserves the right to void the
                  cashback and any winnings.
                </div>
                <div className="paragraph">
                  8. We may, at our sole discretion, change the terms and
                  conditions, the promotion itself, or limit the eligibility of
                  players to participate in this promotion, for any reason
                  whatsoever without prior notice.
                </div>
                <div className="paragraph">
                  9. Any funds that have an active bonus are not eligible for
                  Cashback unless the full wagering requirements have been met.
                  Only real money losses count towards the cashback.
                </div>
                <div className="paragraph">
                  10. General Bonus Terms and Conditions apply.
                </div>
                <h4 className="dark_blue">Wagering Requirements</h4>
                <div className="paragraph">
                  1. Winnings from any casino bonus will become a part of the
                  real money balance only after finishing the wagering
                  requirements.&nbsp;
                </div>
                <div className="paragraph">
                  1.1. Our First Deposit Bonus and Second Deposit Bonus each
                  have a wager requirement of 35x. This means that you must
                  wager the value of the bonus 35 times. For example: If a
                  player received 10 mBTC bonus he will need to wager 350 mBTC
                  before any winnings and the bonus will become part of the real
                  money balance.
                </div>
                <div className="paragraph">
                  1.2. The Third Deposit Bonus has a wager requirement of 30x
                  .This means that you must wager the value of the bonus 30
                  times. For example: If a player received 10 mBTC bonus he will
                  need to wager 300 mBTC before any winnings and the bonus will
                  become part of the real money balance.
                </div>
                <div className="paragraph">
                  1.3. All other bonuses have a playthrough requirement of
                  25x-45x unless stated otherwise. This means that you must
                  wager the value of the bonus a certain number of times, as
                  stated in any promotional material. For example: If a player
                  received 10 mBTC bonus with 40x wagering, he will need to
                  wager 400 mBTC before any winnings and the bonus will become
                  part of the real money balance.&nbsp;
                </div>
                <div className="paragraph">
                  2. Until the play through requirements have been met, the
                  maximum bet that can be placed is 0.003 BTC / 0.55 LTC / 0.10
                  BCH / 0.12 ETH / 10660 DOGE / 28 USDT. This includes the
                  Gamble feature (doubles up the bet winnings) that may be found
                  on some games. If a player has an active bonus and attempts to
                  place bets over the maximum allowed, the bonus and winnings
                  will be forfeited.
                </div>
                <div className="paragraph">
                  2.1 Any bonus and winnings will be forfeited if a player has
                  an active bonus and attempts to use the funds on any of the
                  games that do not contribute to the wagering requirements.
                  Wagering can ONLY be done in SLOTS. Table Games, Live Games,or
                  any other games CANNOT be used to wager the bonus or while the
                  bonus is active. Please refer to our Casino Bonus Payments
                  section to find out more about contributions. If you made a
                  bet on any of the restricted games by mistake, please contact
                  our live support.
                </div>
                <div className="paragraph">
                  2.2 This type of activity may lead to permanent closure of the
                  account and will also lead to a permanent exclusion of our VIP
                  program where all credited points are forfeited.
                </div>
                <div className="paragraph">
                  2.3 Additionally, the following games are restricted for bonus
                  money play until after the wager requirement of the bonus has
                  been met: Scarab Treasure, Dr. Jekyll &amp; Mr. Hyde, Fruitbat
                  Crazy, Max Quest: Wrath of Ra, Pinocchio, Split Way Royal,
                  Sugar Pop, Sugar Pop 2: Double Dipped, Super 7 Blackjack, Tens
                  or Better, Three Card Rummy, Triple Edge Poker, WhoSpunIt
                  Plus, Ninja, London Hunter, Pumpkin Patch, Santa’s Village,
                  Mega Boy, Super Fast Hot Hot Respin, Tree of Fortune, Vegas
                  High Roller, Dead or Alive, Devil's Delight, Lucky Angler,
                  Reel Steal, Robin Hood: Shifting Riches, Scudamore's Super
                  Stakes, The Wish Master, 300 Shields, Jackpot Jester 200000,
                  1429 Uncharted Seas, Baker's Treat, Eye of the Kraken, Golden
                  Legend, Happy Halloween, Hugo 2, MULTIFRUIT 81, Pearls of
                  India, Pimped, Rage to Riches, Royal Masquerade, Sea Hunter,
                  Tower Quest, Queen of Gold, 300 Shields, Astro Legends: Lyra
                  and Erion, Art of the Heist, Beautiful Bones, Bikini Party,
                  Book of Oz, Bookie of Odds, Castle Builder, Castle Builder II,
                  Cool Buck, Dragon Dance, Forsaken Kingdom, Gems Odyssey, Gems
                  Odyssey 92, Hot Ink, Le Kaffee Bar, Medusa, Peek-a-Boo - 5
                  Reel, Reel Gems, Retro Reels, Retro Reels Diamond Glitz, Retro
                  Reels Extreme Heat, Scrooge, Spectacular Wheel of Wealth,
                  Stardust, Tomb Raider - Secret of the Sword, Tomb Raider,
                  Untamed Bengal Tiger, Untamed Crowned Eagle, Untamed Giant
                  Panda, Untamed Wolf Pack, Vampire: The Masquerade - Las Vegas,
                  Wheel of Wealth Special Edition, Wheel of Wealth, Wild Orient,
                  Zombie Hoard, Divine Forest, Lemur Does Vegas, Summer Splash,
                  Alchymedes, Cazino Cosmos, Dark Vortex, Dwarf Mine, Double
                  Dragons, Holmes and the Stolen Stones, Jackpot Raiders,
                  Jokerizer, Ozwin’s Jackpots, Spina Colada, The Dark Joker
                  Rizes, Tut's Twister, Vikings go to Hell, Vikings go Berzerk,
                  Wicked Circus, Wolf Hunters, Gemix, Gunslinger: Reloaded,
                  Eggomatic, Secret of the Stones, Blood Suckers, The Twisted
                  Circus, Immortal Romance, Mega Moolah, Gold Rush, Gypsy Rose,
                  Cops'n'Robbers,&nbsp;Fairytale Legends: Hansel and Gretel, The
                  Vikings, Chillipop, Who Wants to be a Millionaire, Guns N'
                  Roses, Koi Princess.
                </div>
                <div className="paragraph">
                  3. If the bonus is forfeited/cancelled at any time, the
                  initial bonus amount and any winnings will be
                  forfeited/cancelled. Cancelled bonuses/winnings cannot be
                  re-added.
                </div>
                <div className="paragraph">
                  4. The wagering requirement of a deposit bonus locks the
                  deposited funds, the initial bonus and any winnings. If your
                  bonus has a wagering requirement of 35x, this means that if
                  you deposit 0.01 BTC and receive a bonus of 0.01 BTC, you will
                  have a total balance of 0.02 BTC and a wagering requirement of
                  0.35 BTC. The wagering requirement is calculated based on the
                  initial bonus amount: 0.01 x 35 = 0.35 BTC. Deposited funds,
                  initial bonus and the winnings gained while playing, are
                  locked under the wagering requirement until the requirement
                  has been met.
                </div>
                <div className="paragraph"></div>{" "}
                <h4 className="dark_blue">Withdrawals</h4>
                <div className="paragraph">
                  1. At least one deposit is necessary to withdraw funds. All
                  wagering requirements have to be fulfilled. The account may be
                  subject to security verifications prior to the first
                  withdrawal.
                </div>
                <div className="paragraph">
                  2. Depositing players will be able to withdraw the deposited
                  funds as long as the bonus amount is still intact. Should the
                  bonus expiry date be reached, both bonus and winnings
                  generated from the bonus may be voided.
                </div>
                <div className="paragraph">
                  3. Players can request for a cash bonus or deposit bonus to be
                  removed, but along with the bonus amount, all winnings will be
                  removed as well. Once requested, the procedure cannot be
                  reversed.
                </div>
                <div className="paragraph">
                  4. If a cash bonus, or a part of it, is used for a product not
                  described in the promotional material before the requirements
                  have been met, all winnings become void and withdrawals of
                  funds connected with the Cash Bonus (either winnings from
                  wagering the Cash Bonus or the Cash Bonus itself) will be
                  voided. Please refer to our Casino Bonus Payments section to
                  find more info on contributions.
                </div>
                <div className="paragraph">
                  5. If, while playing at Blizz, you win a sum of money tied to a
                  promotion of noteworthy variety, you agree to set aside time
                  for any publicity event arranged by Blizz. Blizz ensures the
                  protection of any and all personal information, however, we
                  reserve the right to utilize usernames when making public
                  posts regarding promotion results.
                </div>
                <h4 className="dark_blue">Casino bonus payments</h4>
                <div className="paragraph">
                  1. Deposit bonuses are valid for 30 days unless otherwise
                  stated in promotional material. If the player does not meet
                  the wagering requirements within the 30 day period, their
                  bonus and any winnings will expire and will be voided.
                </div>
                <div className="paragraph">
                  2. Winnings from any casino bonus will become a part of the
                  real money balance only after having wagered the value of the
                  bonus 25 or 50 times, depending on the bonus. For example: If
                  a player received 10 mBTC bonus with a 40x wagering
                  requirement he will need to wager 400 mBTC before any winnings
                  and the bonus will become part of the real money
                  balance.&nbsp;
                </div>
                <div className="paragraph">
                  3. Casino games count differently towards the bonus wagering
                  requirements. Slots compared to Table Games have a different
                  contribution towards releasing the bonus. Please find the full
                  list below:
                </div>
                <ul>
                  <li className="paragraph">
                    Slots: 100% contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Video slots: 100% contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Other games: 100% contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Video poker: 0% contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Table games: 0% contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Live Casino Blackjack: 0% contribution to wagering
                    requirements
                  </li>
                  <li className="paragraph">
                    Live Casino Roulette: 0% contribution to wagering
                    requirements
                  </li>
                  <li className="paragraph">
                    Live Baccarat: 0% contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Live Casino Lottery: No contribution to wagering
                    requirements
                  </li>
                  <li className="paragraph">
                    Live Dice: No contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Bet On Poker: No contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    7 out of 42 Lottery: No contribution to wagering
                    requirements
                  </li>
                  <li className="paragraph">
                    5 out of 36 Lottery: No contribution to wagering
                    requirements
                  </li>
                  <li className="paragraph">
                    Casual Games: No contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Multiplayer: No contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Scratch Cards: No contribution to wagering requirements
                  </li>
                  <li className="paragraph">
                    Wheel of Fortune: No contribution to wagering requirements
                  </li>
                </ul>
                <div className="paragraph">
                  4. For any bet, placed bets are deducted from the player’s
                  real money balance. If no real money balance is available then
                  the bet placed is deducted from the player’s bonus balance.
                </div>
                <div className="paragraph">
                  5. We reserve the right to remove the bonus and all winnings
                  if a withdrawal request is made prior to meeting the wagering
                  requirements. Winnings should be withdrawn only after the
                  wagering requirements have been met.
                </div>
                <div className="paragraph">
                  6. Tracking the bonus wagering progress may be done from your
                  dashboard directly. You can also ask our Support Team.
                </div>
                <div className="paragraph">
                  7. Blizz casino may change or cancel any promotion at its sole
                  discretion at any given time.
                </div>
                <div className="paragraph">
                  8. By using the bonus funds for play the player has accepted
                  and agreed to all the above Terms and Conditions, and any
                  other requirements or conditions stated in the promotional
                  material.
                </div>
                <h4 className="dark_blue">Miscellaneous</h4>
                <div className="paragraph">
                  1. Comp points are extra bonuses that are generated throughout
                  your gameplay. You can exchange Comp Points for mBTC.&nbsp;
                </div>
                <div className="paragraph">
                  2. The mBTC resulted from exchanging Comp Points are subject
                  to a wagering requirement of 20x.
                </div>
                <div className="paragraph">
                  3. Unless specifically mentioned otherwise, tournament prizes
                  are subject to a wagering requirement of 20x.
                </div>
                <div className="paragraph">
                  4. There is a minimum amount value below which the bonus gets
                  automatically canceled. These minimums are as follows:
                  0.000001 BTC, 0.00001 BCH, 0.00001 ETH, 0.0005 LTC, 10 DOG,
                  0.05 USDT. Once your balance with an active bonus hits the
                  minimum amount, the bonus in question will get canceled by the
                  system and marked as Lost.
                </div>
                <div className="paragraph">
                  5. We reserve the right to evaluate the origin of any claim to
                  free spins to validate that the user came in through a forum
                  or community that is currently eligible.
                </div>
                <div className="paragraph">
                  6. &nbsp;Bonuses can only be received once per person/account,
                  family, household, address, e-mail address, IP addresses and
                  environments where computers are shared (university,
                  fraternity, school, public library, workplace, etc.), unless
                  specifically mentioned otherwise. Blizzcasino reserves the
                  right to close your account and confiscate any existing funds
                  if evidence of abuse/fraud is found.
                </div>
                <div className="paragraph">
                  7. We may, at our sole discretion, change the terms and
                  conditions, the promotion itself, or limit the eligibility of
                  players to participate in any promotion(s), for any reason
                  whatsoever without prior notice.
                </div>
              </div>
            )}
            {topic === "vip-terms" && (
              <div>
                <h1 className="dark_blue">
                  <strong>VIP Terms &amp; Conditions</strong>
                </h1>
                <div className="paragraph"></div>
                <ul>
                  <li className="paragraph">
                    VIP Redeemable Points (MLP) and VIP Status Points (VLP) are
                    updated in real time while playing.
                  </li>
                  <li className="paragraph">
                    Reloads, Free Spins and Cashbacks offered as part of the VIP
                    program are added automatically to the player account.
                    Please contact Live Support before playing if your bonus was
                    not added automatically.
                  </li>
                  <li className="paragraph">
                    Only MLP can be used to purchase items in our VIP store.
                  </li>
                  <li className="paragraph">
                    The conversion is made from inside the profile page. A
                    minimum of 1 point is required in order to perform an
                    exchange.
                  </li>
                  <li className="paragraph">
                    Funds converted with MLP have a wagering requirement of 20x.
                  </li>
                  <li className="paragraph">
                    VLPs cannot be exchanged and indicate your VIP tier status.
                  </li>
                  <li className="paragraph">
                    MLPs and VLPs are generated at the same time while playing
                    any games with Cryptocurrencies.
                  </li>
                  <li className="paragraph">
                    If your play has been in violation of any of our terms and
                    conditions your bonuses, winnings and active MLPs will be
                    forfeited.
                  </li>
                  <li className="paragraph">
                    Only users that do not have an existing VIP status are
                    eligible for our VIP Tier Program.
                  </li>
                  <li className="paragraph">
                    Blizz Tiers are achieved on a monthly cycle, meaning that
                    your VIP status is reset on the first day of every month.
                  </li>
                  <li className="paragraph">
                    We reserve the right to make any and all changes to our VIP
                    program at any time.
                  </li>
                </ul>
                <h4 className="dark_blue">Daily VIP Cashback</h4>
                <div className="paragraph">
                  1. To become eligible for the Cashback, you must accumulate a
                  loss of a minimum 1 mBTC (or currency equivalent). Only
                  bonus-free deposits count towards the cashback.
                </div>
                <div className="paragraph">
                  1.a. If your bonus is not applied automatically, please
                  contact us via live support. If you do not want to receive
                  your bonus, please contact us via live chat and let us know.
                </div>
                <div className="paragraph">
                  1.b. One Cashback Bonus is allowed per one person, household,
                  address, shared computer or IP address. Players may not create
                  duplicate accounts.
                </div>
                <div className="paragraph">
                  1.c. The daily VIP cashback runs on UTC time zone from 00:00
                  to 23:59 GMT. Cashback is issued at 7 AM GMT.
                </div>
                <div className="paragraph">
                  2. The Cashback bonus percentage will be issued based on the
                  VIP Planet that the player is a part of. As an example,
                  players from "Planet Earth" will receive a 2% Cashback Bonus,
                  where players from "Planet Bitcoin" will receive a 20%
                  Cashback Bonus.
                </div>
                <div className="paragraph">
                  2.a. The cashback is added based on real money losses. Any
                  bonuses do not count towards the cashback.
                </div>
                <div className="paragraph">
                  2.b. Any cashouts are counted as winnings and subtracted from
                  the real money loss.
                </div>
                <div className="paragraph">
                  2.c. Maximum cashback amount is 0.100 BTC, 3.120 ETH, 1.60
                  BCH, 6.460 LTC, 182,000 DOG, and 100 USDT. These values may be
                  updated periodically to account for fluctuations in coin
                  prices.
                </div>
                <div className="paragraph">
                  3. The Cashback Bonus will expire after 1 day of issuing the
                  bonus (UTC timezone). Unless wagering is complete by that
                  time, all winnings + bonus will expire.
                </div>
                <div className="paragraph">
                  4. Based on the VIP group you're part of, the Cashback Bonus
                  has to be wagered at least 5 (five) times. Please refer to our
                  Casino Bonus Payments section to find out more about
                  contributions towards the wagering requirement.
                </div>
                <div className="paragraph">
                  5. Players from the following countries are restricted from
                  the promotion: Bulgaria (BG), Cyprus (CY), Russian Federation
                  (RU), Lithuania (LT), Romania (RO), Ukraine (UA), Slovakia
                  (SK), Slovenia (SI), Serbia (RS), Latvia (LV), Denmark (DK),
                  and France (FR).
                </div>
                <div className="paragraph">
                  6. Only one bonus can be claimed at a time. Deposit-related
                  bonuses cannot be “stacked”. Blizz management reserves the
                  right to void any bonuses and/or winnings obtained by
                  fraudulent behavior.
                </div>
                <div className="paragraph">
                  7. One Cashback Bonus is allowed per one person, household,
                  address, shared computer or IP address. Players may not create
                  duplicate accounts. If it is discovered that multiple accounts
                  have been created, Blizz casino reserves the right to void the
                  cashback and any winnings.
                </div>
                <div className="paragraph">
                  8. We may, at our sole discretion, change the terms and
                  conditions, the promotion itself, or limit the eligibility of
                  players to participate in this promotion, for any reason
                  whatsoever without prior notice.
                </div>
                <div className="paragraph">
                  9. Any funds that have an active bonus are not eligible for
                  Cashback unless the full wagering requirements have been met.
                  Only real money losses count towards the cashback.
                </div>
                <div className="paragraph">
                  10. General Bonus Terms and Conditions apply.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
