import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "services/api";
import GameItem from "elements/games_elements/GameItem";
import emailValidate from "services/emailValidate";
import { Badge, Button, Accordion } from "react-bootstrap";
import Slider from "react-slick";
import { __ } from "services/Translator";
const MyTickets = (props) => {
  const [tickets, setTickets] = useState([]);
  const getTickets = async () => {
    props.setSpinner(true);
    var response = await api.get("/my-tickets");
    props.setSpinner(false);
    if (response.status === 200) {
      setTickets(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <>
      <div className="faq_container my_tickets_area">
        <div className="faq_bottom_area">
          <div className="chimp-accordions access_control_area">
            <Accordion>
              {!!tickets.total ? (
                <>
                  {tickets.openTickets.map((item, index) => (
                    <>
                      <Accordion.Item
                        key={index}
                        eventKey={index}
                        className="chimp-accordion"
                      >
                        <Accordion.Header className="faq__question">
                          <Link to={"/support/ticket/" + item.id}>
                            {__("Ticket")}#{item.id}-{item.title}
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body className="faq__answer">
                          {item.created_at}
                          <br />
                          <br />
                          {item.body}
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  ))}

                  {tickets.solvedTickets.map((item, index) => (
                    <>
                      <Accordion.Item
                        key={index}
                        eventKey={index}
                        className="chimp-accordion"
                      >
                        <Accordion.Header className="faq__question">
                          <Link to={"/support/ticket/" + item.id}>
                            {__("Ticket")}#{item.id}-{item.title}
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body className="faq__answer">
                          {item.created_at}
                          <br />
                          <br />
                          {item.body}
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  ))}
                </>
              ) : (
                <li className="single_ticket">
                  {__("No tickets were created yet.")}
                </li>
              )}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTickets;
