import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { __ } from "services/Translator";
import api from "services/api";

export default function GameContentDetails(props) {
  const [pageContent, setPageContent] = useState([]);
  const [pageUid, setPageUid] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: pageUid,
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    // alert(props.gameType)
    if (props.gameType == "all") {
      setPageUid("all_games_at_icryptogaming_casino");
    } else if (props.gameType == "video_slots") {
      setPageUid("slot_games_at_icryptogaming_casino");
    } else if (props.gameType == "table_games") {
      setPageUid("table_games_at_icryptogaming_casino");
    } else if (props.gameType == "live_casino") {
      setPageUid("live_casino_at_icryptogaming_casino");
    } else if (props.gameType == "jackpot") {
      setPageUid("jackpot_games_at_icryptogaming_casino");
    } else if (props.gameType == "blackjack") {
      setPageUid("blackjack_at_icryptogaming_casino");
    } else if (props.gameType == "roulette") {
      setPageUid("roulette_at_icryptogaming_casino");
    } else if (props.gameType == "baccarat") {
      setPageUid("baccarat_at_icryptogaming_casino");
    }
  }, [props]);

  useEffect(async () => {
    if (pageUid != "") {
      getContentPage();
    }
  }, [pageUid]);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <div className="game_content_section">
      <div className="container">
        <div className="col-md-8 offset-md-2">
          <h3 className="dark_white text-center">
            <strong>
              {/* Bitcoin Games, Bitcoin Slots, Baccarat, Blackjack, Video Poker at
              iCryptoGaming Casino */}
              {pageContent != "" && pageContent.content_data.page_title}
            </strong>
          </h3>
          <br />
          <div className="paragraph dark_white text-center">
            {pageContent != "" && renderHTML(pageContent.content_data.content)}
          </div>
          {/* <div className="paragraph dark_white text-center">
            Our <Link to="/games/slots">slot machines</Link> range from low
            limits, where you can wager a fraction of a Bitcoin, similar to
            penny slots, all the way up to high limits. Whether you want to
            wager 1 btc per spin or hundreds of btc per spin, your options are
            wide open at iCryptoGaming casino. Many of our players have walked
            away with huge wins, pocketing hundreds of Bitcoins at a time. Slots
            at iCryptoGaming casino are as exciting and innovative as it gets,
            which is why our players keep coming back.
          </div>
          <div className="paragraph dark_white text-center">
            iCryptoGaming casino has all of the most popular{" "}
            <Link to="/games/video-poker">Video Poker options</Link>. If you
            play Video Poker, you know the thrill of making quads or even an
            elusive Royal Flush. Our high payback VP machines can be played in
            every format, from Jacks or Better to Deuces Wild and even Triple
            Triple Bonus. As is the case with our slot machines, limits for
            Video Poker are almost unlimited.
          </div>
          <div className="paragraph dark_white text-center">
            Table games are the staple of every casino in the world. No matter
            where you are from, the most popular games remain the same, and
            iCryptoGaming casino has them in spades (no pun intended). Our{" "}
            <Link to="/games/table-games">table games</Link> offer high speed
            action, allowing players to fire through hundreds of hands in an
            hour. This is the type of unmatched action that you will never find
            in a physical casino. You wouldn’t believe the runs that some of our
            players have had! Want an experience more similar to what you would
            find in a brick and mortar environment?
          </div>
          <div className="paragraph dark_white text-center">
            <Link to="/games/live-dealer">Live Dealer</Link> casino games are an
            advent of modern online gambling. With Live Dealers, you can play
            casino games with a real dealer. Not only is the dealer a real
            person, but they are actually dealing from an operational
            casino.Your cards and dice are scanned and verified via rfid chips,
            ensuring that your play is 100% fair and secure. With Live Dealer
            games, you will be able to enjoy the camaraderie of your fellow
            players, chat with the dealer, and you may even forget that you
            aren’t in an actual casino; the experience is that engaging and
            real.
          </div> */}
        </div>
      </div>
    </div>
  );
}
