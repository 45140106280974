import React, { Component, useState, useCallback } from "react";

export default function Pagination(props) {
  const data = props.data;

  const onPageChange = (page) => {
    if (props.onPageChange && page) {
      props.onPageChange(page);
    }
  };
  if (!data) {
    return null;
  }
  return (
    <div className="container d-flex justify-content-center">
      <ul className="pagination">
        {data.length > 0 && data.map((pageData, index) => {
          var pageClassName = ["page-link"];
          if (pageData.type === "current_page") {
            pageClassName.push("current-page");
          }
          if (index === 0) {
            pageClassName.push("previous-page");
          }
          if ((index === data.length - 1) === 0) {
            pageClassName.push("next-page");
          }
          if (
            ["first_page", "last_page", "prev_page", "next_page"].indexOf(
              pageData.type
            ) !== -1
          ) {
            pageClassName.push("icon-page");
          }

          pageClassName = pageClassName.join(" ");
          return (
            <li key={index} className="page-item">
              <button
                onClick={() => onPageChange(pageData.page)}
                className={pageClassName}
              >
                {pageData.type === "prev_page" ? (
                  <i className="fas fa-angle-left"></i>
                ) : pageData.type === "next_page" ? (
                  <i className="fas fa-angle-right"></i>
                ) : pageData.type === "first_page" ? (
                  <i className="fas fa-angle-double-left"></i>
                ) : pageData.type === "last_page" ? (
                  <i className="fas fa-angle-double-right"></i>
                ) : (
                  <span>{pageData.label}</span>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
