import React from "react";
import { __ } from "services/Translator";

export default function PaymentMethods() {
  return (
    <div className="payment_method">
      <div className="container">
        <div className="payment_header">
          <h5 className="dark_white">{__("Available Currencies")}</h5>
          <br />
          <div className="paragraph">
            {__("We are proud to be a safe crypto currency online casino. Currently, we accept the following currencies: Bitcoins, Ethereum, Bitcoin Cash, Litecoin, Dogecoin and Theter.")}
            
          </div>
        </div>

        <div className="payment_content">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="single_item text-center">
                <div className="currency_logo">
                  <img
                    src={
                      require("assets/images/currency/bitcoin.svg").default
                    }
                  />
                </div>
                <h5 className="dark_white">{__("Online Currency")}</h5>
                <div className="paragraph no_margin">{__("Deposit")}</div>
                <div className="paragraph">0.0001 BTC</div>
                <div className="paragraph no_margin">{__("Cashout")}</div>
                <div className="paragraph">0.0002 – 10 BTC</div>
                <div className="paragraph no_margin">{__("Processing time")}:</div>
                <div className="paragraph dark_white uppercase">{__("INSTANT")}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="single_item text-center">
                <div className="currency_logo">
                  <img
                    src={require("assets/images/currency/eth.svg").default}
                  />
                </div>
                <h5 className="dark_white">{__("Online Currency")}</h5>
                <div className="paragraph no_margin">{__("Deposit")}</div>
                <div className="paragraph">0.010 ETH</div>
                <div className="paragraph no_margin">{__("Cashout")}</div>
                <div className="paragraph">0.010 - 100 ETH</div>
                <div className="paragraph no_margin">{__("Processing time")}:</div>
                <div className="paragraph dark_white uppercase">{__("INSTANT")}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="single_item text-center">
                <div className="currency_logo">
                  <img
                    src={
                      require("assets/images/currency/bitcoin_cash.png")
                        .default
                    }
                  />
                </div>
                <h5 className="dark_white">{__("Online Currency")}</h5>
                <div className="paragraph no_margin">{__("Deposit")}</div>
                <div className="paragraph">0.0010 BCH</div>
                <div className="paragraph no_margin">{__("Cashout")}</div>
                <div className="paragraph">0.0010 - 100 BCH</div>
                <div className="paragraph no_margin">{__("Processing time")}:</div>
                <div className="paragraph dark_white uppercase">{__("INSTANT")}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="single_item text-center">
                <div className="currency_logo">
                  <img
                    src={
                      require("assets/images/currency/litecoin.png").default
                    }
                  />
                </div>
                <h5 className="dark_white">{__("Online Currency")}</h5>
                <div className="paragraph no_margin">{__("Deposit")}</div>
                <div className="paragraph">0.010 LTC</div>
                <div className="paragraph no_margin">{__("Cashout")}</div>
                <div className="paragraph">0.010 - 100 LTC</div>
                <div className="paragraph no_margin">{__("Processing time")}:</div>
                <div className="paragraph dark_white uppercase">{__("INSTANT")}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="single_item text-center">
                <div className="currency_logo">
                  <img
                    src={
                      require("assets/images/currency/dogecoin.png").default
                    }
                  />
                </div>
                <h5 className="dark_white">{__("Online Currency")}</h5>
                <div className="paragraph no_margin">{__("Deposit")}</div>
                <div className="paragraph">1.00 DOG</div>
                <div className="paragraph no_margin">{__("Cashout")}</div>
                <div className="paragraph">1.00 – 1,000,000 DOG</div>
                <div className="paragraph no_margin">{__("Processing time")}:</div>
                <div className="paragraph dark_white uppercase">{__("INSTANT")}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="single_item text-center">
                <div className="currency_logo">
                  <img
                    src={require("assets/images/currency/logo.svg").default}
                  />
                </div>
                <h5 className="dark_white">{__("Online Currency")}</h5>
                <div className="paragraph no_margin">{__("Deposit")}</div>
                <div className="paragraph">1.00 USDT ERC-20</div>
                <div className="paragraph no_margin">{__("Cashout")}</div>
                <div className="paragraph">1.00 - 4,000 USDT</div>
                <div className="paragraph no_margin">{__("Processing time")}:</div>
                <div className="paragraph dark_white uppercase">{__("INSTANT")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
