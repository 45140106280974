import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";

import { __ } from "services/Translator";
import { Modal, Button, Alert } from "react-bootstrap";
export default function AccountActivation(props) {
  const params = useParams();

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const activateLogin = async (ev) => {
    var response = await api.post("/login", {
      email: params.email,
      code: params.code,
      login_type: "activateLogin",
    });
    console.log(response);
    if (response.status == 200) {
      await auth.login(response.data.user);
      await updateUserObj(response.data.user);
      setIsAuthenticated(auth.isAuthenticated);
    } else {
      setAlertMsg({
        message: response.data.message,
        variant: "danger",
        showAlert: true,
      });

      setTimeout(() => {
        setAlertMsg({
          message: response.data.message,
          variant: "success",
          showAlert: false,
        });
      }, 5000);
    }
  };

  useEffect(() => {
    // console.log('paramsparams', params);
    if (params.email != undefined && params.code != undefined) {
      activateLogin();
    }
  }, []);

  const [specificSlotGames, setSpecificSlotGames] = useState(['gates-of-olympus', 'chaos-crew', 'bigger-bass-bonanza', 'sweet-bonanza', 'fruit-party']);
  // const [specificTableGames, setSpecificTableGames] = useState(['blackjack', 'Baccarat', 'three-card-poker']);
  const [specificTableGames, setSpecificTableGames] = useState(['blackjack-lobby', 'roulette-lobby', 'baccarat-c', 'dragon-tiger', 'sweet-bonanza-candyland']);

  return (
    <>
     
      <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
        {alertMsg.message}
      </Alert>
      

    </>
  );
}
