import React, { useState, useEffect, useRef } from "react";
import Banner from "elements/Banner";
import Flickity from "react-flickity-component";
import { Button } from "react-bootstrap";
import { __ } from "services/Translator";
import api from "services/api";
import { Helmet } from "react-helmet";
export default function VipProgram(props) {
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const [pageContent, setPageContent] = useState([]);
  const [pageUid, setPageUid] = useState("Vip_program");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: pageUid,
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <Helmet>
        <title>
          {__(
            "iCryptoGaming | VIPs treated as royalty with daily bonus and cash extras and exclusive extras!"
          )}
        </title>
        <meta
          name="description"
          content={__(
            "iCryptoGaming! ViP perks, personal assitance, big bonuses, prizes and much more fun; Yes being a iCryptoGaming VIP is superb!"
          )}
        />
        <link
          rel="canonical"
          href={"https://www.icryptogaming.com" + window.location.pathname}
        />
      </Helmet>

      <section className="about">
        <div className="container">
          <div className="about_content">
            <div className="about_article">
              <h1 className="dark_blue">
                <strong>
                  {pageContent != "" && pageContent.content_data.page_title}
                </strong>
              </h1>
              <div className="paragraph">
                {pageContent != "" &&
                  renderHTML(pageContent.content_data.content)}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="vip_program d-none">
        <Banner pageName="vip" {...props} />
        <div className="benefits">
          <div className="container">
            <h2 className="white text-center uppercase weight_bold">
              {__("4 Benefits of the VIP Program")}
            </h2>
            <br />
            <div className="benefits_content">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="single_benefit">
                    <div className="service_logo">
                      <img
                        src={require("assets/images/icons/user.svg").default}
                      />
                    </div>
                    <h6 className="dark_white text-center uppercase">
                      {__("DEDICATED VIP MANAGER")}
                    </h6>
                    <div className="paragraph no_margin text-center">
                      {__(
                        "The VIP Program at iCryptoGaming casino is out of this world!"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="single_benefit">
                    <div className="service_logo">
                      <img
                        src={require("assets/images/icons/pig.svg").default}
                      />
                    </div>
                    <h6 className="dark_white text-center uppercase">
                      {__("Exclusive Bonuses")}
                    </h6>
                    <div className="paragraph no_margin text-center">
                      {__(
                        "Our bonuses are great, but have you seen our VIP bonuses?"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="single_benefit">
                    <div className="service_logo">
                      <img
                        src={require("assets/images/icons/star.svg").default}
                      />
                    </div>
                    <h6 className="dark_white text-center uppercase">
                      {__("LOYALTY POINTS")}
                    </h6>
                    <div className="paragraph no_margin text-center">
                      {__(
                        "You show your loyalty by playing more, we show ours by rewarding you more."
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <div className="single_benefit">
                    <div className="service_logo">
                      <img
                        src={require("assets/images/icons/trophy.svg").default}
                      />
                    </div>
                    <h6 className="dark_white text-center uppercase">
                      {__("PERSONALIZED REWARDS")}
                    </h6>
                    <div className="paragraph no_margin text-center">
                      {__("No two stars are alike, and neither are two VIPs.")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vip_journey">
          <div className="container">
            <div className="col-md-8 offset-md-2">
              <h2 className="white text-center uppercase weight_bold">
                {__("NEW VIP JOURNEY")}
              </h2>
              <h5 className="text-center dark_white">
                {__(
                  "We are proud to reward our loyal players with the prizes they deserve. With each tier you unlock even greater rewards."
                )}
              </h5>
              <br />
              <br />
            </div>
            <br />
            <div className="journey_slider">
              <div onClick={prevLoad} className="prev d-none d-md-block">
                <svg
                  width="25"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <title>{__("left")}</title>
                  <path d="M12.586 27.414l-10-10a2 2 0 0 1 0-2.828l10-10a2 2 0 1 1 2.828 2.828L8.828 14H28a2 2 0 1 1 0 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 0 1-2.828 0z"></path>
                </svg>
              </div>
              <div onClick={nextLoad} className="next d-none d-md-block">
                <svg
                  width="25"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <title>{__("right")}</title>
                  <path d="M19.414 27.414l10-10a2 2 0 0 0 0-2.828l-10-10a2 2 0 1 0-2.828 2.828L23.172 14H4a2 2 0 1 0 0 4h19.172l-6.586 6.586c-.39.39-.586.902-.586 1.414s.195 1.024.586 1.414a2 2 0 0 0 2.828 0z"></path>
                </svg>
              </div>
              <Flickity
                flickityRef={(carouselRef) => {
                  flkty.current = carouselRef;
                }}
                options={{
                  groupCells: true,
                  cellAlign: "left",
                  prevNextButtons: false,
                  pageDots: false,
                }}
                static
                className="earth_slider"
              >
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={require("assets/images/planet/earth.svg").default}
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Earth")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      o {__("Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        <div className="colored blue">1x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored blue">2%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored blue">20%</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={
                          require("assets/images/planet/litecoin.svg").default
                        }
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Litecoin")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("3 Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("Comp Points Booster Multiplier")}
                        <div className="colored light_blue">x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored light_blue">2.5%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored light_blue">25%</div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored light_blue">25% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored light_blue">
                          {__("50 No Deposit Free Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={
                          require("assets/images/planet/ethereum.svg").default
                        }
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Ethereum")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("Comp Points Booster Multiplier")}
                        <div className="colored green">1.5x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored green">5%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored green">30%</div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored green">30% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored green">
                          {__("100 No Deposit Free Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={require("assets/images/planet/doge.svg").default}
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Doge")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("75 Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("Comp Points Booster Multiplier")}
                        <div className="colored yellow">2x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored yellow">7.5%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored yellow">40%</div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored yellow">40% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored yellow">
                          {__("200 No Deposit Free Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={
                          require("assets/images/planet/bitcoin-cash.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Bitcoin Cash")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("500 Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("Comp Points Booster Multiplier")}
                        <div className="colored green_dark">3x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored green_dark">10%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored green_dark">50%</div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored green_dark">50% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored green_dark">
                          {__("300 No Deposit Free Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={require("assets/images/planet/dash.svg").default}
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Dash")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("1500 Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("Comp Points Booster Multiplier")}
                        <div className="colored purpule">4x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored purpule">15%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored purpule">
                          2 * 50% up to 1BTC
                        </div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored purpule">75% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored purpule">
                          {__("400 No Deposit Free Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={
                          require("assets/images/planet/bitcoin.svg").default
                        }
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("Planet Bitcoin")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("4750 Required Status Points")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("Comp Points Booster Multiplier")}
                        <div className="colored red">5x</div>
                      </li>
                      <li>
                        {__("CashBack")}
                        <div className="colored red">20%</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored red">2 * 75% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored red">100% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored red">
                          {__("500 No Deposit Free Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_earth">
                    <div className="earth_icon">
                      <img
                        src={require("assets/images/planet/moon.svg").default}
                      />
                    </div>
                    <div className="earth_dot"></div>
                    <h4 className="dark_white text-center weight_bold">
                      {__("The Moon")}
                    </h4>
                    <h6 className="white text-center weight_bold">
                      {__("By Invitation Only")}
                    </h6>
                    <br />
                    <ul className="menus">
                      <li>
                        {__("CashBack")}
                        <div className="colored info">{__("Custom Offer")}</div>
                      </li>
                      <li>
                        {__("Weekly Reload Bonus")}
                        <div className="colored info">2 * 100% up to 1BTC</div>
                      </li>
                      <li>
                        {__("Reload Bonus")}
                        <div className="colored info">{__("Custom Offer")}</div>
                      </li>
                      <li>
                        {__("Milestone Bonus")}
                        <div className="colored info">
                          {__("To the Moon Spins")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Flickity>
            </div>
          </div>
        </div>

        <div className="review_section">
          <div className="container">
            <h2 className="white text-center uppercase weight_bold">
              {__("CUSTOMER REVIEWS")}
            </h2>
            <br />
            <br />
            <br />
            <div className="review_content">
              <div onClick={prevLoad} className="prev d-none d-md-block">
                <svg
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <title>{__("left")}</title>
                  <path d="M12.586 27.414l-10-10a2 2 0 0 1 0-2.828l10-10a2 2 0 1 1 2.828 2.828L8.828 14H28a2 2 0 1 1 0 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 0 1-2.828 0z"></path>
                </svg>
              </div>
              <div onClick={nextLoad} className="next d-none d-md-block">
                <svg
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <title>{__("right")}</title>
                  <path d="M19.414 27.414l10-10a2 2 0 0 0 0-2.828l-10-10a2 2 0 1 0-2.828 2.828L23.172 14H4a2 2 0 1 0 0 4h19.172l-6.586 6.586c-.39.39-.586.902-.586 1.414s.195 1.024.586 1.414a2 2 0 0 0 2.828 0z"></path>
                </svg>
              </div>
              <Flickity
                flickityRef={(carouselRef) => {
                  flkty.current = carouselRef;
                }}
                options={{
                  groupCells: true,
                  autoPlay: true,
                  cellAlign: "left",
                  prevNextButtons: false,
                  pageDots: false,
                }}
                static
                className="review_slider"
              >
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="single_review">
                    <div className="paragraph dark_white uppercase text-center">
                      {__("Dohn Doe")}
                    </div>
                    <div className="paragraph text-center white">
                      {__(
                        "Whats not to like about this casino! Great graphics and an easy to navigate layout. The chat and email support are fantastic and always available. The VIP staff are incredible, with a response time of no more than an hour."
                      )}
                    </div>
                    <div className="stars">
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                      <svg
                        height="15"
                        viewBox="0 -10 511.98685 511"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                          fill="#ffc107"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </Flickity>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
