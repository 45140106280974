import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
const GameItem = ({ game, isAuthenticated }) => {
  // const __ = (text) => text;
  const [visible, setVisible] = useState(true);
  const [processed, setProcessed] = useState(false);
  const loaderImage = require("assets/images/logos/game-loader.gif").default;
  const defaultImage = require("assets/images/games/default.png").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(loaderImage);

  const hideNoImageFoundGames = () => {
    // setVisible(false);
    setGameSnapshotUrl(defaultImage);
  };

  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);

  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  useEffect(() => {
    setVisible(true);
    setProcessed(false);
    setGameSnapshotUrl(loaderImage);
    setIsFavorite(game.is_favorite_game);
  }, [game.id, isAuthenticated]);

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      var img = event.target;
      var width = img.width;
      var height = width * 1;
      img.height = height;
      setProcessed(true);
    }
  };

  return (
    <div className={"single_game " + (visible ? "" : "d-none")}>
      <img
        className="games_img"
        onError={hideNoImageFoundGames}
        onLoad={fixImageRatio}
        src={gameSnapshotUrl}
      />
      <div className="mask_layer">
        <div className="game_text">
          <div className="game_name" title={game.game_name}>
            {game.game_name}
          </div>
          <div className="providers_name">{game.provider?.name ?? game.provider_id}</div>
        </div>
        <div className="game_btns">
          <Link className="play_btn" to={"/play/" + game.slug + "/real"}>
            {__("Real")}
          </Link>
          {!isAuthenticated && (
            <Link className="play_btn" to={"/play/" + game.slug + "/demo"}>
              {__("Fun")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameItem;
