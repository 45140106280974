import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import CategoryGames from "elements/games_elements/CategoryGames";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import VipLevelText from "elements/VipLevelText";
// import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import { __ } from "services/Translator";

export default function GamePlay(props) {
  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);

  const getGameList = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 12,
      game_types: ["popular"],
      page: 1,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);
    }
  };

  // const __ = (text) => text;

  useEffect(() => {
    getGameList();
  }, [gameSorting, props.selectedProviders]);

  const params = useParams();
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const gamePlayMode = params.mode && params.mode === "demo" ? "demo" : "real";
  const [gameSessionId, setGameSessionId] = useState(null);

  const setGamePlayMode = (mode) => {
    if (mode === "demo") {
      history.replace("/play/" + params.slug + "/demo");
    } else {
      console.log('heddedeeed', mode)
      history.replace("/play/" + params.slug + "/real");
    }
  };

  const toggleFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("game_play_window");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const iframeSrcChanged = (event) => {
    // alert(event.target.contentWindow.location);
  };

  const getPlay = async () => {
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });

    if (response.status === 200 && response.data) {
      setLaunchUrl(response.data.launchUrl);
      setGameSessionId(response.data.sessionId);
      setGameDetails(response.data.details);
    } else {
      props.showAlert("Warning!!", response.data.message, true, () => {
        history.push("/play/" + params.slug + "/demo");
      });
    }
  };

  useEffect(() => {
    getPlay();
  }, [gamePlayMode, params.slug]);

  const [bonusUnsupportedWarningModal, setBonusUnsupportedWarningModal] =
    useState({ show: false });

  useEffect(() => {
    if (
      gameDetails &&
      props.userBalance &&
      gamePlayMode !== "demo" &&
      !gameDetails.bonus_supported &&
      parseFloat(props.userBalance.cash_balance) === 0.0
    ) {
      setBonusUnsupportedWarningModal({ show: true });
    } else {
      setBonusUnsupportedWarningModal({ show: false });
    }
  }, [gamePlayMode, props.userBalance.cash_balance, gameDetails]);

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: "Warning!!",
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
        setLaunchUrl(response.data.newSession.launchUrl);
      }
    }
  };

  useEffect(() => {
    var interval = setInterval(function () {
      if (gamePlayMode !== "demo") {
        loadGameHubMessage();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, gameDetails, isAuthenticated, gamePlayMode]);

  //GamePlay Page detection
  const isMobileDevice = props.isMobileDevice;
  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;
  const isLandscape = props.isLandscape;
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const calclateMobileIframeStyle = () => {
    var style = {
      width: "100vw",
      height: "calc(100vh - 52px)",
      // height: "100vh",
    };
    if (isLandscape) {
      style.height = "100vh";
    }
    setMobileIframeStyle(style);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();

    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  const [topWinners, setTopWinners] = useState([]);

  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners?limit=4");
    if (response.status === 200) {
      setTopWinners(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  useEffect(() => {
    getTopWinners();
  }, []);
  const [theaterMode, setTheaterMode] = useState(false);

  const [isFavorite, setIsFavorite] = useState(gameDetails.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", {
      gameId: gameDetails.id,
    });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  return (
    <>
      {!isMobileDeviceByViewPort && (
        <>
          <br />
          <div
            className={"game_play " + (theaterMode ? "theaterModeEnabled" : "")}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="game_content">
                    <iframe
                      style={{ display: "block !important;" }}
                      id="game_play_window"
                      width="100%"
                      height="100%"
                      src={launchUrl}
                    ></iframe>
                    <div className="game_play_overlay"></div>
                  </div>
                </div>

                <div className="col-12 col-xl-4 d-none">
                  <div className="game_details">
                    <div className="headiing">{__("Game Details")}:</div>
                    <div className="single_details_block">
                      <div className="item">
                        <div className="item_name">{__("Mode")}</div>
                        <div className="item_value">
                          {gamePlayMode === "demo" ? "Fun Play" : "Real Play"}
                        </div>
                      </div>
                      <div className="item active">
                        <div className="item_name">
                          {__("Currently Playing")}:
                        </div>
                        <div className="item_value">
                          {gameDetails.game_name}
                        </div>
                      </div>
                      <div className="item">
                        <div className="item_name">{__("Provider")}:</div>
                        <div className="item_value">
                          {gameDetails.provider?.name}
                        </div>
                      </div>
                    </div>

                    <div className="single_details_block">
                      <div className="item">
                        <div className="item_name">
                          {__("Bonus Supported")}:
                        </div>
                        <div className="item_value">
                          {gameDetails.bonus_supported ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="item">
                        <div className="item_name">{__("Game Type")}:</div>
                        <div className="item_value">
                          {gameDetails.type?.replace("-", " ")}
                        </div>
                      </div>
                      <div className="item">
                        <div className="item_name">{__("Jackpot")}</div>
                        <div className="item_value">
                          {gameDetails.has_jackpot && gameDetails.jackpot_amount
                            ? "$" + gameDetails.jackpot_amount?.toString()
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="single_details_block">
                      <div className="item">
                        <div className="item_name">{__("Featured")}:</div>
                        <div className="item_value">
                          {gameDetails.is_featured ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="item">
                        <div className="item_name">{__("New")}:</div>
                        <div className="item_value">
                          {gameDetails.is_new ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="item">
                        <div className="item_name">
                          {__("Fun Play Supported")}
                        </div>
                        <div className="item_value">
                          {gameDetails.play_for_fun_supported ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>

                    <div className="headiing">{__("Top Players")}</div>
                    {topWinners.map((item, index) => (
                      <div key={index} className="single_details_block players">
                        <div className="item">
                          <div className="game_block">
                            <div className="game_thumb">
                              <img src={item?.game?.game_snapshot_url} />
                            </div>
                            <div className="game_name">
                              <div className="d-flex">
                                {item.player.player_name +
                                  " " +
                                  item.player.player_surname}
                                <span className="level">
                                  {item.player.identity}
                                </span>
                                <sup className="supper">Lv</sup>
                              </div>
                              <div className="item_name">
                                <VipLevelText {...item.player} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="item_name white">
                            ${item.win_amount}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="frame_bottom">
                    <div className="actions_icon">
                      <OverlayTrigger
                        overlay={<Tooltip>{__("Full-Screen")}</Tooltip>}
                      >
                        <Link>
                          <i
                            onClick={toggleFullScreen}
                            class="fas fa-expand"
                          ></i>
                        </Link>
                      </OverlayTrigger>
                      <Link className={theaterMode ? "active" : ""}>
                        <OverlayTrigger
                          className="tooltipStyle"
                          overlay={<Tooltip>{__("Theater Mode")}</Tooltip>}
                        >
                          <i
                            onClick={() => {
                              if (theaterMode) {
                                setTheaterMode(false);
                              } else {
                                setTheaterMode(true);
                              }
                            }}
                            class="fas fa-expand-wide"
                          ></i>
                        </OverlayTrigger>
                      </Link>
                      {isAuthenticated && (
                        <i
                          onClick={toggleFavoriteGame}
                          className={(isFavorite ? "fas" : "far") + " fa-heart"}
                        ></i>
                      )}

                      <select className="currency_select d-none">
                        <option>USD</option>
                        <option>EUR</option>
                      </select>
                    </div>
                    <div className="text_area white">
                      {gameDetails.game_name} |{" "}
                      {gameDetails.provider?.name ?? ""}
                    </div>

                    <div className="actions_btn">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitches"
                          defaultChecked={gamePlayMode == "real" ? true : false}
                          onChange={(ev) => {
                            var target = ev.target;
                            if (target.checked) {
                              setGamePlayMode("real");
                            } else {
                              setGamePlayMode("demo");
                            }
                          }}
                        />
                        <label
                          class="custom-control-label"
                          for="customSwitches"
                        >
                          {__("Real Play")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <SingleCategoryGames
                categoryName="Popular"
                category="popular"
                perPage="5"
              /> */}
            </div>

            {/* <CategoryGames
              providers={props.providers}
              selectedProviders={props.selectedProviders}
              setSelectedProviders={props.setSelectedProviders}
              gameList={gameList}
              categoryName="Popular Games"
              category="popular"
              setGameSorting={setGameSorting}
              gameSorting={gameSorting}
              isAuthenticated={props.isAuthenticated}
              setSearchStr={props.setSearchStr}
            /> */}
          </div>
        </>
      )}

      {isMobileDeviceByViewPort && (
        <iframe
          onLoad={iframeSrcChanged}
          src={launchUrl}
          style={mobileIframeStyle}
        />
      )}

      <Modal
        className="gamePlayMsgModal"
        show={gameHubMessageModal.show}
        onHide={closeAllModals}
      >
        {/* <Modal className="gamePlayMsgModal" show={1} onHide={closeAllModals}> */}
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type ==
                "maximum_bonus_wagers_per_round"
              ? __("Maximum bonus wagers limit exceeded!")
              : gameHubMessageModal.message_type == "bonus_balance_not_support"
              ? __("Insufficient balance!")
              : gameHubMessageModal.message_type == "maximum_wagers"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_loss"
              ? __("Maximum cash loss limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_playing_time"
              ? __("Maximum play time limit exceeded!")
              : gameHubMessageModal.title}
          </h5>
          {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i class="fas fa-exclamation-triangle"></i>
            <br />
            {gameHubMessageModal.message_type ==
            "maximum_cash_wagers_per_round" ? (
              <>
                {__(
                  "You have exceeded the maximum bet amount for cash money. Please lower the amount and try again. See our"
                )}{" "}
                <Link target="_blank" to="/content/terms-conditions">
                  {__("General Terms & Conditions")}
                </Link>{" "}
                {__("for more information.")}"
              </>
            ) : gameHubMessageModal.message_type ==
              "maximum_bonus_wagers_per_round" ? (
              <>
                {__(
                  "You have exceeded the maximum bet amount for bonus money. Please lower the amount and try again. See our"
                )}{" "}
                <Link target="_blank" to="/content/bonus-terms">
                  {__("Bonus Terms")}
                </Link>{" "}
                {__("for more information.")}
              </>
            ) : gameHubMessageModal.message_type ==
              "bonus_balance_not_support" ? (
              <>
                {__(
                  "Bonus play is not supported for this game. You do not have sfficient cash balance to play this game. See our"
                )}{" "}
                <Link target="_blank" to="/content/bonus-terms">
                  {__("Bonus Terms")}
                </Link>{" "}
                {__(
                  "for more information. Note: For Live Casino games and Sports Betting, Only cash wagers are accepted (no bonus wagers)"
                )}
              </>
            ) : (
              <>{gameHubMessageModal.message}</>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            {__("OK")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="gamePlayMsgModal"
        show={bonusUnsupportedWarningModal.show}
        onHide={closeAllModals}
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Game playing with bonus money!")}
          </h5>
          {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__(
              "This game is not available for bonus money. Only cash money is accepted."
            )}
            <br />
            {__(
              "Note: All Live Casino games are not available for bonus money wagering."
            )}
            <br />
            <Link target="_blank" to="/content/bonus-terms">
              {__("Click here")}
            </Link>{" "}
            {__("to see which games are available for Bonus Money playing.")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            {__("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
