import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { __ } from "services/Translator";

export default function BitcoinGuide(props) {
  const params = useParams();
  const topic = params.topic;
  const [topicTitle, setTopicTitle] = useState("What is Bitcoin");

  return (
    <section className="about">
      <div className="container">
        <div className="about_content">
          <div className="about_menu">
            <div className="select_box  d-block d-lg-none">
              <DropdownButton className="select_btn" title={topicTitle}>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("What is Bitcoin");
                    }}
                    to="/bitcoin-guide/index"
                  >
                    {__("What is Bitcoin")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("History of Bitcoins");
                    }}
                    to="/bitcoin-guide/history-of-bitcoins"
                  >
                    {__("History of Bitcoins")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Where to get bitcoins");
                    }}
                    to="/bitcoin-guide/where-to-get-bitcoins"
                  >
                    {__("Where to get bitcoins")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bitcoin Wallets");
                    }}
                    to="/bitcoin-guide/bitcoin-wallets"
                  >
                    {__("Bitcoin Wallets")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bitcoin Exchanges");
                    }}
                    to="/bitcoin-guide/bitcoin-exchanges"
                  >
                    {__("Bitcoin Exchanges")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("How to acquire, sell and store Bitcoins");
                    }}
                    to="/bitcoin-guide/how-to-acquire-store-and-sell-bitcoins"
                  >
                   {__("How to acquire, sell and store Bitcoins")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("The value of Bitcoin");
                    }}
                    to="/bitcoin-guide/the-value-of-bitcoin"
                  >
                    {__("The value of Bitcoin")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle(
                        "Using a Trezor for Bitcoin Casino Deposits"
                      );
                    }}
                    to="/bitcoin-guide/using-a-trezor-for-bitcoin-casino-deposits"
                  >
                    {__("Using a Trezor for Bitcoin Casino Deposits")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle(
                        "Transaction Speed, Security, Fees and Options"
                      );
                    }}
                    to="/bitcoin-guide/transaction-speed-security-fees-and-options"
                  >
                    {__("Transaction Speed, Security, Fees and Options")}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bitcoin FAQ");
                    }}
                    to="/bitcoin-guide/bitcoin-faq"
                  >
                    {__("Bitcoin FAQ")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("How Litecoin Works");
                    }}
                    to="/bitcoin-guide/how-litecoin-works"
                  >
                    {__("How Litecoin Works")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("How Ethereum Works");
                    }}
                    to="/bitcoin-guide/how-ethereum-works"
                  >
                    {__("How Ethereum Works")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Ethereum Online Casinos");
                    }}
                    to="/bitcoin-guide/ethereum-online-casinos"
                  >
                    {__("Ethereum Online Casinos")}
                  </Link>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <ul className="d-none d-lg-block">
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("What is Bitcoin");
                  }}
                  className={topic === "index" ? "active" : ""}
                  to="/bitcoin-guide/index"
                >
                  {__("What is Bitcoin")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("History of Bitcoins");
                  }}
                  className={topic === "history-of-bitcoins" ? "active" : ""}
                  to="/bitcoin-guide/history-of-bitcoins"
                >
                  {__("History of Bitcoins")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Where to get bitcoins");
                  }}
                  className={topic === "where-to-get-bitcoins" ? "active" : ""}
                  to="/bitcoin-guide/where-to-get-bitcoins"
                >
                  {__("Where to get bitcoins")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bitcoin Wallets");
                  }}
                  className={topic === "bitcoin-wallets" ? "active" : ""}
                  to="/bitcoin-guide/bitcoin-wallets"
                >
                  {__("Bitcoin Wallets")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bitcoin Exchanges");
                  }}
                  className={topic === "bitcoin-exchanges" ? "active" : ""}
                  to="/bitcoin-guide/bitcoin-exchanges"
                >
                  {__("Bitcoin Exchanges")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("How to acquire, sell and store Bitcoins");
                  }}
                  className={
                    topic === "how-to-acquire-store-and-sell-bitcoins"
                      ? "active"
                      : ""
                  }
                  to="/bitcoin-guide/how-to-acquire-store-and-sell-bitcoins"
                >
                  {__("How to acquire, sell and store Bitcoins")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("The value of Bitcoin");
                  }}
                  className={topic === "the-value-of-bitcoin" ? "active" : ""}
                  to="/bitcoin-guide/the-value-of-bitcoin"
                >
                  {__("The value of Bitcoin")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Using a Trezor for Bitcoin Casino Deposits");
                  }}
                  className={
                    topic === "using-a-trezor-for-bitcoin-casino-deposits"
                      ? "active"
                      : ""
                  }
                  to="/bitcoin-guide/using-a-trezor-for-bitcoin-casino-deposits"
                >
                  {__("Using a Trezor for Bitcoin Casino Deposits")}
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setTopicTitle(
                      "Transaction Speed, Security, Fees and Options"
                    );
                  }}
                  className={
                    topic === "transaction-speed-security-fees-and-options"
                      ? "active"
                      : ""
                  }
                  to="/bitcoin-guide/transaction-speed-security-fees-and-options"
                >
                  {__("Transaction Speed, Security, Fees and Options")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bitcoin FAQ");
                  }}
                  className={topic === "bitcoin-faq" ? "active" : ""}
                  to="/bitcoin-guide/bitcoin-faq"
                >
                  {__("Bitcoin FAQ")}
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("How Litecoin Works");
                  }}
                  className={topic === "how-litecoin-works" ? "active" : ""}
                  to="/bitcoin-guide/how-litecoin-works"
                >
                  {__("How Litecoin Works")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("How Ethereum Works");
                  }}
                  className={topic === "how-ethereum-works" ? "active" : ""}
                  to="/bitcoin-guide/how-ethereum-works"
                >
                  {__("How Ethereum Works")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Ethereum Online Casinos");
                  }}
                  className={
                    topic === "ethereum-online-casinos" ? "active" : ""
                  }
                  to="/bitcoin-guide/ethereum-online-casinos"
                >
                  {__("Ethereum Online Casinos")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="about_article">
            {topic === "index" && (
              <div>
                <h1 className="dark_blue">
                  <strong>{__("Everything You Need to Know About Bitcoins")}</strong>
                </h1>
                <div className="paragraph">
                  Bitcoins (BTC) are the most popular cryptocurrency online
                  today. Designed to be completely anonymous, Bitcoins are
                  created and stored entirely by computers on the Internet using
                  the Bitcoin network. Simply put, there are no tangible bills,
                  coins, or any other type of physical elements that can be
                  identified as bitcoins, passed around between consumers and
                  businesses when transactions are made - bitcoins are stored
                  purely online as numbers on computers without the need for any
                  physical bills or coins. All payments are done electronically
                  between computers with internet access, from one bitcoin “
                  <Link to="https://www.casino.com/bitcoin-guide/bitcoin-wallets">
                    wallet
                  </Link>
                  ” to another. Since bitcoin transfers occur from one user to
                  another, no intermediary organizations like banks are
                  involved, so there are no monthly payments or expensive
                  banking fees associated with the use of bitcoins. Multilayered
                  security methods have been implemented into Bitcoins to
                  guarantee safe and clean transactions, preventing various
                  forms of fraud and risks from affecting Bitcoin users.
                </div>
                <div className="paragraph">
                  More vendors are accepting Bitcoins as a legitimate method of
                  payment each day and the markets are quickly realizing how
                  Bitcoins can have a serious impact on how we trade goods on a
                  daily basis. The growth of Bitcoins is steadily rising and as
                  more and more people and organizations begin to accept the
                  cryptocurrency as a viable method of payment, the more value
                  Bitcoins will gain. While some may perceive Bitcoins as a
                  volatile currency suffering from inflation spikes, it is
                  designed to be stable in the long run. What we can all agree
                  on is that Bitcoins are here to stay and as time passes, the
                  more predominant it will become.
                </div>
                <div className="paragraph">
                  Before we get you started on&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/how-to-acquire-store-and-sell-bitcoins">
                    how to acquire your own Bitcoins
                  </Link>
                  , we’ll fill you in on how it all works. While the concept of
                  Bitcoins may seem daunting at first, they are actually quite
                  simple to understand once you can grasp the fundamental
                  concepts behind them.
                </div>
                <h3 className="dark_blue">Bitcoin Mining</h3>
                <div className="paragraph">
                  Bitcoin mining is the key to understanding how Bitcoin works.
                  Bitcoin miners are electronic devices that use specialized
                  hardware and software to both approve transactions between
                  users and secure the peer to peer bitcoin network. These
                  miners additionally use their computing power to solve a
                  mathematical algorithm, and upon solving the algorithm are
                  issued new bitcoins to introduce to the market. Every time the
                  algorithm is solved, a brand new and more complex algorithm is
                  introduced, requiring more computing power to solve, thus
                  reducing the rate in which bitcoins are created.
                </div>
                <div className="paragraph">
                  Releasing new bitcoins by resolving the algorithms provides a
                  monetary incentive for more miners to take part in the bitcoin
                  community. This is also a superior method to resolve the
                  current issue we see every day in modern currencies known as
                  inflation. Requiring miners to solve more difficult algorithms
                  to unlock new bitcoins ensures that the rate in which bitcoins
                  are introduced never increases and that the influx of new
                  bitcoins never gets out of hand. What this means is obvious:
                  the time to invest in bitcoins is now. In a few years, the
                  price of bitcoins will increase dramatically and the
                  acquisition of bitcoins early will bring profitable long term
                  value.
                </div>
                <div className="paragraph">
                  In order to approve transactions, bitcoin miners may charge a
                  small fee, often a few pennies. However, these marginal fees
                  are a fraction of what traditional banks charge for wire
                  transfers and occur faster, often within seconds as opposed to
                  hours and days. This is where bitcoin shines above the rest
                  and gives it a competitive edge over other services like
                  PayPal or credit card companies.
                </div>
                <h3 className="dark_blue">Security of Bitcoins</h3>
                <div className="paragraph">
                  Many people tend to stay away from making purchases online
                  because they are concerned about the safety of spending their
                  hard earned money in cyberspace. Bitcoins are designed in a
                  fashion that reduces the surface area of security mitigations
                  unlike any other payment method available today.
                </div>
                <div className="paragraph">
                  The several features integrated into the cryptocurrency are
                  designed to keep your money safe. The main security features
                  are:
                </div>
                <ol>
                  <li>
                    <strong>Secure and private transactions:</strong>Using a
                    public and private key cryptography service, transactions
                    are kept secure in the exact same method in which SSL works
                    when you log into sites such as your bank or sites that
                    contain sensitive data. Nobody can read the data being sent
                    if they are listening to your computer “talk” to another
                    computer across the bitcoin network because it is encrypted
                    using this method.
                  </li>
                  <li>
                    <strong>Double Spending and Race Attacks:</strong>This
                    occurs when somebody uses the exact same bitcoin and sends
                    it to multiple people at once, tricking the network into
                    processing two transactions with the same bitcoin. The
                    network is smarter than this and prevents this type of
                    activity from occurring by recording all transfers in a
                    ledger known as the block chain. This ledger is visible to
                    all users and guarantees that once a bitcoin is spent, it
                    can’t be spent again from the same user.
                  </li>
                  <li>
                    <strong>Block ledger modification:</strong>Remember that
                    ledger we just mentioned? You can’t edit it. By preventing
                    people from manipulating the ledger, you prevent people from
                    manipulating the network. The ledger has a feature
                    implemented in it referred to as a hash, which is a
                    generated number that is created based on the information
                    the ledger receives. If you change even a single bit on
                    information, the hash changes dramatically. This security
                    method is popular throughout the IT industry and is arguably
                    one of the most effective methods of keeping the network
                    safe as it prevents users from modifying sensitive data.
                  </li>
                  <li>
                    <strong>Anonymous Users:</strong>Every bitcoin user is
                    completely anonymous online. Nobody knows who you are, your
                    name, and your address are not involved with bitcoins in any
                    way; unlike credit cards or PayPal funds, this information
                    can’t be used against you if somebody is able to discover
                    your online identity. While your BTC transfers appear in the
                    ledger, your identity is seen as a random string of numbers
                    and letters, masking your identity to the general public.
                  </li>
                </ol>
                <div className="paragraph">
                  What does this all boil down to? Privacy, security and peace
                  of mind. The creators of Bitcoin wanted to create a currency
                  that was secure, not just from external threats, but from any
                  form of exploitation, internal or external. No centralization
                  means no inflation manipulation and cheap fees for users.
                  Advanced security methods are implemented to keep all
                  transactions secure and even though they’re made public on the
                  block chain ledgers, it’s impossible to find out who you are.
                  There is seemingly no method known to man at this stage of
                  Bitcoin development for any level of threat to Bitcoin users
                  and it seems likely to stay this way into the foreseeable
                  future.
                </div>
                <div className="paragraph">
                  This is why we, at iCryptoGaming casino, opted for Bitcoins – because we
                  wanted to offer you a discrete yet highly secure gambling
                  experience. We value our players, their privacy, and their
                  security.
                </div>
                <div className="paragraph">
                  Please note that the materials here serve an informative
                  purpose only, and are meant to help you make an informed
                  decision in regards to purchasing, using and storing Bitcoins.
                  iCryptoGaming casino is not affiliated with any of the websites or
                  products mentioned within the content, so we are not to be
                  held responsible for any losses or issues you may encounter
                  while using the mentioned websites or products.
                </div>
              </div>
            )}

            {topic === "history-of-bitcoins" && (
              <div>
                <h1 className="dark_blue">
                  <strong>History of Bitcoins</strong>
                </h1>
                <div className="paragraph">
                  Bitcoin (BTC) is a cryptocurrency, which means it is not
                  reliant on any centralized regulating authority. It was
                  created in November 2008, at the tail end of the financial
                  crisis, by Satoshi Nakamoto in a paper titled “bitcoin: A
                  Peer-to-Peer Electronic Cash System.” In the paper, Satoshi
                  Nakamoto, which is likely a pseudonym, described an ideal
                  currency which involved a system of electronic transactions
                  that did not rely on trust. Two months later, in January 2009,
                  would be the advent of the bitcoin. With the release of the
                  first bitcoin client, the bitcoin network came into being.
                  Satoshi Nakamoto was the first to “mine” bitcoins; this first
                  bitcoin retrieval is known as “The Genesis Block”. One of the
                  first ever transactions using bitcoins involved two Papa
                  John’s pizzas for 10,000 bitcoins negotiated in the
                  bitcointalk forum.
                </div>
                <div className="paragraph">
                  Historically, bitcoins have been a secure, reliable method for
                  peer to peer transactions. One notable vulnerability occurred
                  in August 2010. A loophole was present in the system which
                  allowed users to bypass bitcoin restrictions and create an
                  indefinite quantity of bitcoins. Over 184 billion bitcoins
                  were created in such a manner, and were divested to two
                  addresses within the network. Soon thereafter, the fraudulent
                  transaction was detected and eradicated from the bitcoin
                  transaction log. The bug was then fixed with an updated
                  version of bitcoin protocol. Since its creation, this was the
                  only major security breach to ever impede the credibility of
                  the bitcoin.
                </div>
                <div className="paragraph">
                  In 2012, bitcoin was featured on an episode of “The Good
                  Wife”, in which Jim Cramer, host of CNBC’s “Mad Money”, said:
                  <em>
                    “There’s no central bank to regulate it; it’s digital and
                    functions completely peer to peer”.
                  </em>
                </div>
                <div className="paragraph">
                  Jim Cramer’s comments, despite them being in a fictional TV
                  show, are quite accurate. Bitcoins, which were created at the
                  end of the internationally detrimental financial collapse of
                  2008, quickly raised in popularity and value. Based in the
                  underground, many began accepting bitcoins as a legitimate,
                  valued form of currency. Wikileaks among other organizations
                  began to accept them as a form of donation. Processors like
                  BitPay reported over 1,000 viable merchants that accepted
                  bitcoin by 2012. Coinbase, another bitcoin-based payment
                  processor, reported selling 1,000,000$ worth of bitcoins in a
                  single month when a bitcoins was valued around 22$. One of the
                  greatest advantages to this form of currency was its lack of
                  regulation, and thus taxability.
                </div>
                <div className="paragraph">
                  In the United States, the Financial Crimes Enforcement Network
                  (FinCEN) created guidelines regulating “decentralized virtual
                  currencies”. This was directly aimed at currencies such as
                  bitcoin, and officially classified American bitcoin miners
                  (generators), who then go on to sell these bitcoins, as “Money
                  Service Businesses” (MSB), which rendered them liable to
                  register for the various legal obligations that coincide with
                  MSB’s. This is one of the first instances in which these
                  currency vendors were regulated by government, which is the
                  antithesis to the entire philosophy of cryptocurrency. This
                  only applied to those who would sell the mined currency, so
                  peer to peer transaction was not included in this regulation.
                </div>
                <div className="paragraph">
                  On May 15, 2013, Mt. Gox, a bitcoin exchange involved with
                  various customer accounts, had some of their accounts seized
                  by FinCEN due to being unregistered money transmitters. Soon
                  after in June 2013, the US Drug Enforcement Administration
                  accounted bitcoins as a seized asset during an audit. In
                  August 2013, the Eastern District of Texas of the Fifth
                  Circuit deemed it a “currency or a form of money”, which
                  rendered the currency to be subject to legal jurisdiction. The
                  Finance Ministry of Germany deemed it a “unit of account”,
                  which made it technically a financial instrument, but not
                  necessarily a functioning currency. One of the largest
                  seizures of all time occurred when the FBI took over the
                  assets from the underground website “Silk Road”; they seized
                  26,000 BTC from owner Ross William Ulbricht.
                </div>
                <div className="paragraph">
                  Robocoin and Bitcoiniacs, two BTC companies, created the
                  world’s first bitcoin ATM on October 29, 2013 in Vancouver,
                  Canada. In November 2013, Nicosia University in Cyprus was the
                  first school to accept BTC for tuition costs. The university’s
                  chief financial advisor deemed it the “gold of tomorrow”. Las
                  Vegas soon followed suite and many of their casinos, such as
                  Golden Gate Hotel and Casino, began accepting BTC throughout
                  the premises.
                </div>
                <div className="paragraph">
                  The growing popularity of BTC is undeniable. It is currently
                  worth around 430$. Here is a graph of its worth over time, in
                  which it nearly reached 1200$ in late 2013. In 2015 it
                  steadily raised up until present day. For peer to peer
                  transactions, BTC is one of the preferred currencies.
                  Although, it has had its volatile moments, meaning that its
                  price in USD can change dramatically over a short period of
                  time.
                </div>
                <div className="paragraph">
                  In April 2013, payment processors such as BitInstant and Mt.
                  Gox experienced transaction delays due to an exceeded
                  capacity, this resulted a drop from 266$ to 76$, upon
                  satisfying the required capacity, BTC rose back up to 160$.
                  All of this was within 6 hours. This volatility has become
                  rare in recent times, mostly because technological hiccups are
                  less common. Despite this, there is still minimal regulation
                  effecting this currency, making it quite unpredictable.
                </div>
                <div className="paragraph">
                  On June 24, 2015, NASDAQ, the second largest finance stock
                  exchange in the world, agreed to begin trials on a Private
                  Market for trading pre-IPO company shares backed by
                  blockchain. NASDAQ will begin issuing, managing, and
                  cataloging transactions for privately issued shares working
                  with an application program interface (API) developing chain.
                  This increases the scope, value and perception of BTC’s
                  blockchain technology; implying that various financial
                  entities will begin researching this method of payment. This
                  strongly insists that commercial banking, credit card
                  companies, and venture capitalists will be researching the
                  potential financial use of bitcoins.
                </div>
                <div className="paragraph">
                  The history of BTC has been exhilarating, and quite the roller
                  coaster ride. Despite its dramatic ups and downs, the
                  volatility of bitcoins is subsiding, making it a stable, yet
                  profitable, venture to pursue. Be sure to make knowledgeable
                  decisions when delving into the bitcoin market. The
                  ever-expanding history of BTC is only surpassed by its
                  exciting, lucrative future.
                </div>
              </div>
            )}
            {topic === "where-to-get-bitcoins" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Where Can I Buy or Sell Bitcoins?</strong>
                </h1>
                <div className="paragraph">
                  Bitcoins can be acquired from many different places. Once
                  you’ve set up your&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/bitcoin-wallets">
                    wallet
                  </Link>
                  , you will need to load it up with Bitcoins to spend or invest
                  in. The best place to purchase BTC is from online exchanges.
                  Using an exchange may require either using a wire transfer
                  from your bank to the Bitcoin owner’s bank, credit or debit
                  card purchases, or even an in-person cash exchange.
                </div>
                <div className="paragraph">
                  Before you purchase your Bitcoins, you need to make sure that
                  you are aware of the current exchange rates. Since Bitcoins
                  fluctuate in value like any other currency, make sure that you
                  are up to date on the current rates.
                </div>
                <h3 className="dark_blue">Online Bitcoin Exchanges</h3>
                <div className="paragraph">
                  Arguably the easiest and most straightforward method of
                  purchasing BTC is from online exchanges. Using an online
                  exchange offers many methods of payment from MoneyGram’s,
                  Western Union, cash, wire transfers, Money Orders or credit
                  and debit cards. As always, make sure the site and users are
                  reputable in order to maximize your experience. Usually you
                  will find the current exchange rates or prices listed directly
                  on the site.
                </div>
                <div className="paragraph">
                  <strong>
                    To find out how to buy Bitcoins in your country, please
                    visit:&nbsp;
                    <Link rel="nofollow" to="http://howtobuybitcoins.info/">
                      http://howtobuybitcoins.info
                    </Link>
                  </strong>
                </div>
                <div className="paragraph">
                  <strong>Other reputable sites are:</strong>
                </div>
                <ul>
                  <li>
                    <Link rel="nofollow" to="https://www.quadrigacx.com/">
                      https://www.quadrigacx.com
                    </Link>
                    &nbsp;
                  </li>
                  <li>
                    <Link rel="nofollow" to="https://cex.io/">
                      https://cex.io
                    </Link>
                    &nbsp;
                  </li>
                </ul>
                <div className="paragraph">
                  Naturally there are many more options available depending on
                  your current country of residence and you may need to research
                  a local exchange that operates in your nation that accepts
                  payment methods you are able to fulfill.
                </div>
                <h3 className="dark_blue">In-Person Bitcoin Purchases</h3>
                <div className="paragraph">
                  So you’ve decided to purchase your Bitcoins in an in-person
                  exchange for cash. While there’s nothing wrong with that,
                  there are certainly some things you need to take into
                  consideration. First and foremost, make sure the user you are
                  buying from is reputable in order to prevent personal injury
                  and theft. Secondly, make sure the place you meet is both
                  public/safe and has internet access as the seller will need to
                  use the internet to send you your BTC. The vast majority of
                  users who sell in person are legitimate, but erring on the
                  side of caution has its advantages.
                </div>
                <div className="paragraph">
                  We also want to remind you that in-person BTC purchases may
                  additionally be subject to fees and some sellers may charge up
                  to 5% of the transaction to cover usage of their personal
                  time.
                </div>
                <h3 className="dark_blue">Bitcoin ATMs</h3>
                <div className="paragraph">
                  While this is a newer concept, Bitcoin ATMs are becoming
                  increasingly popular. They operate similar to an in-person
                  exchange but require a smart phone with software installed
                  from your&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/bitcoin-wallets">
                    wallet
                  </Link>
                  &nbsp;provider. You insert your cash into the Bitcoin ATM and
                  use your mobile application to scan the QR code, matching
                  your&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/bitcoin-wallets">
                    wallet
                  </Link>
                  &nbsp;to the transaction. Shortly after, the BTC will appear
                  in your&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/bitcoin-wallets">
                    wallet
                  </Link>
                  .
                </div>
                <div className="paragraph">
                  To find a local BTC ATM you can use Google or visit:
                </div>
                <div className="paragraph">
                  <Link
                    rel="nofollow"
                    to="http://www.coindesk.com/bitcoin-atm-map"
                  >
                    http://www.coindesk.com/bitcoin-atm-map
                  </Link>
                </div>
                <div className="paragraph">
                  Be aware that some BTC ATMs charge an exchange rate anywhere
                  from 3-8% on top of the standard rate.
                </div>
                <h3 className="dark_blue">Preventing Fraud</h3>
                <div className="paragraph">
                  Be careful when using your credit card or PayPal to purchase
                  Bitcoins. These transactions can be exploited using
                  “chargebacks”, meaning that somebody can cancel the exchange
                  effectively keeping both the money and the Bitcoins. This
                  method is usually not used since it is well known to be
                  exploitative. Also make sure that you are purchasing from a
                  reputable vendor with solid reviews so that you can guarantee
                  a safe transaction. Researching the site you use is also a
                  necessity to make sure you are making a legitimate purchase.
                  While purchasing BTC is generally safe, it is always best to
                  be on the side of caution.
                </div>
                <h3 className="dark_blue">Conclusion</h3>
                <div className="paragraph">
                  Hopefully you have a general understanding of how Bitcoins
                  work, how to properly store and purchase them. If not, you can
                  always find other sources of information like Bitcoin sites,
                  forums, Google and YouTube. Joining the world of Bitcoins is
                  an amazing experience and opens the doors for the acquisition
                  of many new items or services. As the Bitcoin community
                  continues to grow, so will the value of your Bitcoins. The
                  best time to start collecting BTC is now, and we hope this
                  guide has given you a helping hand in getting started.
                </div>
                <div className="paragraph">
                  Please note that the materials here serve an informative
                  purpose only, and are meant to help you make an informed
                  decision in regards to purchasing, using and storing Bitcoins.
                  iCryptoGaming casino is not affiliated with any of the websites or
                  products mentioned within the content, so we are not to be
                  held responsible for any losses or issues you may encounter
                  while using the mentioned websites or products.
                </div>
              </div>
            )}
            {topic === "bitcoin-wallets" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Getting Started With Bitcoins</strong>
                </h1>
                <div className="paragraph">
                  So you understand how Bitcoins work, how they’re secured, and
                  how&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/how-to-acquire-store-and-sell-bitcoins">
                    acquiring BTC
                  </Link>
                  &nbsp;sooner rather than later can bring you exponential
                  profits. Now we’ll discuss how you can get started and what
                  you can do to maximize your Bitcoin experience.
                </div>
                <div className="paragraph">
                  Before you get started buying Bitcoins, you need to set up a
                  Bitcoin wallet. This is essentially your Bitcoin bank account
                  and will house all your Bitcoins while also being your method
                  of sending and receiving payments. There are several types of
                  wallets available, each with their own benefits and drawbacks.
                  Pay close attention to the following and decide which method
                  is best for you.
                </div>
                <div className="paragraph">
                  One critical consideration you need to ponder is that your
                  Bitcoin wallet needs to be backed up on a regular basis. This
                  can be done manually or you can allow an external organization
                  take care of this for you - depending on your own confidence
                  using and understanding computing technologies. When a bitcoin
                  wallet is lost or corrupted, the bitcoins it contains are lost
                  forever. If you’re okay with letting somebody else handle this
                  for you, then perhaps a cloud wallet is the way to go. If you
                  want to handle this responsibility yourself, you can but you
                  need to be vigilant in backing up your wallet in case anything
                  goes wrong. There are also hardware wallets available, using a
                  specialized USB drive to store your bitcoins. This is the
                  safest method as accessing your wallet requires access to the
                  USB drive. Using this guide you will discover which option
                  will suit your needs best.
                </div>
                <h3 className="dark_blue">Cloud Wallets</h3>
                <div className="paragraph">
                  Cloud wallets are recommended for users who have little
                  experience or knowledge of modern computer technologies, or
                  for those who want an easier method of storing Bitcoins. When
                  you use a cloud wallet, you trust an external organization to
                  maintain and secure your Bitcoins. This means you are not
                  handling the primary responsibility of securing and backing up
                  your Bitcoin wallet.
                </div>
                <div className="paragraph">
                  When using a cloud wallet, you need to make sure that you are
                  using a 2 factor authentication by email. This means that, in
                  order to access your wallet stored on the cloud, you will need
                  to also be able to access your email. Using a different
                  password on both your email and wallet will help maximize the
                  security of your wallet. You also need to make sure that you
                  are still creating your own backups, in case something happens
                  on the cloud’s side of things. While we have personally
                  recovered our own wallets using the backups provided to us by
                  our cloud wallet provider, it’s definitely best practice to
                  make sure you have a backup of your own wallet just in case.
                </div>
                <h3 className="dark_blue">Software Wallets</h3>
                <div className="paragraph">
                  The primary benefits of using software wallets are both
                  security and control. Since you are responsible for your own
                  wallet and aren’t handing over the responsibility of wallet
                  security to an external source, this gives you a lot of
                  options to not only maximize and utilize every additional
                  security method available but also back up your own wallet
                  manually to a USB drive. Be careful when choosing this option
                  - if you lose your wallet, it’s gone for good (along with all
                  of your bitcoins). However, if you’re tech savvy and thrive
                  under the idea that your wallet couldn’t possibly be more
                  secure and that you know how to properly backup, store and
                  maintain your wallet, this is the option for you.
                </div>
                <h3 className="dark_blue">Hardware Wallets</h3>
                <div className="paragraph">
                  <Link to="https://www.casino.com/bitcoin-guide/using-a-trezor-for-bitcoin-casino-deposits">
                    Hardware wallets
                  </Link>
                  &nbsp;operate as intermediaries between you and your wallet,
                  offering a greater protection scheme than standard software
                  and cloud based wallets. The only downside to hardware wallets
                  is that they cost money, but hey, you spend money on a good
                  wallet to store your credit cards, so why not spend some money
                  on a wallet to handle your digital currency as well? Hardware
                  wallets are physical devices which store your Bitcoins offline
                  in USB devices and work in tandem with your wallet. In order
                  to send payments from your wallet, the physical hardware is
                  required along with a password, incorporating an additional
                  level of security for your Bitcoins. These devices can’t be
                  infected with viruses and other malware, and implement
                  cryptography to privatize all data on the device. The
                  operating systems of some of these devices are completely open
                  source, meaning anybody can look at the code and verify that
                  it does exactly what it’s designed to do with no tricky
                  exploits hidden within. Be warned, however, that losing your
                  hardware wallet will likely result in the loss of your
                  bitcoins so take extra care if you decide to purchase one.
                </div>
                <div className="paragraph">
                  Some recommended hardware wallets are:
                </div>
                <ul>
                  <li>
                    <Link rel="nofollow" to="https://www.bitcointrezor.com/">
                      https://www.bitcointrezor.com
                    </Link>
                  </li>
                  <li>
                    <Link rel="nofollow" to="http://bitcoincard.org/">
                      http://bitcoincard.org
                    </Link>
                  </li>
                </ul>
                <h3 className="dark_blue">Choosing Your Wallet</h3>
                <div className="paragraph">
                  Now that you understand the differences between a cloud-based,
                  hardware-based and software-based wallet, you can make an
                  informed decision about which option best suits your needs.
                  While we’re not here to promote any specific wallet method as
                  individual needs may vary, we do encourage you to research
                  each wallet method or provider thoroughly. For a comprehensive
                  list of wallets, please visit&nbsp;
                  <Link
                    rel="nofollow"
                    to="https://bitcoin.org/en/choose-your-wallet"
                  >
                    https://bitcoin.org/en/choose-your-wallet
                  </Link>
                </div>
                <div className="paragraph">
                  Please note that the materials here serve an informative
                  purpose only, and are meant to help you make an informed
                  decision in regards to purchasing, using and storing Bitcoins.
                  iCryptoGaming casino is not affiliated with any of the websites or
                  products mentioned within the content, so we are not to be
                  held responsible for any losses or issues you may encounter
                  while using the mentioned websites or products.
                </div>
              </div>
            )}
            {topic === "bitcoin-exchanges" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Online Bitcoin Exchanges</strong>
                </h1>
                <div className="paragraph">
                  Arguably the easiest and most straightforward method of
                  purchasing BTC is from online exchanges. Using an online
                  exchange offers many methods of payment from MoneyGram’s,
                  Western Union, cash, wire transfers, Money Orders or credit
                  and debit cards. As always, make sure the site and users are
                  reputable in order to maximize your experience. Usually you
                  will find the current exchange rates or prices listed directly
                  on the site.
                </div>
                <div className="paragraph">
                  <strong>
                    To find out how to buy Bitcoins in your country, please
                    visit:
                    <Link rel="nofollow" to="http://howtobuybitcoins.info/">
                      http://howtobuybitcoins.info
                    </Link>
                  </strong>
                </div>
                <div className="paragraph">
                  <strong>Other reputable sites are:</strong>
                </div>
                <ul>
                  <li>
                    <Link rel="nofollow" to="https://www.quadrigacx.com/">
                      https://www.quadrigacx.com
                    </Link>
                  </li>
                  <li>
                    <Link rel="nofollow" to="https://cex.io/">
                      https://cex.io
                    </Link>
                  </li>
                </ul>
                <div className="paragraph">
                  Naturally there are many more options available depending on
                  your current country of residence and you may need to research
                  a local exchange that operates in your nation that accepts
                  payment methods you are able to fulfill.
                </div>
              </div>
            )}
            {topic === "how-to-acquire-store-and-sell-bitcoins" && (
              <div>
                <h1 className="dark_blue">
                  <strong>How to Acquire, Sell, and Store Bitcoin</strong>
                </h1>
                <div className="paragraph">
                  Acquiring, selling, and storing bitcoins (BTC) are three of
                  the most basic, yet essential aspects to familiarizing
                  yourself with the workings of this multi-faceted
                  cryptocurrency. This article will address where bitcoins come
                  from, along with where to buy, sell and store them.
                </div>
                <div className="paragraph">
                  BTC (bitcoins) were originally created by Satoshi Nakamoto,
                  who to this day remains an anonymous, somewhat legendary
                  figure. What started out being worth less than a penny, has
                  now become worth hundreds of dollars, depending on the market.
                  This digital cryptocurrency is acquired in various ways.
                </div>
                <h3 className="dark_blue">Acquiring Bitcoins</h3>
                <ol>
                  <li>
                    You can receive them as payment for merchandise or services
                  </li>
                  <li>
                    You can purchase them directly from the&nbsp;
                    <Link
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      to="https://howtobuybitcoins.info/#!/"
                    >
                      bitcoin exchange
                    </Link>
                  </li>
                  <li>
                    You can exchange bitcoins locally at&nbsp;
                    <Link rel="nofollow" to="https://www.localbitcoins.com/">
                      www.localbitcoins.com
                    </Link>
                  </li>
                  <li>
                    Finally, you can earn bitcoins through a technique called
                    “mining”
                  </li>
                </ol>
                <div className="paragraph">
                  Many people are already buying and selling with BTC, so one of
                  the easiest ways to start acquiring bitcoins is to sell or
                  give a service to someone who is purchasing with bitcoins.
                  This is a great way to get your foot in the door. Especially
                  if you are selling an item or service, which you are likely
                  up-charging for profit so you will receive more bang for your
                  buck from the get go. This has many advantages that cannot be
                  matched by conventional currencies. Once you begin to collect
                  a stash of bitcoins, you can use them for purchases or sell
                  the BTC directly for cash.
                </div>
                <div className="paragraph">
                  With the proper knowledge, one can have a lucrative endeavor
                  by mining bitcoins, but it is a rather complex process for
                  beginners. Paper money involves centralized government
                  entities to print and distribute currency, whereas BTC has no
                  centralized control system. Bitcoin mining involves special
                  software that allows miners to solve various math problems
                  from which they are then awarded bitcoins in exchange for
                  their work. This provides a strong incentive for people to
                  mine, as well as requiring a logical thought process to
                  acquire this
                  <Link to="https://www.casino.com/bitcoin-guide/how-to-acquire-sell-and-store-bitcoins">
                    valued cryptocurrency
                  </Link>
                  . There is all sorts of hardware that is purposed for bitcoin
                  mining as well. Despite having the ability to mine bitcoins,
                  this is not often the most lucrative way to profit from
                  investing in bitcoins. The electricity required to run this
                  hardware is in the TH/s (Terra Hertz per second) range. This
                  is a lot of money that you will be pending on electricity,
                  without as much of a profit if you were to have just bought
                  bitcoins and wait for the price to rise. Of course, this has
                  only been true in recent history, and is subject to change. It
                  is important to know of bitcoin mining, in case the dynamics
                  and economics of the system change. If you are up to date on
                  BTC news, and knowledgeable on the process, it can be a
                  worthwhile endeavor to pursue bitcoin mining.
                </div>
                <h3 className="dark_blue">Selling Bitcoins</h3>
                <div className="paragraph">
                  Bitcoins can be sold either online or in person. Each has its
                  own distinct advantages and disadvantages. Selling online is
                  the more common way of trading BTC, because the entire
                  internet is your potential buying market. There are three main
                  ways to go about selling BTC online:
                </div>
                <ol>
                  <li>
                    Through direct trade with another person, in which an
                    arbitrating entity facilitates the transaction. Common
                    options are Coinbase (USA), LocalBitcoins (USA), Bitbargain
                    (UK), and Bittylicious (UK). These sites require seller
                    registration through identity verification. Once registered,
                    you can post selling offers to attract potential buyers. The
                    website arbitrates the transaction for security purposes.
                  </li>
                  <li>
                    Through an online exchange, in which you trade with the
                    exchange itself, rather than another individual. Like direct
                    trade, you will have to register by verifying your identity,
                    but the sales are not as arduous because you are selling
                    directly to the exchange. This method has faced various
                    issues, and therefore you should thoroughly research any
                    online exchange before trusting and using its services.
                  </li>
                  <li>
                    Through a new peer-to-peer system which connects those who
                    want to buy bitcoins with traditional currently, and those
                    who want to sell bitcoins for traditional currency. Sites
                    like Purse and Brawker help connect the independent buyer
                    and seller to implement a complementary system of bitcoin
                    exchange. These sites can be used to use bitcoins to buy
                    goods from vendors that do not directly accept such
                    currencies. It also attends to those looking to buy bitcoin
                    with a debit or credit card
                  </li>
                </ol>
                <div className="paragraph">
                  The most relevant method of the three will vary from person to
                  person depending on the interests of the seller. The 3rd
                  option, the peer-to-peer system, is likely the most useful and
                  lucrative. The way it works is, someone puts up a wish to say,
                  for example, buy a guitar with bitcoins on a website that does
                  not accept bitcoins. They would want to do this because
                  bitcoins can have more buying power, thus allowing the
                  purchase to require less wealth. So, someone with cash offers
                  to buy said guitar, and in return, when the guitar arrives at
                  the requester’s house, the bitcoins will be transferred from
                  the requester to the buyer’s wallet. This process is easier
                  done than said, and is difficult to articulate to someone who
                  has not done a transaction like this. The arbitrating website
                  will take some portion of the transaction, but it is often
                  till worth it to use these services.
                </div>
                <h3 className="dark_blue">Storing Bitcoins</h3>
                <div className="paragraph">
                  Bitcoin wallets can store your bitcoins at a personal address
                  which allows you to access your funds. No matter what you do,
                  be sure this wallet is secured. Beyond the digital, you can
                  also use paper storage to avoid a potential security breach on
                  your computer. Your bitcoins are actually stored anywhere, but
                  rather, the digital security keys are stored which allow you
                  to access the addresses of your public bitcoins. It is these
                  digital security keys that are maintained within these
                  wallets.
                </div>
                <ol>
                  <li>
                    Desktop Wallets: Programs like Bitcoin Core and MultiBit are
                    examples of Desktop Wallets. DarkWallet is a program that
                    prevents people from tracking their various exchanges.
                  </li>
                  <li>
                    Mobile Wallets: Desktop programs are useful, but mobile
                    wallets allow you to purchase at a physical store. These
                    often come as apps on your smartphone, often using the
                    near-field communication (NFC) feature. This allows you to
                    tap your phone on a vendor’s reader to make a payment.
                    Examples include Bitcoin Wallet, Mycelium, and Xapo.
                  </li>
                  <li>
                    Online Wallets: This allows your bitcoins to be stored
                    online, allowing you to access them from anywhere. Online
                    Wallet companies include Coinbase, Circle, and Blockchain.
                    Although these companies have historically been reliable, be
                    careful, because technically with online wallets they are in
                    control of your bitcoins.
                  </li>
                  <li>
                    Hardware Wallets: These are devices which are dedicated
                    solely to holding bitcoin information.&nbsp;
                    <Link to="https://www.casino.com/bitcoin-guide/using-a-trezor-for-bitcoin-casino-deposits">
                      Trezor hardware
                    </Link>
                    &nbsp;wallet is one of the most commonly used hardware
                    wallets.
                  </li>
                  <li>
                    Ledger USB Wallet: USB wallets are similar to the software
                    found in hardware wallets. They use smartcard security
                    systems and look like your traditional USB device.
                  </li>
                </ol>
              </div>
            )}
            {topic === "the-value-of-bitcoin" && (
              <div>
                <h1 className="dark_blue">
                  <strong>The Value of Bitcoin</strong>
                </h1>
                <div className="paragraph">
                  The value of bitcoin (BTC) has varied significantly since its
                  advent back in 2008 by Satoshi Nakamoto. Without a centralized
                  banking authority, or a market of usability to base it off,
                  the BTC was originally worth less than a penny. The first
                  transaction using bitcoins involved two Papa John’s pizzas
                  which were bought for 10,000 bitcoins, which was negotiated in
                  the bitcointalk forum. Assuming the two pizzas were worth
                  around 25 USD, this would put the USD-BTC exchange rate at
                  400-1, making BTC worth one fourth of a penny. This is not
                  that bad, considering these coins were mined for no cost
                  except electricity. Despite its humble beginnings, the bitcoin
                  would soon experience a prolific, unprecedented rise in value.
                </div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/4e20d66cc4f9ef19dcd2ef518b5de4fb4cc3738f_value_of_bitcoin.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  As shown above, the value of BTC almost reached $1000 USD
                  before the dawn of 2014. This dramatic increase from $100 to
                  almost $1000 USD per bitcoin occurred in less than two months.
                  Since then, it has dropped, raised, and settled around $400
                  USD as of January 20th, 2016. The value, as with any free
                  market item, is determined by what the people are willing to
                  pay for it. With bitcoin, people have invested their faith in
                  this curious cryptocurrency. Not just people, but bankers,
                  vendors, and investors are all hopping on board the BTC train,
                  aspiring to make money off of this lucrative currency.
                </div>
                <div className="paragraph">
                  The volatility of bitcoins, meaning the likelihood of a
                  sporadic change in prince, has been historically worrisome for
                  BTC users. Within about 5 months in late 2013 and early 2014,
                  BTC jumped to around $1000 USD from $100 USD, and then back
                  below $500 USD. During these volatile time periods, you could
                  gain or lose large amounts of money investing in BTC.
                  Recently, the volatility has decreased, and the price is
                  relatively stable, albeit still being prone to significant
                  daily alterations. For example, in 2015, its value in USD
                  increased by 21%. As bitcoins become more and more acceptable
                  as a currency, the price will likely increase because this
                  implies that people are realizing it as a worthwhile method of
                  transaction.
                </div>
                <div className="paragraph">
                  Let’s analyze year-by-year the historical values of BTC
                </div>
                <div className="paragraph">2011:&nbsp;</div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/3ad161a0efff180853df1e114df93b7007a21556_image2.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  In 2011 was one of the first price booms that occurred for the
                  BTC. Within a couple months, the price spike from under $1, to
                  around $30, followed by a relatively quick drop back to $1
                  within the ensuing months. We see these mini booms throughout
                  the history of the value of bitcoins. These were somewhat
                  prophetic of the eventual booms of increasing value exhibited
                  by the BTC in the later years.
                </div>
                <div className="paragraph">2012:&nbsp;</div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/cf487606aa415ee94c056e7235b6d46b779639af_image3.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  In 2012, there was a steady increase in BTC value from around
                  $4 to $15 USD. There was not many drastic changes in price
                  besides a significant 40% drop in late August. This was a
                  momentous year for BTC because it demonstrated its ability to
                  grow in a stable manner, and also new markets were now
                  accepting BTC as an acceptable currency. It is also worth
                  noting that Silk Road, an underground website that accepts BTC
                  and sells illicit items, was up and running during this year.
                  The following year in 2013 this would be shut down, but the
                  value of BTC persevered and has its most exciting year in its
                  history
                </div>
                <div className="paragraph">2013:&nbsp;</div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/622e2630d50a258bc6e6b6d8e74a90fabccca2dd_image4.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  If you were the man or woman who was paid 10,000 BTC for two
                  papa johns pizzas back in 2008, you were in for a treat in
                  2013 if you were patient enough to hold on to your
                  ever-increasing BTC. If he or she sold their 10,000 BTC in
                  November of 2013, they would have essentially earned
                  $10,000,000 USD from a 5 year investment. This makes those
                  original two papa john’s pizzas the most expensive pizzas, in
                  hindsight, ever to be bought. From its humble beginnings, this
                  would have been hard to predict. Who in the original
                  bitcointalk forum could have guessed that the price would jump
                  from a fraction of a penny to a thousand dollars in 5 years?
                  Many have speculated as to whether or not BTC will ever
                  rebound back to its $1000 USD glory. It seems likely,
                  especially since BTC is still in its infant phase, and still
                  requires more efficient, and safe programs to help arbitrate
                  its transactions. There is also the doubt in many people’s
                  minds that the value of BTC may crash, but this seems very
                  unlikely. If anything, BTC is in store for another prolific
                  rise in value as exhibited in 2013, especially when
                  considering the rising debt ceiling, and rising inflation
                  rates, BTC seems like a safe investment during these times.
                </div>
                <div className="paragraph">2014:&nbsp;</div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/1bb6468058c81b91e634cc55ffebe8d9c238b4c1_image5.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  In 2014, the value of BTC suffered from the 2013 bubble. The
                  dramatic ups and downs through 2014 made this an exciting,
                  albeit downward, year for BTC. The end of 2014/the beginning
                  of 2015, and even today at around $400 BTC/USD, seems like the
                  time to buy. It seems likely that the BTC will bounce back to
                  its 2013 glory, because the acceptability trend is going
                  upwards; more and more vendors, banks and investors are
                  accepting this as a valid currency. With more backers, another
                  boom is likely, especially facing inflation.
                </div>
                <div className="paragraph">2015:&nbsp;</div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/58bf5d747dd4ad4c2060f9dfa2941d0e003d65d6_image6.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  2015 also saw its dramatic ups and down, but overall saw a 21%
                  rise. This was powerful for the BTC value, because it
                  encouraged people to believe that BTC will continue to be a
                  valuable form of currency. If the crash would have continued,
                  people may have begun to lose faith in BTC throughout 2015.
                  With a timely rise in value, it seems as though BTC is here to
                  stay; vendors and banks seem to agree.
                </div>
                <div className="paragraph">
                  Where is the value of BTC headed? It is hard to tell. From
                  examining its history, growth seems inevitable, but the
                  ever-present thought of a potential crash is always in the
                  back of the minds of users and investors alike. Despite all
                  this, it has made it this far, and the future of BTC appears
                  to be bright. It will be interesting to keep an eye on the
                  bitcoins in the years to come.
                </div>
              </div>
            )}

            {topic === "using-a-trezor-for-bitcoin-casino-deposits" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Using a Trezor for Bitcoin Casino Deposits</strong>
                </h1>
                <div className="paragraph">
                  If you are in any way familiar with the use and function of
                  Bitcoin on the web, you are more than well-aware of the fact
                  that most users opt for storing their bitcoins in some sort of
                  web-based wallet or safe. This has been one of the only
                  methods of safe-harboring bitcoins since the currency’s
                  inception, but with recent changes to the way hackers and
                  fraudsters work, it is becoming clear that web-based bitcoin
                  wallets are not as safe and secure as once believed.
                </div>
                <div className="paragraph">
                  For those out there who would like to utilize bitcoin, and
                  even those who utilize it already and are looking for a safe
                  place for storage, Trezor may be the answer to all of your
                  security concerns. In essence, Trezor is nothing more than an
                  added layer of protection sheltering you from any and all
                  unscrupulous characters who might be attempting to access your
                  bitcoin stash. Protected by a security key only known to you
                  and existing as a separate piece of hardware from your
                  computer, Trezor provides more protection than you will
                  probably ever need. By protecting any and all outbound bitcoin
                  transactions, Trezor actively thwarts keyloggers and other
                  hackers from being able to access your wallet and stealing or
                  otherwise making use of your money without authorization.
                </div>
                <h3 className="dark_blue">How Trezor Works</h3>
                <div className="paragraph">
                  Trezor is not a computer program or any type of software that
                  is downloaded to your computer, but rather, it exists as a
                  separate device quite similar to a small external hard drive.
                  Most Trezor devices come packaged in a small box and are
                  accompanied by a short micro-USB cable used to connect the
                  device to whatever computer you choose. The actual Trezor
                  device is quite small, made of plastic, and unassuming in
                  nature. What’s more, it is small enough that it can be safely
                  stored in anything from pants pockets, to a suitcase, and even
                  in a bookbag or work bag.
                </div>
                <div className="paragraph">
                  The installation process for a Trezor is by no means
                  difficult, but there are certain steps you must take in order
                  to install the device successfully. Being that we are dealing
                  with a device intended to provide security, a few red flags
                  would be raised if the installation process were quick,
                  simple, and straightforward.
                </div>
                <div className="paragraph">
                  After you have connected the Trezor device to your computer,
                  your next step is to download a browser plugin known as
                  myTREZOR. Once myTREZOR has been successfully installed, it
                  will prompt you to enter a PIN. Instead of physically typing
                  in numbers, however, the myTREZOR webpage will simply ask you
                  to click numbers on a keypad that has been scrambled. The PIN
                  you utilize will then be used on your Trezor device, but each
                  and every time you enter the PIN on the device the number
                  layout changes, meaning that keyloggers and other hackers
                  would be physically unable to steal your PIN because it
                  changes each and every time you log in.
                </div>
                <div className="paragraph">
                  Once your PIN has been set, you will then need to take note of
                  a 24-word seed that can be utilized in the event of you losing
                  or misplacing your Trezor. It is particularly important that
                  you take note of the 24-word seed as it is the only method by
                  which you can recover your bitcoin wallet should the Trezor
                  become compromised. As far as what wallets can be of use when
                  attempting to utilize the 24-word recovery seed, Satoshi Labs,
                  which created Trezor, confirmed that the accepted wallets are
                  as follows: Mycelium 2.0, Multibit HD, and Wallet32.
                </div>
                <h3 className="dark_blue">Price of Trezor</h3>
                <div className="paragraph">
                  The price you will pay for a Trezor device ranges from seller
                  to seller, but the baseline cost is typically in the $100
                  range. This is an extremely affordable option for safeguarding
                  your bitcoins, as there are horror stories of people losing
                  thousands and thousands of dollars because the security of
                  their trusted bitcoin wallet was compromised.
                </div>
                <h3 className="dark_blue">Trezor and Online Casinos</h3>
                <div className="paragraph">
                  For the online casino enthusiast, Trezor presents you with
                  perhaps the safest and most secure way by which you can
                  transfer funds to an online player account. The initial setup
                  of a Trezor device will take you in the realm of 15-20
                  minutes, but every subsequent use of the device will be as
                  simple and painless as it can possibly be.
                </div>
                <div className="paragraph">
                  Depositing via Trezor is different from web-based bitcoin
                  wallets in that you need to provide a bit more authentication.
                  In order to verify outgoing transactions (deposits at online
                  casinos), you will be prompted to enter your PIN on your
                  device and subsequently verify that the amount you are sending
                  as well as the address you are sending it to are both correct.
                  In most cases, online casino users utilizing Trezor do so in
                  order to avoid having all of their bitcoins located in one,
                  vulnerable spot. If you have 50 BTC but are only seeking to
                  deposit 5 BTC, you are able to use Trezor to transfer the 5BTC
                  to your bitcoin wallet so that you can then deposit it into
                  your player account. In this hypothetical example, even if
                  your bitcoin wallet was compromised after you executed your
                  deposit, the hackers would be left with nothing to take as the
                  5 BTC is already in your online player account, and your other
                  45 BTC is sitting safely on your Trezor device, protected from
                  anyone and everyone.
                </div>
                <div className="paragraph">
                  You can never underestimate the prowess and ability of
                  unscrupulous internet hackers. Utilizing Trezor is just one
                  way to stay ahead of the game and protect your money. Bitcoin
                  is still very much the Wild West as far as currencies are
                  concerned, and that leaves you with all the more reason to
                  stop at nothing to make sure that every bitcoin you own is
                  safe and secure at all times.
                </div>
              </div>
            )}
            {topic === "transaction-speed-security-fees-and-options" && (
              <div>
                <h1 className="dark_blue">
                  <strong>
                    Transaction Speed, Security, Fees, and Options
                  </strong>
                </h1>
                <div className="paragraph">
                  here are many ways to transfer bitcoins (BTC). The speed,
                  security risk, and cost of these various options are worth
                  looking into so you can use the transaction program that is
                  tailored to your needs
                </div>
                <h3 className="dark_blue">Transaction Speed</h3>
                <div className="paragraph">
                  By design, bitcoin transactions take around 10 minutes for
                  each transaction. This is because bitcoin transfer requires
                  multiple confirmations through the mining process, which
                  ranges from a few minutes to a half an hour. Despite this, a
                  transaction is often irreversible once the initial transfer is
                  digitized. Companies like CoinSafe, a free bitcoin ATM
                  service, are pioneering ways to reduce the required
                  transaction time to make the payment process less arduous. By
                  monitoring mining machine nodes, they believe they can reduce
                  the average wait time from 10 minutes to 10 seconds; this
                  would surely be a great advantage to this bitcoin ATM (BTM)
                  company. The tricky part is, that this would be exclusive to
                  CoinSafe, so it could either make them the exclusive heir to
                  the most efficient BTM processing, or alienate them due to
                  lack of compatibility. It is likely that the former would
                  occur, especially if it means 1/10th of the wait time. Be sure
                  to keep up to date on transaction speed technology, because it
                  will allow you to enlighten your investments and see where the
                  future of BTC transactions is heading.
                </div>
                <h3 className="dark_blue">Security</h3>
                <div className="paragraph">
                  Since BTC is not regulated by any centralized authority, there
                  have been questions regarding the security of this
                  cryptocurrency. Historically, there have been very few
                  security breaches that have allowed fraudulent transactions.
                  The ingenuity of bitcoins has made it a safe option in terms
                  of avoiding cyber theft.
                </div>
                <div className="paragraph">
                  Although, back in August of 2010 a loophole was found in the
                  system which allowed some greedy users to bypass the inherent
                  BTC transaction restrictions which allowed them to create an
                  indefinite number of bitcoins. With a ravenous act of greed,
                  Over 184 billion bitcoins were created in such a manner.
                  Within that same day the blatant fraudulent transaction was
                  detected and the fraudulent bitcoins were destroyed. The bug
                  was fixed and an updated version of bitcoin protocol was
                  implemented. This was the only major known glitch to hit the
                  BTC market, and it was swept clean by the internet militia
                  rather hastily.
                </div>
                <div className="paragraph">
                  The infrastructure of bitcoin protocol is very secure, but the
                  problem resides in the various wallets and services used for
                  bitcoin transactions. This means it is imperative that you
                  find the most secure programs and hardware to handle your
                  precious BTC currency. If any security breach occurs, you can
                  assume that it was some sort of deficiency in the program or
                  your computer’s security. Examples of program breaches are
                  rather numerous.
                </div>
                <div className="paragraph">
                  Inputs.io was hacked twice for a total of 4,100 bitcoins worth
                  around 1.2$ million USD at the time. The hackers infiltrated
                  the system cloud-hosting provider and gained access to various
                  funds. The hacker gained access to the site’s accounts on
                  Linode through a series of old email accounts and reset the
                  site’s account password. Mt. Gox also suffered immensely from
                  internet hackers. They recently filed for bankruptcy
                  protection after losing 468$ million USD worth of bitcoins to
                  fraudulent transactions. Investigators believe the thieves
                  took advantage of transaction malleability, which allowed them
                  to modify the transaction to trick the system into thinking
                  the transaction had not occurred, when in actuality, the BTC
                  infrastructure had executed the transfer. After this breach,
                  transactions were temporarily frozen, but efficient bitcoin
                  exchanges such as Bitstamp and BTC-E resolved these issues
                  shortly after the breach, and resumed operation with a few
                  days.
                </div>
                <div className="paragraph">
                  It was not just the BTC transfer and storage programs which
                  suffered, Silk Road 2.0 suffered a loss of 2.7$ million USD
                  worth of bitcoins from its escrow account. Because Silk Road
                  is not necessarily accustomed to BTC security, they had no
                  fail safe to prevent such a large theft from occurring.
                </div>
                <h3 className="dark_blue">Transaction Fees</h3>
                <div className="paragraph">
                  Although most bitcoin transactions are free of charge, one of
                  the downsides to BTC transactions is that they sometimes do
                  require a transaction fee. Fees often incur when transactions
                  draw coins from many bitcoin addresses, which requires a large
                  amount of data. For transactions like these, a small fee is
                  usually expected. The transaction fee is received and process
                  by the initial bitcoin miner who mined the given block. If you
                  wish to avoid unwanted transaction fees, you should be fully
                  knowledgeable of any potential fees that may incur on a
                  transfer. Transaction cancellation is also a possibility if
                  you are presented with an unexpected fee.
                </div>
                <div className="paragraph">
                  Here is a list from blockchain.info regarding the total
                  monthly transaction fees of the recent transfer period:
                </div>
                <div className=" block-img">
                  <img
                    src="https://casino.cdn.prismic.io/casino/a1c2f99fc66820c56830f3c7cd2ddeef13f4e917_totaltransactionfees.png"
                    alt=""
                    copyright=""
                  />
                </div>
                <div className="paragraph">
                  Considering that there are over 100’s of thousands of
                  transactions occurring daily, these total transaction fees are
                  nearly negligible. These fees are most common when accruing
                  numerous amounts of small payouts such as when you are
                  collecting BTC donations, affiliate revenue, or receiving
                  small quantities of free bitcoins from various sources.
                </div>
                <h3 className="dark_blue">Transaction Options</h3>
                <div className="paragraph">
                  There are many transaction options when it comes to
                  transferring BTC. As the days go by, bitcoins are becoming
                  more common and acceptable. This is ensuring their&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/the-value-of-bitcoin">
                    value as a currency
                  </Link>
                  .
                </div>
                <ol>
                  <li>
                    <strong>Bank Wire:</strong>&nbsp;Viable option for trading
                    bitcoins. Be sure to personally talk with the banks involved
                    before committing to any sort of large bank wire
                    transaction. (allows various currencies)
                  </li>
                  <li>
                    <strong>BitcoinPay:</strong>&nbsp;Payment gateway involved
                    in BTC transfer; initiates instant confirmation from the
                    bitcoin network. They offer a special guarantee which
                    promises no chargeback and no fraudulence. (allows USD, EUR,
                    PLN, CZK)
                  </li>
                  <li>
                    <strong>OKPay:</strong>&nbsp;A simple non-reversible BTC
                    processing service that supports 19 world currencies (allows
                    USD, EUR, GBP, RUB, CHF, etc)
                  </li>
                  <li>
                    <strong>WARNING - PayPal:</strong>&nbsp;Easy for
                    charge-backs, and the terms of service do not allow
                    bitcoins. This makes fraudulent transactions very easy. Try
                    to avoid PayPal for bitcoin transactions.
                  </li>
                  <li>
                    <strong>Euro SEPA Transfer:</strong>&nbsp;Often free wire
                    transferring between European banks, and is cleared in
                    approximately one working day after the transaction.
                    Fraudsters avoid this transfer service, which means it is a
                    wise choice for the noble BTC users. (Allows EUR)
                  </li>
                </ol>
              </div>
            )}
            {topic === "bitcoin-faq" && (
              <div>
                <h1 className="dark_blue">
                  <strong>
                    Typical Questions and Answers for New Bitcoin Users
                  </strong>
                </h1>
                <div className="paragraph">
                  Bitcoin is a revolutionary approach to currencies. It is one
                  of the first mainstream examples of cryptocurrency, which is a
                  digital currency based on the principles of anonymity,
                  security, and privacy. These are all highly desirable traits
                  when it comes to trading in today's modern world that's full
                  of examples of government interference and increased taxation
                  due to e-commerce. So, what are some of the typical questions
                  that newcomers typically have of Bitcoin? Let's review two of
                  the most popular ones:
                </div>
                <h3 className="dark_blue">A Simple Way to Explain Bitcoin</h3>
                <div className="paragraph">
                  Bitcoin in its most basic form is a type of money. It's one of
                  the first decentralized forms of currency that isn't
                  controlled by any one single entity: it is something that's
                  made by the people, for the people. All over the world, users
                  are currently trading using Bitcoins and these trades exceed
                  $100,000 USD on a daily basis. There are no middle men, no
                  credit card companies, and no fees associated with its use.
                </div>
                <div className="paragraph">
                  The network itself is made up of users who directly interact
                  with one another in the trading process. There is no bank or
                  payment processor to serve as a middle man, which ensures
                  Bitcoin will remain a secure and free way of buying and
                  selling on the Internet.
                </div>
                <div className="paragraph">
                  Bitcoin also benefits from a higher level of efficiency
                  against conventional means of trading on the Internet. Because
                  users are able to cut out the middle man, there's no way of
                  buyers reversing their Bitcoins back into their wallets like
                  they'd be able to by filing a chargeback claim with their
                  credit card issuers. While this ensures transactions are
                  relatively flawless, it also puts a high level of personal
                  responsibility onto users. Because merchants are in complete
                  control when it comes to offering refunds, buyers need to know
                  who they're dealing with and ensure they're reputable traders.
                </div>
                <h3 className="dark_blue">Explaining Mining</h3>
                <div className="paragraph">
                  Bitcoin is understandably full of “bitspeak” terms that are
                  unique to the platform. However, one of the most commonly
                  encountered terms is “mining” because it is how the network
                  actually operates. Instead of having a central authority
                  issuing Bitcoins and controlling the entire supply, the “work”
                  is spread out across the network. Bitcoins are generated by
                  miners who “mine” Bitcoins for their own wallets in order to
                  release them into the network.
                </div>
                <div className="paragraph">
                  Miners do this by collecting transactions on the network into
                  bundles known as blocks, which are then strung together into
                  one record known as the block chain. This doesn't permit
                  conflicting transactions and ensures users cannot use the same
                  Bitcoins for two different transactions. The block chain
                  itself lets users know that they're in good hands when it
                  comes to dealing with a particular vendor and that the
                  transaction can be trusted.
                </div>
                <h3 className="dark_blue">Conclusion</h3>
                <div className="paragraph">
                  These are by no means the only questions that are addressed
                  through various sources on the Internet. However, they stand
                  out as two of the leading ones that users most often ask.
                  Other questions and answers are readily available through
                  various sources: including the ones contained within this
                  website.
                </div>
                <div className="paragraph">
                  Please note that the materials here serve an informative
                  purpose only, and are meant to help you make an informed
                  decision in regards to purchasing, using and storing Bitcoins.
                  iCryptoGaming casino is not affiliated with any of the websites or
                  products mentioned within the content, so we are not to be
                  held responsible for any losses or issues you may encounter
                  while using the mentioned websites or products.
                </div>
              </div>
            )}
            {topic === "how-litecoin-works" && (
              <div>
                <div>
                  <h1 className="dark_blue">
                    <strong>How Litecoin Works</strong>
                  </h1>
                  <div className="paragraph">
                    In most circles, the term “cryptocurrency” is synonymous
                    with Bitcoin. While this is not necessarily correct, it is
                    easy to see why it is the case, considering Bitcoin was, and
                    still is, the most popular type of cryptocurrency. With that
                    being said, Bitcoin is far from being the only
                    cryptocurrency out there. At this point, there are dozens of
                    different cryptocurrencies, some that are like Bitcoin and
                    some that are not.
                  </div>
                  <div className="paragraph">
                    One of those other cryptocurrencies is Litecoin. If you’re
                    thinking that Litecoin’s name is eerily similar to Bitcoin,
                    you are exactly correct. If people consider Bitcoin to be
                    the gold of the cryptocurrency world, Litecoin is the
                    silver. This analogy makes sense on multiple different
                    levels, but before we go any further it would be right for
                    us to first explain how, exactly, Litecoin works.
                  </div>
                  <h4 className="dark_blue">How Litecoin Works</h4>
                  <div className="paragraph">
                    For someone who is entirely new to cryptocurrencies such as
                    Litecoin, the explanation of how it all works is not overly
                    complicated at all. In fact, it is beneficial to think of
                    Litecoin exactly like you think of traditional, fiat
                    currencies such as the USD. Litecoin is a currency that is
                    bought, sold, and traded online just like traditional
                    currencies. It can be stored in wallets that are comparable
                    to online bank accounts, and can be transferred in the same
                    way that you would move money to or from an online bank
                    account, or e-wallet such as PayPal. The difference between
                    cryptocurrencies and traditional currencies, however, is
                    that litecoins are not being created by any central
                    government or agency. Instead, they are mined. If the term
                    “mining” used in relation to a strictly online currency
                    confuses you, you are not alone.
                  </div>
                  <div className="paragraph">
                    In essence, Litecoin is able to function because millions of
                    users are offering up computing power to support the
                    network. As computing power is used to process and validate
                    transactions, those who are behind the offered power are
                    rewarded with litecoins. The reward is proportional to the
                    power offered up. Because Litecoin is governed by an
                    algorithm and no person or group of people, it is the
                    algorithm that rewards them.
                  </div>
                  <div className="paragraph">
                    To dig a bit deeper, Litecoin has become such a feature in
                    the mainstream now because of its overwhelming
                    trustworthiness. In the aforementioned algorithm, it is
                    written that there will only ever be 84 million litecoins
                    mined. This is something that ensures you that there isn’t a
                    single person or company sitting back creating litecoins,
                    thus stuffing their own pockets. Over time, as more
                    litecoins are mined, the reward the miners receive will be
                    reduced such that the mining of litecoins slows as more
                    litecoins are uncovered. Inevitably, however, there will
                    reach a time when all 84 million litecoins have been mined.
                  </div>
                  <div className="paragraph">
                    If you have even the most basic understanding of Bitcoin,
                    learning how Litecoin works will be simple. In short,
                    Litecoin can be bought and sold online in much the same way
                    as a person would buy and sell stocks. Once purchased,
                    Litecoin can be used in the same way as any other currency.
                    In almost every way, Litecoin is the quintessential
                    cryptocurrency.
                  </div>
                  <div className="paragraph">
                    What is more interesting, however, is how Litecoin compares
                    to Bitcoin. Like Bitcoin, the Litecoin algorithm works in
                    such a way that there will never be more than 84 million
                    litecoins ever mined. The finite number of litecoins that
                    will ever be available is something that helps drive the
                    value of the cryptocurrency, and also adds to the
                    trustworthiness of it all. Unlike paper money and other fiat
                    currencies, which are printed in unknown quantities and have
                    0 inherent value, Litecoin is transparent in how many there
                    are and how many there ever will be.
                  </div>
                  <div className="paragraph">
                    The absolute biggest difference between Bitcoin and Litecoin
                    is how fast it takes for a block of the currency to be
                    generated. It is generally agreed that Litecoin transactions
                    process 4x faster than Bitcoin transactions.
                  </div>
                  <div className="paragraph">
                    The algorithms governing Bitcoin and Litecoin are built
                    entirely differently, but this is something that matters
                    more to miners than it does the typical user. What end-users
                    really need to know is that just about everything relating
                    to Litecoin is faster than Bitcoin. Being that the time it
                    takes transactions to process is a big complaint coming from
                    Bitcoin users, it is easy to see why Litecoin has caught on.
                  </div>
                  <h3 className="dark_blue">
                    Buying, Selling, Trading, and Storing Litecoin
                  </h3>
                  <h4 className="dark_blue">Buying</h4>
                  <div className="paragraph">
                    When it comes to getting some Litecoin of your own, you will
                    once again be happy to know that the process is identical to
                    the process of acquiring Bitcoin. Before you can ever
                    receive Litecoin, you will first need somewhere to store it.
                    This is where the wallet comes in. A cryptocurrency wallet
                    is identical to an online bank account because it is, quite
                    literally, where your litecoins will be stored. There are
                    thousands of wallets out there, and signing up for one is a
                    process that takes just a few minutes.
                  </div>
                  <div className="paragraph">
                    When it comes to getting some Litecoin of your own, you will
                    once again be happy to know that the process is identical to
                    the process of acquiring Bitcoin. Before you can ever
                    receive Litecoin, you will first need somewhere to store it.
                    This is where the wallet comes in. A cryptocurrency wallet
                    is identical to an online bank account because it is, quite
                    literally, where your litecoins will be stored. There are
                    thousands of wallets out there, and signing up for one is a
                    process that takes just a few minutes.
                  </div>
                  <h4 className="dark_blue">Selling</h4>
                  <div className="paragraph">
                    Let’s say that, for whatever reason, there comes a time when
                    you would like to sell some or all of your Litecoin
                    holdings. If this is the case, you will be happy to know
                    that selling is quite easy. At most exchanges, it is as easy
                    as selecting how much you would like to get rid of, viewing
                    the conversion into USD (or any other fiat currency), and
                    authorizing the sale. Though the amount of time it will take
                    for the USD to make it into your bank or PayPal account can
                    and does vary, your role in the sale takes but a matter of
                    seconds.
                  </div>
                  <h4 className="dark_blue">Trading</h4>
                  <div className="paragraph">
                    Rather than sell your Litecoin, let’s say that you wanted to
                    trade it for another cryptocurrency you are interested in.
                    Once again, this is where the exchange comes in. Most
                    exchanges will facilitate trading between users, however
                    some of the more popular names do not. In fact, many of the
                    most popular exchanges only allow you to buy and sell
                    cryptocurrencies. This is not always the case, but it is
                    something worth looking into before creating an account if
                    you think that you will ever want to trade.
                  </div>
                  <div className="paragraph">
                    If the exchange which you are on does facilitate trading,
                    the process of trading Litecoin is similar to selling. The
                    only difference being, of course, that you are exchanging
                    Litecoin for another cryptocurrency rather than a fiat
                    currency.
                  </div>
                  <h4 className="dark_blue">Storing</h4>
                  <div className="paragraph">
                    As was previously mentioned, most exchanges offer a wallet
                    whereby you can store your Litecoin. This is easy and
                    convenient, but far from the safest route of storing
                    Litecoin. Because Litecoin exchanges are identical to
                    e-wallet and banking institutions online, they are
                    susceptible to being hacked. This means that if the exchange
                    which you are using is a victim of a hack, there is a chance
                    your funds will be stolen in their entirety. While this is
                    by no means a common occurrence, it has happened before and
                    can happen again.
                  </div>
                  <div className="paragraph">
                    If storing your Litecoin in an exchange’s wallet is not
                    something you would like to do, there are other, safer ways
                    whereby you can store your litecoins. Both of these methods
                    play on the fact that Litecoin only exists online. If you
                    would like to take on the task of storing and protecting
                    your own Litecoin, you can purchase an external device that
                    allows you to upload your litecoins and, literally, unplug
                    them from the internet. Think of doing this in the same way
                    you would load files onto a USB drive and unplug it from
                    your computer. Once the files are on the USB drive and the
                    drive is removed from a computer, no one can access them
                    without the drive. In the same way, once your litecoins are
                    loaded to an external device, and that device is then
                    removed from the internet, the litecoins can no longer be
                    accessed. The one pitfall to this external method of storage
                    is that if you lose your external device, or break it, your
                    litecoins will be lost forever.
                  </div>
                  <div className="paragraph">
                    Another safe way of storing Litecoin is by way of a service
                    offered by many exchanges, known as cold storage. Cold
                    storage is essentially the method of external storage
                    discussed above, only you are employing a company to do it
                    for you. This is the most hassle-free way to safely store
                    your Litecoin, but it is not necessarily the most
                    cost-efficient. The idea behind paying for cold storage is
                    that the responsibility is taken out of your hands. There is
                    no chance that you will lose your Litecoin, and this offers
                    some added peace of mind.
                  </div>
                  <h3 className="dark_blue">Safety and Security of Litecoin</h3>
                  <div className="paragraph">
                    The great thing about the Litecoin network is that the
                    cryptocurrency is able to be traded, bought, and sold
                    without anyone knowing. What we mean by this is that if you
                    are attempting to send someone money, the only people that
                    will ever know about the transaction are you as well as the
                    person or entity that is receiving the funds. The encrypted
                    nature of Litecoin’s code means that while anyone and every
                    can see the transaction, they cannot see who partook in the
                    transaction. In that sense, there is no monetary system that
                    offers more anonymity than Litecoin.
                  </div>
                  <div className="paragraph">
                    Unfortunately, the news is not all good. If you employ the
                    services of a Litecoin exchange and/or wallet, the fact of
                    the matter is that your level of security can vary
                    dramatically. Because you need to supply certain amounts of
                    personal information to these sites, the security of your
                    information is only as strong as the security of the site to
                    which you have given it. Fortunately, most of the top
                    Litecoin exchanges employ security measures that are on par
                    with those employed by a bank or other large financials
                    institution.
                  </div>
                  <h3 className="dark_blue">Litecoin Value</h3>
                  <div className="paragraph">
                    The historical value of Litecoin is about in line with what
                    you will see from most other now-popular cryptocurrenices.
                    In the earlier days the cryptocurrency’s worth was very
                    small and almost negligible. Before too long, the price of
                    Litecoin began to rise and rise. Now, the value of a single
                    Litecoin is in upwards of $50. There is a belief that the
                    value will rise even further, but that remains to be seen.
                  </div>
                </div>
              </div>
            )}
            {topic === "how-ethereum-works" && (
              <div>
                <h1 className="dark_blue">
                  <strong>How Ethereum Works</strong>
                </h1>
                <div className="paragraph">
                  Only a few years ago, the term “cryptocurrency” was known only
                  to a select group of people from different small pockets
                  around the world. Before long, the term became synonymous with
                  Bitcoin, which is perhaps the most well-known and widely used
                  cryptocurrency on the market. In more recent years, however,
                  other cryptocurrencies have emerged on the scene and are
                  actively challenging Bitcoin for supremacy.
                </div>
                <div className="paragraph">
                  One of those cryptocurrency systems is known as Ethereum.
                  Ethereum was only released in 2015, but despite its limited
                  lifespan the cryptocurrency has gained a following of millions
                  of people, with many millions more learning about it everyday.
                  Having said all of this, we would be remiss were we to not
                  mention that, despite many similarities, Bitcoin and Ethereum
                  are totally different. The following will go into great detail
                  with regard to just about everything you will ever need to
                  know about Ethereum and how it works.
                </div>
                <h4 className="dark_blue">Ethereum Explained</h4>
                <div className="paragraph">
                  Ethereum is not an overly complicated cryptocurrency to
                  comprehend, however in order to aptly understand it we need to
                  draw similarities to Bitcoin. Like Bitcoin, Ethereum operates
                  and is able to be transparent thanks to the blockchain. For
                  those who may not know, a blockchain is nothing more than a
                  public ledger where all transactions that have ever taken
                  place are stored.
                </div>
                <div className="paragraph">
                  Apart from the use of a blockchain, the similarities between
                  Bitcoin and Ethereum begin to dissipate. While Bitcoin is
                  strictly a financial vehicle like traditional currencies,
                  Ethereum strives to be much more than that. At its core,
                  Ethereum is attempting to replace large servers where all
                  sorts of personal and financial information is stored, and
                  often hacked as well. Rather than relying on a single company,
                  or handful of companies, to store all of our data, Ethereum
                  strives to store that data across a large network of
                  interconnected computers, each contributing their own
                  computing power to the network. By contributing power to the
                  Ethereum network, the users are rewarded with a cryptocurrency
                  known as Ether.
                </div>
                <h4 className="dark_blue">Why Use Ethereum</h4>
                <div className="paragraph">
                  People are drawn to Ethereum because of the overwhelming
                  uncertainty that online dealings in the modern day brings with
                  it. Let’s take your online bank account, for example. This
                  convenient tool allows you to transfer money, save money, and
                  keep track of your money with just a few simple strokes of the
                  keyboard and clicks of the mouse. This is great for so many
                  reasons, but the fact of the matter is that the password and
                  other information used to validate access to your account is
                  currently being stored on a server either operated by the
                  bank, or contracted by the bank. In the event that a hacker
                  gains access to your bank’s servers, your password—as well as
                  heaps of other information—will be compromised, and so too
                  will your personal finances.
                </div>
                <div className="paragraph">
                  Whether we are talking about the password to your bank account
                  or the password to your Facebook account, the storage of this
                  personal information is the same. With Ethereum, the thousands
                  of computers contributing computing power to the network allow
                  information to be used and stored in the same way as it is
                  now, with the exception of it not being susceptible to
                  hacking. Because the data you upload to the network will,
                  technically, be powered by a large network of computers rather
                  than one, single server, the information is spread out and
                  therefore impossible to be holistically stolen.
                </div>
                <div className="paragraph">
                  The Ethereum system strives to replace the traditional way of
                  working online. It strives to mimic everything we do
                  currently, only in a much safer, decentralized way. Though the
                  whole network is still growing and definitely in its infancy,
                  it is gaining attention from around the world. If you are
                  wondering why we really haven’t made mention of any specific
                  cryptocurrency, that is because the Ethereum network is much
                  more than a financial system alone. Though there is a currency
                  (ether), it is not the absolute main feature like is the case
                  with Bitcoin.
                </div>
                <h4 className="dark_blue">Buying and Storing Ether</h4>
                <div className="paragraph">
                  As was just mentioned, the network is Ethereum while the
                  cryptocurrency operating within that network is known as
                  ether. Ethere can be bought and sold in almost identical
                  fashion to Bitcoin, but before you can buy any ether, you need
                  to first establish a wallet whereby you can store it. As is
                  the case with Bitcoin, there are hundreds of wallets out there
                  which allow you to not only purchase and trade ether, but also
                  store it.
                </div>
                <div className="paragraph">
                  Places where you go to purchase ether are typically referred
                  to as exchanges. Just like you will need to go to the stock
                  exchange in order to buy and sell stocks, you need to go to a
                  cryptocurrency exchange in order to purchase ether.
                  Establishing an account at a top exchange is usually as simple
                  as supply a few bits of personal information, an email to
                  validate that info, and your preferred method of purchase
                  (credit card, bank transfer, etc.). Once you have an account
                  set up, buying ether is as easy as any online purchase you
                  have ever made. Simply select the amount which you would like
                  to purchase, a payment method, and you will be able to trade
                  USD (or any other fiat currency) for ether. In fact, on most
                  exchanges you will even be able to exchange other
                  cryptocurrencies for ether.
                </div>
                <div className="paragraph">
                  Once you have made the purchase, it then comes time to store
                  and protect your ether. Luckily, most exchanges offer wallets
                  as well. An ether wallet is identical to a PayPal e-wallet, or
                  your online bank account. It is nothing more than the storage
                  location of your ether. Like your PayPal and bank accounts,
                  however, because they exist online, your account (and the
                  cryptocurrency within it) are susceptible to being
                  stolen/hacked.
                </div>
                <div className="paragraph">
                  Because of this very real fear, many people opt to store their
                  ether on external devices that, quite literally, remove the
                  ether from the internet. Because this is a cryptocurrency we
                  are dealing with, and because it only exists online, if you
                  remove the ether from the online network, it is unable to be
                  hacked. Though we could go into extreme detail with regard to
                  the different types of external devices, you can think of
                  storing ether on an external device in the same way you think
                  of placing files on a flash drive.
                </div>
                <div className="paragraph">
                  Making your life a bit easier nowadays is the fact that most
                  exchanges offer what is known as cold storage. Cold storage is
                  the same as taking your ether off the internet, only you are
                  enlisting a service to do the work for you. As you could have
                  probably surmised, this service comes at a price.
                </div>
                <h4 className="dark_blue">How Safe is Ethereum</h4>
                <div className="paragraph">
                  Ethereum, and the ether that exists on it, is an inherently
                  safe system to use because of the ecrypted blockchain. As was
                  mentioned in the beginning, every transaction on the ether
                  network is logged in the public ledger, or blockchain. While
                  this is true, the transactions are logged in a way where the
                  users, and all of their personal information, is kept secret.
                  Understanding this, Ethereum is far saferto use than any
                  conventional system we have in place now, because the chances
                  of your provided personal information leaking are slim to
                  none.
                </div>
                <div className="paragraph">
                  Where people can and have run into trouble before, however, is
                  with wallets. The reason for this is due to the fact that
                  ether, cryptocurrency wallets exist in much the same way that
                  your PayPal account does. Because it is online, and because
                  the information you provide the wallet in order to setup your
                  accunt is stored on a private server, there is the chance that
                  your account does get hacked. The scary part about all of this
                  is that, in the event of a hack, if your ether is stolen and
                  transferred away from your wallet, there is no recourse; your
                  ether will be gone forever.
                </div>
                <div className="paragraph">
                  Therein lies the Catch 22 of Ethereum and ether. Though the
                  Ethereum network strives to prevent any type of hacking or
                  centralized data storage, the ether that is traded on a daily
                  basis is done through sites that do not work on the Ethereum
                  network, and are therefore susceptible to being hacked. While
                  the incidence of ether being stolen is not very high, it has
                  happened before. That is why it is always recommended to store
                  your ether offline so that hacking can never occur.
                </div>
                <h4 className="dark_blue">Ethereum Price History</h4>
                <div className="paragraph">
                  Like most cryptocurrencies, the price of Ethereum has changed
                  dramatically from when it was first introduced in 2015. During
                  the very early days, Ethereum was trading right around $1.
                  After some 7 or 8 months, however, the price of Ethereum
                  slowly began to creep upward. By the cryptocurrency’s 1 year
                  anniversary, the price of the coin had grown by about 1200%.
                  Slowly but surely from that point forward, Ethereum creeped
                  upward in price.
                </div>
                <div className="paragraph">
                  In the early to middle parts of 2017, however, Ethereum went
                  on what can only be described as a meteoric rise. Now,
                  Ethereum has retained a rather lofty value and is growing in
                  popularity every day that this is true. There is no saying
                  what the future holds, but it is clear to see that Ethereum is
                  slowly but surely entering the mainstream.
                </div>
                <div className="paragraph"></div>
              </div>
            )}
            {topic === "ethereum-online-casinos" && (
              <div>
                <h1 className="dark_blue">
                  <strong>Ethereum Online Casinos</strong>
                </h1>
                <div className="paragraph">
                  If you have any familiarity with Bitcoin, understanding the
                  concept of Ethereum will be infinitely more feasible. If not,
                  let us give you a little bit of insight. Both Bitcoin and
                  Ethereum are what have become known as cryptocurrencies and
                  operate on what is known as a blockchain. Through their own
                  unique networks, transactions occur and are fundamentally
                  based around currencies specifically designed to be used
                  within the blockchain. The blockchain is nothing more than a
                  ledger by which anyone, anywhere can see each and every
                  transaction that has occurred from the beginning of time up
                  until the present. This is something people love because it
                  breeds transparency in that no shady dealings or secret
                  transactions can ever take place because they are all public,
                  recorded, and published.
                </div>
                <div className="paragraph">
                  Though these fundamental aspects of Ethereum and Bitcoin are
                  similar, the similarities really stop beyond that.
                </div>
                <h3 className="dark_blue">
                  Difference Between Bitcoin and Ethereum
                </h3>
                <div className="paragraph">
                  From that first section, it sounds a lot like Ethereum and
                  Bitcoin are two garments cut from the same cloth; and in many
                  ways they are. But while Bitcoin exists as a means of strictly
                  transferring value over the web, Ethereum exists as a platform
                  for people to transfer/exchange literally anything over the
                  web. Though there is a monetary aspect of Ethereum, it differs
                  from Bitcoin in that it is not solely intended as a monetary
                  exchange and trading platform. Put simply, Bitcoin is a
                  monetary-based blockchain while Ethereum can be described as a
                  general purpose blockchain.
                </div>
                <div className="paragraph">
                  Utilizing Ethereum, users and developers can create
                  decentralized applications that are governed by smart
                  contracts. Smart contracts are exactly like written contracts
                  in that two or more parties enter into the agreement, which is
                  plainly spelled out. If, for example, you wanted to create an
                  application for the creation of legal documents, you, the
                  writer, might spell out a contract with a developer that lays
                  out the type of application being built and who is responsible
                  for specific tasks. In order for anyone to create a contract
                  and use the Ethereum network to host it, they must pay. The
                  price paid for a contract depends upon many factors, including
                  the number of members, the complexity, and so on. For
                  extremely complex contracts, the fee is higher than it would
                  be for one that was simple. On Ethereum, those wanting to
                  enter into a contract pay a fee to the host of people who will
                  host the smart contract, keep it alive, and ultimately enforce
                  it. The currency paid out to these people is referred to as
                  ether. What’s more, the contracts are governed Ethereum and
                  not by any singular government or government organization.
                </div>
                <div className="paragraph">
                  In the end, the hope is that entering into agreements via
                  Ethereum and the currency that governs it will become a viable
                  alternative to seeking a lawyer and paying exorbitant costs in
                  order to create an enforceable contractual agreement. In
                  addition, Ethereum provides people who may reside in a part of
                  the world where the legal system can best be described as
                  unreliable a way to enter into agreements without fear of
                  being taken advantage of.
                </div>
                <h3 className="dark_blue">Ethereum History</h3>
                <div className="paragraph">
                  Ethereum was first derived in a publication headed by a man
                  named Vitalik Buterin, who himself was a developer who dealt
                  with Bitcoin. Buterin first crafted Ethereum with the goal of
                  creating a network of decentralized applications that worked
                  in much the same way as the popular cryptocurrency Bitcoin.
                </div>
                <div className="paragraph">
                  Ethereum was first conceived in early 2014, and by the summer
                  months a crowd-funding campaign had already gotten off its
                  feet. In that same year, Buterin was presented with a World
                  Technology Award for his new creation. Ethereum was launched
                  and became live in July of 2015 and its ether currency quickly
                  rose in value as people saw Ethereum as a way to do everything
                  that bitcoin does and more. As opposed to solely transferring
                  money from person to person, people were able to transfer all
                  sorts of services and items.
                </div>
                <div className="paragraph">
                  Ethereum’s short history is not absent any controversy as a
                  major hacking event which saw The DAO as its victim not only
                  shattered the seemingly unflappable confidence people had in
                  Ethereum, it also devalued ether considerably. The DAO is a
                  platform of sorts where thousands of Ethereum’s smart
                  contracts were being stored. When all was said and done, about
                  $50 million worth of ether was stolen from The DAO and some
                  serious&nbsp;
                  <Link to="https://www.casino.com/bitcoin-guide/transaction-speed-security-fees-and-options">
                    security concerns
                  </Link>
                  &nbsp;were raised. Ethereum has since worked its way through
                  many of these concerns and continues to grow, but just as it
                  was in the early days of Bitcoin, there have been some
                  turbulent times.
                </div>
                <h3 className="dark_blue">Online Casinos and Ethereum</h3>
                <div className="paragraph">
                  At this point, you will find almost no online casinos
                  utilizing Ethereum or, more specifically, ether. The reason
                  for this is due to the simple fact that ether cannot operate
                  outside of the Ethereum platform. This means that in order for
                  a real money Ethereum online casino to exist, it must be
                  created within Ethereum. Unlike existing websites who have
                  simply tailored their banking methods to accept bitcoins,
                  ether does not present them with such an option.
                </div>
                <div className="paragraph">
                  As Ethereum becomes more widely known and, more importantly,
                  more widely used, the widespread belief is that the existence
                  of online casino applications is more of an inevitability than
                  anything else. For now, however, cryptocurrency enthusiasts
                  will find that it is incredibly simple and straightforward to
                  convert ether into BTC. Many exchanges exist such that you can
                  quickly exchange ether for BTC and vice versa. Many of these
                  same exchanges also offer you the ability to change ether into
                  any traditional currency such as USD or Euros as well.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
