// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://www.icryptogaming.com",
    apiUrl: "https://api.icryptogaming.com/api/v1",
    staticUrl: "https://api.icryptogaming.com/api/v1",
    liveChatUrl: "https://bossoffice.icryptogaming.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://bossoffice.icryptogaming.com/public",
    socketUrl: "wss://api.icryptogaming.com:7002",
    debug: false,
    onRamperApiKey: "pk_prod_0nUzoBR07P4xJ_h7OvBO1jipOV6_eJtWnI4ewXbxInQ0", //DO not copy, only for casinobull
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://blizzapi.chimptestserver.com",
    apiUrl: "https://blizzapi.chimptestserver.com/api/v1",
    staticUrl: "https://blizzapi.chimptestserver.com/api/v1",
    liveChatUrl: "https://blizzbossoffice.chimptestserver.com/bossoffice/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://blizzbossoffice.chimptestserver.com/bossoffice/public",
    socketUrl: "wss://blizzapi.chimptestserver.com:7002",
    debug: false,
    onRamperApiKey: "pk_prod_0nUzoBR07P4xJ_h7OvBO1jipOV6_eJtWnI4ewXbxInQ0", //DO not copy, only for casinobull
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost",
    apiUrl: "http://localhost/casinoapi/api/v1",
    staticUrl: "http://localhost/casinoapi/api/v1",
    liveChatUrl: "http://localhost/casinoadmin/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://localhost/casinoadmin/public",
    socketUrl: "ws://localhost:7002",
    debug: true,
    onRamperApiKey: "pk_prod_0nUzoBR07P4xJ_h7OvBO1jipOV6_eJtWnI4ewXbxInQ0", //DO not copy, only for casinobull
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;
