import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import { __ } from "services/Translator";
export default function GamesProviders() {
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  return (
    <section className="category_games">
      <div className="container">
        <div className="category_games_header">
          <h4 className="game_headline">
            {/* <svg
              width="36"
              fill="#FF8C45"
              height="36"
              viewBox="0 0 40 29"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Group 44</title>
              <g fill="#4A90E2" fillRule="nonzero">
                <path d="M25.372 6.389h-.666a.951.951 0 0 1 0-1.903h.666a.951.951 0 0 1 0 1.903zM14.352 15.816l-2.727.002v-2.846a.912.912 0 1 0-1.823 0v2.848l-2.308.002a.912.912 0 0 0 0 1.824h.002l2.306-.002v2.448a.912.912 0 1 0 1.823 0v-2.45l2.728-.003a.912.912 0 0 0 0-1.823zM29.117 20.573c-2.175 0-3.945-1.82-3.945-4.058 0-2.238 1.77-4.059 3.945-4.059s3.944 1.82 3.944 4.059c0 2.238-1.77 4.058-3.944 4.058zm0-6.214c-1.126 0-2.042.967-2.042 2.156 0 1.188.916 2.155 2.042 2.155 1.125 0 2.04-.967 2.04-2.155 0-1.19-.915-2.156-2.04-2.156z"></path>
                <path d="M31.635 4.486h-3.331a.951.951 0 1 0 0 1.903h3.331a6.113 6.113 0 0 1 6.106 6.106v8.04a6.113 6.113 0 0 1-6.106 6.106H8.01a6.113 6.113 0 0 1-6.106-6.106v-8.04a6.113 6.113 0 0 1 6.106-6.106h14.098a.951.951 0 1 0 0-1.903h-1.373V.912a.912.912 0 1 0-1.824 0v3.574H8.01C3.593 4.486 0 8.079 0 12.495v8.04c0 4.416 3.593 8.009 8.009 8.009h23.626c4.416 0 8.009-3.593 8.009-8.01v-8.04c0-4.415-3.593-8.008-8.009-8.008z"></path>
              </g>
            </svg> */}
            {__("Game providers")}
          </h4>
          <div className="slider_controlls">
            <Button onClick={prevLoad} className="prev">
              <svg
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <title>{__("left")}</title>
                <path d="M12.586 27.414l-10-10a2 2 0 0 1 0-2.828l10-10a2 2 0 1 1 2.828 2.828L8.828 14H28a2 2 0 1 1 0 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 0 1-2.828 0z"></path>
              </svg>
            </Button>
            <Button onClick={nextLoad} className="prev next">
              <svg
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <title>{__("right")}</title>
                <path d="M19.414 27.414l10-10a2 2 0 0 0 0-2.828l-10-10a2 2 0 1 0-2.828 2.828L23.172 14H4a2 2 0 1 0 0 4h19.172l-6.586 6.586c-.39.39-.586.902-.586 1.414s.195 1.024.586 1.414a2 2 0 0 0 2.828 0z"></path>
              </svg>
            </Button>
          </div>
        </div>
        <Flickity
          flickityRef={(carouselRef) => {
            flkty.current = carouselRef;
          }}
          options={{
            groupCells: true,
            cellAlign: "left",
            prevNextButtons: false,
            pageDots: false,
          }}
          static
          className="custom_slider"
        >
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/amatic.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/belatra.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/bgaming.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/booming.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/bsg.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/endorphina.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/evolution.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/ezugi.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/gameart.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/habanero.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/isoftbet.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/nolimit.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/platipus.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/playngo.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img src={require("assets/images/providers/spinomenal.svg").default} />
            </Link>
          </div>
          <div className="single_game single_provider">
            <Link to="#">
              <img style={{width: 'unset'}} src={require("assets/images/providers/_missing.svg").default} />
            </Link>
          </div>
        </Flickity>
      </div>
    </section>
  );
}
