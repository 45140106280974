import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Table } from "react-bootstrap";

import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import GamesProviders from "elements/GamesProviders";
import Banner from "elements/Banner";
import CategoryGames from "elements/games_elements/CategoryGames";
import { __ } from "services/Translator";
import { Accordion } from "react-bootstrap";
import GamesFilter from "elements/games_elements/GamesFilter";
import SearchBox from "elements/SearchBox";
import { Helmet } from "react-helmet";

export default function Landing(props) {
  const [winnerTab, setWinnerTab] = useState("Recent Winners");

  const [recentWinners, setRecentWinners] = useState([]);
  const getRecentWinners = async () => {
    var response = await api.get("/games/recent-winners", { limit: 12 });
    if (response.status === 200) {
      setRecentWinners(response.data.data);
      // console.log('response.data', response.data);
    }
  };

  const [topWinners, setTopWinners] = useState([]);
  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners", { limit: 12 });
    if (response.status === 200) {
      setTopWinners(response.data.data);
    }
  };

  useEffect(async () => {
    getTopWinners();
    getRecentWinners();
  }, []);

  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [gameCount, setGameCount] = useState(0);
  const perPage = 18;

  const getGameList = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: ["all"],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.all?.gamelist ?? []);

      if (page === 1) {
        setGameList(response.data.games?.all?.gamelist ?? []);
      } else {
        var gamesClone = JSON.parse(JSON.stringify(gameList));
        console.log("gamesClone", gamesClone);
        gamesClone = [...gamesClone, ...response.data.games["all"].gamelist];
        setGameList(gamesClone);
      }

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
      if (response.data.gameCount) {
        setGameCount(response.data.gameCount);
      } else {
        setGameCount(0);
      }
    }
  };

  useEffect(() => {
    getGameList(1);
  }, [gameSorting, props.selectedProviders]);

  const onPageChange = (page) => {
    getGameList(page);
  };

  return (
    <>
      <Helmet>
        <title>
          {__("Best Bitcoin Casino For Crypto Gambling Games | Icrypto Gaming")}
        </title>
        <meta
          name="description"
          content={__(
            "Icrypto Gaming is a leading online crypto gaming casino in the US. Discover the best collection of crypto games to play and earn with exclusive bonuses and promotions."
          )}
        />
        <link
          rel="canonical"
          href={"https://www.icryptogaming.com" + window.location.pathname}
        />
      </Helmet>
      <Banner {...props} />

      <GamesFilter {...props} searchBox={true} />

      <CategoryGameSlider {...props} categoryName="New Games" />
      <CategoryGameSlider {...props} categoryName="Slots" />
      <CategoryGameSlider {...props} categoryName="Table Games" />
      <CategoryGameSlider {...props} categoryName="Live Casino" />
      <GamesProviders {...props} />

      <CategoryGames
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        gameList={gameList}
        category="popular"
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        isAuthenticated={props.isAuthenticated}
        showPagination={true}
        pagination={pagination}
        gameCount={gameCount}
        perPage={perPage}
        onPageChange={onPageChange}
        {...props}
      />

      <section className="winner_section">
        <div className="container">
          <h4 className="game_headline">
            <img
              alt=""
              className="icon"
              src={require("assets/images/icons/coin.svg").default}
            />
            {__("Recent Winners")}
          </h4>
          <br />
          <div className="winner_toggle">
            <div
              onClick={() => {
                setWinnerTab("Recent Winners");
              }}
              className={
                winnerTab === "Recent Winners"
                  ? "toggle_btn active"
                  : "toggle_btn"
              }
            >
              {__("Recent Winners")}
            </div>
            <div
              onClick={() => {
                setWinnerTab("Top Winners");
              }}
              className={
                winnerTab === "Top Winners" ? "toggle_btn active" : "toggle_btn"
              }
            >
              {__("Top Winners")}
            </div>
          </div>
          <div className="winner_table">
            <Table>
              <thead>
                <tr>
                  <td>{__("Game")}</td>
                  <td>{__("User")}</td>
                  <td>{__("Bet")}</td>
                  <td>{__("Win")}</td>
                  {/* <td>Payout</td> */}
                  <td>{__("Profit")}</td>
                </tr>
              </thead>

              <tbody>
                {winnerTab === "Recent Winners" && (
                  <>
                    {recentWinners.length > 0 &&
                      recentWinners.map((value, index) => (
                        <React.Fragment key={index}>
                          {props.isMobileDeviceByViewPort ? (
                            <tr>
                              <td>
                                <Link
                                  to={"/play/" + value.game?.slug}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <img src={value.game?.game_snapshot_url} />
                                  <div>
                                    {value.game?.game_name} <hr />
                                    {value.player?.player_nickname
                                      ? value.player?.player_nickname
                                      : __("Anonymous")}
                                  </div>
                                </Link>
                              </td>
                              <td>
                                {__("Bet")}: ${value.bet_amount}
                                <hr />
                                {__("Win")}: ${value.win_amount}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>
                                <Link to={"/play/" + value.game?.slug}>
                                  <img src={value.game?.game_snapshot_url} />
                                  {props.isMobileDeviceByViewPort
                                    ? value.game?.game_name.substring(0, 9) +
                                      "..."
                                    : value.game?.game_name}
                                </Link>
                              </td>
                              <td>
                                {value.player?.player_nickname
                                  ? value.player?.player_nickname
                                  : __("Anonymous")}
                              </td>
                              <td>${value.bet_amount}</td>
                              <td>${value.win_amount}</td>
                              <td>${value.profit}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
                {winnerTab === "Top Winners" && (
                  <>
                    {topWinners.length > 0 &&
                      topWinners.map((value, index) => (
                        <React.Fragment key={index}>
                          {props.isMobileDeviceByViewPort ? (
                            <tr>
                              <td>
                                <Link
                                  to={"/play/" + value.game?.slug}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <img src={value.game?.game_snapshot_url} />
                                  <div>
                                    {value.game?.game_name} <hr />
                                    {value.player?.player_nickname
                                      ? value.player?.player_nickname
                                      : __("Anonymous")}
                                  </div>
                                </Link>
                              </td>
                              <td>
                                {__("Bet")}: ${value.bet_amount}
                                <hr />
                                {__("Win")}: ${value.win_amount}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>
                                <Link to={"/play/" + value.game?.slug}>
                                  <img src={value.game?.game_snapshot_url} />
                                  {props.isMobileDeviceByViewPort
                                    ? value.game?.game_name.substring(0, 9) +
                                      "..."
                                    : value.game?.game_name}
                                </Link>
                              </td>
                              <td>
                                {value.player?.player_nickname
                                  ? value.player?.player_nickname
                                  : __("Anonymous")}
                              </td>
                              <td>${value.bet_amount}</td>
                              <td>${value.win_amount}</td>
                              <td>${value.profit}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </section>

      <section className="landing_details_section">
        <div className="container">
          <div className="col-md-8 offset-md-2">
            <div className="vip_icon">
              <img src={require("assets/images/home/img-vip.svg").default} />
            </div>
            <h4 className="white weight_bold uppercase">{__("vip program")}</h4>
            <div className="paragraph white">
              {__(
                "The VIP Program at iCryptoGaming casino is out of this world! Dedicated VIP Hostesses are in touch with you at all times to ensure you're getting the best experience in the Universe.No two stars are alike, and neither are two VIPs, so our VIP Hostesses will offer you your very own lucky star with personalized rewards. Now make a wish - we'll make it happen"
              )}
            </div>
            {/* <div className="common_hr"></div> */}
            <div className="bonus_section">
              <div className="vip_icon">
                <img
                  src={require("assets/images/home/img-diamond.svg").default}
                />
              </div>
              <div className="white weight_bold">{__("Loyalty Points")}</div>
              <div className="white paragraph">
                {__(
                  "You show your loyalty by playing more, we show ours by rewarding you more. With every new level you reach, loyalty points generate faster. Play more, get more, win more."
                )}
              </div>
            </div>
            <div className="bonus_section">
              <div className="vip_icon">
                <img src={require("assets/images/home/gift.svg").default} />
              </div>

              <div className="white weight_bold">{__("Exclusive Bonuses")}</div>
              <div className="white paragraph">
                {__(
                  "Our bonuses are great, but have you seen our VIP bonuses? Every VIP level unlocks exclusive bonuses that boost your chances to the moon!"
                )}
              </div>
            </div>
            {/* <div className="common_hr"></div> */}

            <Accordion className="chimp-accordions access_control_area">
              <Accordion.Item className="chimp-accordion" eventKey="0">
                <Accordion.Header>
                  {__("PLAY AT THE BEST BITCOIN CASINO, iCryptoGaming CASINO")}
                </Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "Your best crypto casino experience is about to unfold at iCryptoGaming casino!"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "With over 2,000 casino games available and exclusive promotions, your crypto online gambling is about to level up. Start by becoming a member of iCryptoGaming casino, free of charge, and get a warm 'Hello' from our generous welcome package."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "You'll also be introduced to daily deals, available from our monthly interactive map. You can claim reloads, free spins, combos, and other deals with a swift click. iCryptoGaming casino also runs slot tournaments in iCryptoGaming Races! Fast-paced, 3 hour long races that will make any adrenaline junkie shout out from the rooftops. And that's even before seeing the prize pool: 6 mBTC (real money wins) and 60 free spins every 3 hours!"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Transactions are made easy, safe, and cheap with the currently available cryptocurrencies: BTC, BCH, ETH, DOGE, USDT, and LTC. At our crypto casino online, your personal information is kept safe and anonymous throughout your gameplay."
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="1">
                <Accordion.Header>
                  {__("Best BTC Casino Promotions")}
                </Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "We want you to get started on the right foot, so we've prepared three special deals for your first three deposits at iCryptoGaming casino. Unlike other casinos that offer you a set deposit bonus or some free spins, at iCryptoGaming casino, you can choose what you get!"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "If gambling crypto is your favorite pastime and you love to see a massive balance whenever you play your favorite casino games, then iCryptoGaming's welcome package has your back. If you're the type of gambler whose spin finger is always tingling for free spin deals, then we've got you covered as well. Check it out:"
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="2">
                <Accordion.Header>{__("Welcome Package")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "For your First Deposit Bonus, you have not one, but two different options to choose from: 110% Deposit Bonus up to 1 BTC + 300 Free Spins OR up to 1,000 Free Spin"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Hard to believe this is just for your first iCryptoGaming casino deposit, but there's more where that came from. For your Second Deposit Bonus, you can choose between a generous bonus or sets of free spins: 50% Deposit Bonus up to 2.5 BTC or Free Spins range bonus of up to 300 Free Spins."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Let's not forget about the last promotion available in the welcome package. The Third Deposit Bonus offers you a staggering 75% Deposit Bonus up to 1.5 BTC, or Free Spins range bonus of up to 500 Free Spins!"
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="3">
                <Accordion.Header>{__("Daily Promotions")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "Spice up your real bitcoin casino games with extra reloads, spins, or special deals!"
                    )}{" "}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "iCryptoGaming casino members are pampered with our daily promotions accessible from the monthly interactive map. Each month, you get to enjoy a new map that offers daily prizes in exchange for deposits. Make sure you don't miss any days and take advantage of this crazy promotion! You can access it by pressing the button on the website's top right corner or finding it on our promotions page."
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="4">
                <Accordion.Header>{__("Regular Promotions")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "Being a iCryptoGaming casino member comes with many perks, and one of them is regular weekly promotions. Whether it's the first day of the week and you're feeling the Monday blues, iCryptoGaming casino is here to boost your spirits and gambling luck with Reload Frenzy! Get one 25% Reload Bonus each Monday with a simple click."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "If you find yourself stuck in a boring routine and want to spice things up, then you can always take advantage of the Midweek Madness promotion. Every Wednesday and Thursday, you can claim three bonuses of up to 30% Bonus! Which is more than enough to increase your playtime, luck, and crypto balance."
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="5">
                <Accordion.Header>{__("iCryptoGaming Races")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "If you're one of those players who want to feel an adrenaline rush and win real money wins (yes, with 0x wagering), you should definitely try iCryptoGaming Races!"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "These fast-paced, three hour-long slot tournaments run all day, every day, and offer a generous 6 mBTC + 60 Free Spins prize pool every round."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "What's more is that winning your spot on the podium depends on your win multiplier, not your bet amount. In other words, your position on the leaderboard is determined by one lucky spin!"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Moreover, all you have to do to enter this exciting competition is playing any slot you want at iCryptoGaming casino. Once you hit the spin button, you are in the race. So, get set, ready, spin!"
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="6">
                <Accordion.Header>{__("Bitty Quiz")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "iCryptoGaming casino rewards wits with fun and generous trivia game - Bitty Quiz! Answer ten questions correctly, and you can be the winner of the 5,000 Free Spins prize pool."
                    )}
                    &nbsp;
                    <br />
                    {__(
                      "We've got the questions; do you have the right answers?"
                    )}
                    <br />
                    {__(
                      "There's only one way to find out - play Bitty Quiz now!"
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="7">
                <Accordion.Header>{__("Refer a Friend")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "Share the fun of playing at iCryptoGaming casino with a friend, and both of you will be rewarded with a massive reward - 200 Free Spins and a special 30% Deposit Bonus!"
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="8">
                <Accordion.Header>{__("Stellar VIP Program")}</Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "iCryptoGaming casino VIP players embark on a stellar journey. Depending on your level, you can have access to a higher number of perks. You can win anything from reloads to comp points, no deposit free spins, etc. You can take advantage of the VIP Friday Reload, where you can claim between 1x 20% and 2x 100% reload bonuses once every Friday."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "There's also a daily VIP Cashback. Depending on your VIP level, you can claim between 2% and 20% cashback daily. The cashback is based on real money losses."
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="9">
                <Accordion.Header>
                  {__("Bitcoin Casino Games Available")}
                </Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "It's time to play at one of the best crypto gambling sites online!"
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Our selection of over 2,000 crypto casino games from various software providers offers an exciting and varied entertainment offer. You can play anything from bitcoin slots, baccarat, blackjack, poker, roulette, keno, dice, and more with your favorite device. Yes, iCryptoGaming casino can be enjoyed on all devices as long as you have a steady internet connection. Simply access it from the browser and enjoy instant play on your favorite games."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Moreover, you can play your favorite table games at our casino bitcoin online with a high-quality and smooth streaming live dealer gameplay! Choose your lobby and interact with other players or even dealers. It's about time you mixed the brick-and-mortar experience with the virtual and ditch the hustle of traveling and extra expenses. As mentioned earlier, since the platform is compatible with all devices, you can take your mobile bitcoin casino experience anywhere with you. That also includes live dealers, slots, and more."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Free crypto gambling is also an option at iCryptoGaming casino. You can play any slot you want in demo mode and decide how much crypto you want to bet."
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="10">
                <Accordion.Header>
                  {__("iCryptoGaming casino Live Dealer")}
                </Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "For players who want to see the outcome of the gameplay unfold in front of their eyes, there is the option of live dealer casino games. With a generous offer of over 50 live dealer games, players can choose to play blackjack, roulette, poker, Dream Catcher, baccarat, and other variations."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "The stream has an HD quality that ensures a smooth viewing of the game. Depending on the lobby you choose to enter, you can even have a conversation with other players and even the dealer."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "The iCryptoGaming casino live dealer experience offers fans of the brick-and-mortar casino the opportunity to enjoy the best of both worlds, a click away."
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="chimp-accordion" eventKey="11">
                <Accordion.Header>
                  {__("iCryptoGaming casino Live Dealer")}
                </Accordion.Header>
                <Accordion.Body className="myaccount_input_area">
                  <p className="paragraph white">
                    {__(
                      "Because at iCryptoGaming casino, we want to provide customers with the best casino experience, it was an obvious decision to choose cryptocurrencies for transactions. Online casinos are often under high scrutiny and regulations; using cryptocurrencies for transactions makes those issues disappear and lets players have more fun with fewer worries."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "A casino crypto offers a better alternative for transactions and ensures that players enjoy bigger profits once they win a jackpot."
                    )}

                    <br />
                    {__("We also chose this method because it is:")}

                    <br />
                    {__(
                      "Safer – Due to the public ledger, your transactions are visible, and provide proof of payment and withdrawal."
                    )}

                    <br />
                    {__(
                      "Cheaper – There's no other hidden fee you have to worry about except for the blockchain transaction fee."
                    )}

                    <br />
                    {__(
                      "Anonymous – You can protect your personal data by gambling with cryptocurrencies online, and you don't have to record your BTC online casino transactions on credit cards or bank statements."
                    )}

                    <br />
                    {__(
                      "Decentralized – That means that only you have complete control over your funds."
                    )}
                  </p>
                  <p className="paragraph white">
                    {__(
                      "The faster you become a iCryptoGaming member, the quicker you can gain access to the following benefits:"
                    )}
                  </p>
                  <p className="paragraph white">
                    <ul>
                      <li>{__("A stellar welcome package")}</li>
                      <li>{__("A win-win refer a friend program")}</li>
                      <li>{__("Daily promotions")}</li>
                      <li>{__("Over 2,000 casino games")}</li>
                      <li>
                        {__(
                          "iCryptoGaming Races, daily 3-hour long slot tournaments"
                        )}
                      </li>
                      <li>
                        {__(
                          "A prolific selection of over 380 bitcoin table games"
                        )}
                      </li>
                      <li>{__("A unique game of Bitty Quiz")}</li>
                      <li>{__("Friendly and supportive customer care")}</li>
                      <li>{__("VIP program out of this world")}</li>
                      <li>{__("Special deals")}</li>
                    </ul>
                  </p>
                  <p className="paragraph white">
                    {__(
                      "Kick start your gaming adventure at one of the best online crypto casino!"
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}
