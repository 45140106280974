import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { __ } from "services/Translator";

export default function MonthlyPromotions() {
  const [campaign, setCampaign] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  //   const day = [];

  //   for (let index = 1; limit =31, index < day.length; index++) {
  //     day.push([index]);
  //   }
  //   console.log("element", day);

  return (
    <>
      <div className="monthly_promotion">
        <div className="monthly_promotion_top">
          <div className="top_image">
            <img
              src={
                require("assets/images/banners/promotion-title.png").default
              }
            />
          </div>
        </div>
        <div className="monthly_promotion_calender">
          <div className="date_time">
            <div className="text">{__("Today's promotion will end in")}:</div>
            <div className="time">18h:06m:45s</div>
          </div>

          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day31"
            to="#"
          >
            <span>31</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day30"
            to="#"
          >
            <span>30</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day29"
            to="#"
          >
            <span>29</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day28"
            to="#"
          >
            <span>28</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day27"
            to="#"
          >
            <span>27</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day26"
            to="#"
          >
            <span>26</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day25"
            to="#"
          >
            <span>25</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day24"
            to="#"
          >
            <span>24</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day23"
            to="#"
          >
            <span>23</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day22"
            to="#"
          >
            <span>22</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day21"
            to="#"
          >
            <span>21</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day20"
            to="#"
          >
            <span>20</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="upcoming day19"
            to="#"
          >
            <span>19</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="current currentDay day18"
            to="#"
          >
            <span>18</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day17"
            to="#"
          >
            <span>17</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day16"
            to="#"
          >
            <span>16</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day15"
            to="#"
          >
            <span>15</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day14"
            to="#"
          >
            <span>14</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day13"
            to="#"
          >
            <span>13</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day12"
            to="#"
          >
            <span>12</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day11"
            to="#"
          >
            <span>11</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day10"
            to="#"
          >
            <span>10</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day9"
            to="#"
          >
            <span>9</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day8"
            to="#"
          >
            <span>8</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day7"
            to="#"
          >
            <span>7</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day6"
            to="#"
          >
            <span>6</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day5"
            to="#"
          >
            <span>5</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day4"
            to="#"
          >
            <span>4</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day3"
            to="#"
          >
            <span>3</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day2"
            to="#"
          >
            <span>2</span>
          </Link>
          <Link
            onClick={() => {
              setCampaign(true);
            }}
            className="past achieved day1"
            to="#"
          >
            <span>1</span>
          </Link>
        </div>
      </div>

      <Modal className="campaign_modal" show={campaign}>
        <img
          className="popup_img"
          src={require("assets/images/banners/bonus-popup.svg").default}
        />
        <Modal.Body className="campaign_modal_content">
          <div className="offers">
            <Link
              to="#"
              className="close_button"
              onClick={() => {
                setCampaign(false);
              }}
            >
              <svg
                width="19"
                height="19"
                className="close-button__IconClose-sc-1o0pd70-1 bMKQZK"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>close</title>
                <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
              </svg>
            </Link>
            <div className="paragraph">
              {__("You can claim three sets of spins of your choice and play them on the coolest BGAMING slots at iCryptoGaming casino!")}
              
            </div>
            <div className="paragraph">
              {__("You can claim three sets of spins of your choice and play them on the coolest BGAMING slots at iCryptoGaming casino!")}
              
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
