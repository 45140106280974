import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import api from "services/api";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

export default function Promotions(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");

    if (response.status === 200) {
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__(
            "iCryptoGaming | Increase your chance of winning and add up to double the play time with superb promotions at iCryptoGaming!"
          )}
        </title>
        <meta
          name="description"
          content={__(
            "iCryptoGaming! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
        <link
          rel="canonical"
          href={"https://www.icryptogaming.com" + window.location.pathname}
        />
        <meta
          property="og:title"
          content={__(
            "icryptogaming | Deposit Bonuses that increase your chance of winning and add up to double the play time!"
          )}
        />
        <meta
          property="og:description"
          content={__(
            "icryptogaming! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
        <meta
          property="og:image"
          content={
            require("../assets/images/banners/promotions-banner.jpg").default
          }
        />
        <meta
          property="twitter:title"
          content={__(
            "icryptogaming | Deposit Bonuses that increase your chance of winning and add up to double the play time!"
          )}
        />
        <meta
          property="twitter:description"
          content={__(
            "icryptogaming! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
        <meta
          property="twitter:image"
          content={
            require("../assets/images/banners/promotions-banner.jpg").default
          }
        />
      </Helmet>

      <div className="promotions">
        <div className="container">
          <div className="promotion_list">
            <div className="row">
              {promotions.map((promotion, index) => (
                <div key={index} className="col-12 col-sm-6 col-md-4">
                  <div className="single_promotion">
                    <div className="promotions_banner">
                      <Link to="/promotions/slug">
                        <img src={promotion.image} />
                      </Link>
                    </div>
                    <div className="promotions_details">
                      <div className="promotion_title dark_white">
                        {promotion.title}
                      </div>
                      <div
                        className="paragraph dark_white text-center no_margin"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(promotion.details),
                        }}
                      ></div>
                      <div className="more_btn">
                        <Link
                          className="load_more_btn no_margin"
                          to={"/promotion/" + promotion.promotion_id}
                        >
                          {__("read more")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
