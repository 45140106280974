import ls from "local-storage";

//Auth module
import PrivateRoute from "./PrivateRoute";
import OpenRoute from "./OpenRoute";
import ChatRoute from "routes/ChatRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

// Main Layout

import Landing from "pages/Landing";
import AccountActivated from "pages/AccountActivated";

// games page
import Games from "pages/Games";
import GamePlay from "pages/GamePlay";

// other pages
import Races from "pages/Races";
import Promotions from "pages/Promotions";
import PromotionDetails from "pages/PromotionDetails";
import MonthlyPromotions from "pages/MonthlyPromotions";
import Help from "pages/Help";
import HelpLists from "pages/HelpLists";
import HelpDetails from "pages/HelpDetails";
import UserDashboard from "pages/UserDashboard";
import About from "pages/About";
import Content from "pages/Content";
import BitcoinGuide from "pages/BitcoinGuide";
import Terms from "pages/Terms";
import PaymentMethods from "pages/PaymentMethods";

import Blogs from "pages/Blogs";
import BlogDetails from "pages/BlogDetails";

//Content page
import VipProgram from "pages/VipProgram";

import AffiliateProgramme from "pages/AffiliateProgramme";

// dashboard

// auth pages
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";
import AccountActivation from "pages/AccountActivation";

import Chat from "elements/Chat";

//Ticket
import TicketDetails from "pages/TicketDetails";

// const Landing = lazy(() => import("pages/Landing"));
// import { BrowserRouter, Switch, Redirect } from "react-router-dom";
class OpenRoutes extends Component {
  // const history = useHistory()
  //   useEffect(() => {
  //     const unlisten = history.listen((location, action) => {
  //       if (action !== 'POP') {
  //         window.scrollTo(0, 0)
  //       {
  //     })
  //     return () => unlisten()
  //   }, [])

  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <OpenRoute exact path="/" component={Landing}></OpenRoute>
          {/* auth pages */}
          <OpenRoute
            exact
            path="/account-activated"
            component={AccountActivated}
          ></OpenRoute>
          <OpenRoute exact path="/login" component={Login}></OpenRoute>
          <OpenRoute exact path="/register" component={Register}></OpenRoute>
          <OpenRoute
            exact
            path="/recover-password"
            component={RecoverPassword}
          ></OpenRoute>
          {/* <OpenRoute
            exact
            path="/reset-password"
            component={ResetPassword}
          ></OpenRoute>           */}
          <OpenRoute
            exact
            path="/account-activation/:email?/:code?"
            component={AccountActivation}
          ></OpenRoute>

          <OpenRoute
            exact
            path="/reset-password/:userId/:code"
            component={ResetPassword}
          ></OpenRoute>

          <OpenRoute
            exact
            path="/games/:category"
            component={Games}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/play/:slug/:mode"
            component={GamePlay}
          ></OpenRoute>
          <OpenRoute exact path="/tournaments" component={Races}></OpenRoute>
          <OpenRoute
            exact
            path="/promotions"
            component={Promotions}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/promotion/:id"
            component={PromotionDetails}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/monthly-promotions"
            component={MonthlyPromotions}
          ></OpenRoute>
          <OpenRoute exact path="/help" component={Help}></OpenRoute>
          <OpenRoute
            exact
            path="/help-lists/:category"
            component={HelpLists}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/help-details/:slug"
            component={HelpDetails}
          ></OpenRoute>
          <OpenRoute exact path="/about/:topic?" component={About}></OpenRoute>
          <OpenRoute
            exact
            path="/content/:topic?"
            component={Content}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/bitcoin-guide/:topic?"
            component={BitcoinGuide}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/terms-and-conditions/:topic?"
            component={Terms}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/payment-methods"
            component={PaymentMethods}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/vip-program"
            component={VipProgram}
          ></OpenRoute>

          <OpenRoute
            exact
            path="/affiliate-programme"
            component={AffiliateProgramme}
          ></OpenRoute>

          <OpenRoute exact path="/blogs" component={Blogs}></OpenRoute>
          <OpenRoute
            exact
            path="/blog-details/:slug"
            component={BlogDetails}
          ></OpenRoute>

          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <PrivateRoute exact path="/" component={Landing}></PrivateRoute>
          <PrivateRoute
            exact
            path="/games/:category"
            component={Games}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/play/:slug/:mode"
            component={GamePlay}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/tournaments"
            component={Races}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/promotions"
            component={Promotions}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/dashboard/:tab?"
            component={UserDashboard}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/promotion/:id"
            component={PromotionDetails}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/monthly-promotions"
            component={MonthlyPromotions}
          ></PrivateRoute>
          <PrivateRoute exact path="/help" component={Help}></PrivateRoute>
          <PrivateRoute
            exact
            path="/help-lists/:category"
            component={HelpLists}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/help-details/:slug"
            component={HelpDetails}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/about/:topic?"
            component={About}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/content/:topic?"
            component={Content}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/bitcoin-guide/:topic?"
            component={BitcoinGuide}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/terms-and-conditions/:topic?"
            component={Terms}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/payment-methods"
            component={PaymentMethods}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/vip-program"
            component={VipProgram}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/affiliate-programme"
            component={AffiliateProgramme}
          ></PrivateRoute>

          {/* auth pages */}
          <PrivateRoute exact path="/login" component={Login}></PrivateRoute>
          <PrivateRoute
            exact
            path="/register"
            component={Register}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/recover-password"
            component={RecoverPassword}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/reset-password"
            component={ResetPassword}
          ></PrivateRoute>

          <PrivateRoute exact path="/blogs" component={Blogs}></PrivateRoute>
          <PrivateRoute
            exact
            path="/blog-details/:slug"
            component={BlogDetails}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/support/ticket/:id"
            component={TicketDetails}
          ></PrivateRoute>

          <ChatRoute exact path="/chat" component={Chat}></ChatRoute>
          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
