import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";

import api from "services/api";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

const ContentDetails = (props) => {
  const history = useHistory();
  const contentPage = props.contentPage;

  const [pageContent, setPageContent] = useState([]);
  const [pageUid, setPageUid] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: pageUid,
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    if (props.contentPage == "about-us") {
      setPageUid("about_us");
    } else if (props.contentPage == "bonuses-promotions") {
      setPageUid("bonuses_promotions");
    } else if (props.contentPage == "privacy-policy") {
      setPageUid("privacy_policy");
    } else if (props.contentPage == "vip-program") {
      setPageUid("vip_program");
    } else if (props.contentPage == "bitcoin-gambling") {
      setPageUid("bitcoin_gambling");
    } else if (props.contentPage == "complaints") {
      setPageUid("complaints");
    } else if (props.contentPage == "responsible-gaming") {
      setPageUid("responsible_gaming");
    } else if (props.contentPage == "gaming_license") {
      setPageUid("gaming_license");
    } else if (props.contentPage == "terms-conditions") {
      setPageUid("terms_conditions");
    } else if (props.contentPage == "player-terms") {
      setPageUid("player_terms");
    } else if (props.contentPage == "affiliate-terms") {
      setPageUid("affiliate_terms");
    } else if (props.contentPage == "bonus-terms") {
      setPageUid("bonus_terms_conditions");
    } else if (props.contentPage == "vip-terms") {
      setPageUid("vip_terms");
    } else if (props.contentPage == "aml-policy") {
      setPageUid("aml_policy");
    }
  }, [props]);

  useEffect(async () => {
    if (pageUid != "") {
      getContentPage();
    }
  }, [pageUid]);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const pageTitles = {
    "terms-conditions": __(
      "iCryptoGaming | Terms and Conditions of iCryptoGaming which explain all our rules and regulations."
    ),
    "privacy-policy": __(
      "iCryptoGaming | Privacy of our players is guaranteed, read all about it here in our Privacy Policy."
    ),
    "bonus-terms": __(
      "iCryptoGaming | Big deposit bonuses, hundreds of Free Spins to have extra fun and play time!"
    ),
    "responsible-gaming": __(
      "iCryptoGaming | Never wager more than you can afford and keep gambling fun and safe for you and your loved ones."
    ),
    "aml-policy": __(
      "iCryptoGaming | iCG is compliant with all world-wide rules, our AML Policy has all information for you to read."
    ),
    "about-us": __(
      "iCryptoGaming | The most rewarding crypto casino experience of the online gambling Industry."
    ),
  };

  const metaDescriptions = {
    "terms-conditions": __(
      "iCryptoGaming | Terms which benefit the players as well as the best casino odds and RTP!"
    ),
    "privacy-policy": __(
      "iCryptoGaming! See what data we collect about you and how we process it. Your Privacy is of the upmost importance to us!"
    ),
    "bonus-terms": __(
      "iCryptoGaming! Read this section before accepting our Bonuses as it explains everything you need to know about them!"
    ),
    "responsible-gaming": __(
      "iCryptoGaming! Playing casino games should be, and remain, fun. Never wager more than you can afford. Read this page how to prevent problematic gambling behaviour."
    ),
    "aml-policy": __(
      "iCryptoGaming! Read all the information regarding what we do to prevent money laundering, criminal activities, and make sure all is legit."
    ),
    "about-us": __(
      "iCryptoGaming! Best crypto betting website where players can deposit and payout in crypto, more fun, more winnings!"
    ),
  };

  return (
    <>
      <Helmet>
        <title>
          {pageTitles[props.contentPage]
            ? pageTitles[props.contentPage]
            : pageTitles["terms-conditions"]}
        </title>
        <meta
          name="description"
          content={
            metaDescriptions[props.contentPage]
              ? metaDescriptions[props.contentPage]
              : metaDescriptions["terms-conditions"]
          }
        />
        <link
          rel="canonical"
          href={"https://www.icryptogaming.com" + window.location.pathname}
        />
        <meta
          property="og:title"
          content={
            pageTitles[props.contentPage]
              ? pageTitles[props.contentPage]
              : pageTitles["terms-conditions"]
          }
        />
        <meta
          property="og:description"
          content={
            metaDescriptions[props.contentPage]
              ? metaDescriptions[props.contentPage]
              : metaDescriptions["terms-conditions"]
          }
        />
        <meta
          property="og:image"
          content={require("../assets/images/logos/icrypto-logo.svg").default}
        />
      </Helmet>

      <div className="about_article">
        <h1 className="dark_blue">
          <strong>
            {pageContent != "" && pageContent.content_data.page_title}
          </strong>
        </h1>
        <div className="paragraph">
          {pageContent != "" && renderHTML(pageContent.content_data.content)}
        </div>
      </div>
    </>
  );
};

export default ContentDetails;
