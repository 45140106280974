import React, { useState, useEffect, useContext } from "react";
import api from "services/api";
import { useParams, useLocation, useHistory } from "react-router-dom";
import CategoryGames from "elements/games_elements/CategoryGames";
import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import GamesFilter from "elements/games_elements/GamesFilter";
import GameContentDetails from "elements/games_elements/GameContentDetails";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

export default function Games(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  // const __ = (text) => text; //Placeholder for future translations function

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    blackjack: "blackjack",
    "video-poker": "table_games",
    "live-dealer": "live_casino",
    "live-casino": "live_casino",
    roulette: "roulette",
    baccarat: "baccarat",
    jackpot: "jackpot",
    "table-games": "table_games",
    "special-games": "special_games",
    lottery: "lottery",
    keno: "keno",
    search: "search_results",
  };

  const gameType = params.category ? params.category : "all";

  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  const [gameCount, setGameCount] = useState(0);
  const perPage = 18;

  const getGames = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
      if (response.data.gameCount) {
        setGameCount(response.data.gameCount);
      } else {
        setGameCount(0);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
      if (response.data.gameCount) {
        setGameCount(response.data.gameCount);
      } else {
        setGameCount(0);
      }
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);


  const pageTitles = {
    slots: __("Top Bitcoin Casino for Beginners With Trusted Bitcoin Slots"),
    "live-casino": __(
      "Top crypto casino to play real casino games with a cryptocurrency"
    ),
    "table-games": __("Top crypto website to Play highest paying bitcoin table games"),
    popular: __(
      "iCryptoGaming | Wagering on the most popular games provided by Prgamtic Play, NetEnt, Betsoft, Hacksaw, and more…"
    ),
    new: __("iCryptoGaming | Newest crypto casino games are aded weekly so there is always something fresh to play!"),
    search_results: __("iCryptoGaming | The crypto casino with over 4.000 of games to choose from!"),

    all: __("Best website to Play 4000+ Online Crypto Casino Games to earn real money"),
    blackjack: __("Play live Blackjack games online with crypto | No Deposit Bonus"),
    roulette: __("Best collection of American roulette wheel games with crypto 2022"),
    baccarat: __("Baccarat Casino in the US | Online Baccarat for real money"),
  };

  const metaDescriptions = {
    slots: __(
      "Icrypto Gaming is a Top online Bitcoin Casino With the Best gambling Slots. Select from 3000+ slot games with cryptocurrency and exclusive bonuses. Join now!"
    ),
    "live-casino": __(
      "Icrypto Gaming is the best Online Bitcoin Live Casino in the USA. Bet on the best BTC Live casino site and other cryptocurrencies including Bitcoin, Ethereum, USDT & more."
    ),
    "table-games": __(
      "Icrypto Gaming is a leading online website to get the best collection of the highest-paying bitcoin table games with exclusive welcome bonuses in 2022.  Play Now!"
    ),
    popular: __("iCryptoGaming! Here you can find the games played most by your fellow-iCryptoGaming-players."),
    new: __("iCryptoGaming! All our newest games in one handy section - check back regularly to see what is new!"),
    search_results: __(
      "iCryptoGaming! So much slots and live casino games to choose from, you will find something new with each visit, and have an endless journey of fun!"
    ),
    all: __(
      "Icrypto Gaming offers hundreds of thrilling casino games online. Get an unmatched crypto gaming experience with a variety of live dealer games and betting."
    ),
    blackjack: __(
      "Play live Bitcoin Blackjack⭐ Best crypto blackjack games from well-known game providers ✓ Play Online Blackjack with BTC, ETH, LTC, and more, online at Icrypto Gaming."
    ),
    roulette: __(
      "iCryptoGaming! So much slots and live casino games to choose from, you will find something new with each visit, and have an endless journey of fun!"
    ),
    baccarat: __(
      "Icrypto Gaming presents a large range of real-money baccarat games to play online. Enjoy the best of Punto Banco, Speed Baccarat, and more with Fast Payments and 24/7 Assistance."
    ),
  };



  return (
    <>
      <Helmet>
        <title>{pageTitles[gameType] ? pageTitles[gameType] : pageTitles["all"]}</title>
        <meta
          name="description"
          content={metaDescriptions[gameType] ? metaDescriptions[gameType] : metaDescriptions["all"]}
        />
        <link rel="canonical" href={"https://www.icryptogaming.com" + window.location.pathname} />
        <meta property="og:title" content={pageTitles[gameType] ? pageTitles[gameType] : pageTitles["all"]} />
        <meta
          property="og:description"
          content={metaDescriptions[gameType] ? metaDescriptions[gameType] : metaDescriptions["all"]}
        />
        <meta property="og:image" content={require("../assets/images/logos/icrypto-logo.svg").default} />
      </Helmet>

      <GamesFilter {...props} />

      <CategoryGames
        gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
        isSearchResults={isSearchResultsShowing}
        category={gameType}
        onPageChange={onPageChange}
        pagination={pagination}
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        isAuthenticated={props.isAuthenticated}
        gameCount={gameCount}
        perPage={perPage}
        setSearchStr={props.setSearchStr}
        {...props}
      />

      <GameContentDetails gameType={gameTypeAPISlugs[gameType]} />
    </>
  );
}
