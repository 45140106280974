import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Button, Dropdown, Collapse } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { __ } from "services/Translator";

const SearchBox = (props) => {
  const history = useHistory();
  const openExpandSearchBar = () => {
    setExpandSearchBar(true);
    searchTextBox.current.focus();
  };
  const closeExpandSearchBar = () => {
    setExpandSearchBar(false);
  };

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const searchTextBox = useRef(null);

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    props.setSelectedProviders([]);
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    props.setSearchStr(text);
    setSearchStr("");
    setSearchSuggestions([]);
    closeExpandSearchBar();
    props.setSelectedProviders([]);
    history.push("/games/search");
  };
  return (
    <>
      <div className="searchbox">
        <input
          className="searchontble"
          placeholder={__("Try looking for a game")}
          type="text"
          autocomplete="off"
          name="game_search"
          value={searchStr}
          onChange={(ev) => setSearchStr(ev.target.value)}
        />
        {/* <span className="search-icon d-block d-md-none">
          <i onClick={openExpandSearchBar} className="fal fa-search"></i>
        </span> */}
        {(expandSearchBar || !!searchSuggestions.length) && (
          <div
            onClick={() => closeExpandSearchBar(false)}
            className="invisible_overlay"
          ></div>
        )}
        {!!searchSuggestions.length && (
          <Collapse
            className=""
            style={{ zIndex: 3050 }}
            in={!!searchSuggestions.length}
          >
            <ul className="search_suggestions">
              {searchSuggestions.map((item, index) => (
                <li
                  onClick={() => doSearchByGameName(item.game_name)}
                  key={index}
                >
                  <img
                    src={item.game_snapshot_url}
                    onError={(event) =>
                      (event.target.parentElement.style.display = "none")
                    }
                  />
                  {item.game_name}
                </li>
              ))}
            </ul>
          </Collapse>
        )}
      </div>
    </>
  );
};

export default SearchBox;
