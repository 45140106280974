import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import ContentDetails from "elements/ContentDetails";
import { __ } from "services/Translator";

export default function Content(props) {
  const params = useParams();
  const topic = params.topic;
  const [topicTitle, setTopicTitle] = useState("About");

  return (
    <section className="about">
      <div className="container">
        <div className="about_content">
          <div className="about_menu">
            <div className="select_box  d-block d-lg-none">
              <DropdownButton className="select_btn" title={topicTitle}>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Terms and Conditions");
                    }}
                    to="/content/terms-conditions"
                  >
                    {__("Terms and Conditions")}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Privacy Policy");
                    }}
                    to="/content/privacy-policy"
                  >
                    {__("Privacy Policy")}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bonus Terms");
                    }}
                    to="/content/bonus-terms"
                  >
                    {__("Bonus Terms")}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Responsible Gaming");
                    }}
                    to="/content/responsible-gaming"
                  >
                    {__("Responsible Gaming")}
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("AML Policy");
                    }}
                    to="/content/aml-policy"
                  >
                    {__("AML Policy")}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("About");
                    }}
                    to="/content/about-us"
                  >
                    {__("About")}
                  </Link>
                </Dropdown.Item>

                {/* <Dropdown.Item>
                  <Link
                    onClick={() => {
                      setTopicTitle("Bonuses & Promotions");
                    }}
                    to="/about/bonuses-promotions"
                  >
                    {__("Bonuses & Promotions")}
                  </Link>
                </Dropdown.Item> */}
              </DropdownButton>
            </div>
            <ul className="d-none d-lg-block">
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Terms and Conditions");
                  }}
                  className={topic === "terms-conditions" ? "active" : ""}
                  to="/content/terms-conditions"
                >
                  {__("Terms and Conditions")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Privacy Policy");
                  }}
                  className={topic === "privacy-policy" ? "active" : ""}
                  to="/content/privacy-policy"
                >
                  {__("Privacy Policy")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Bonus Terms");
                  }}
                  className={topic === "bonus-terms" ? "active" : ""}
                  to="/content/bonus-terms"
                >
                  {__("Bonus Terms")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("Responsible Gaming");
                  }}
                  className={topic === "responsible-gaming" ? "active" : ""}
                  to="/content/responsible-gaming"
                >
                  {__("Responsible Gaming")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("AML Policy");
                  }}
                  className={topic === "aml-policy" ? "active" : ""}
                  to="/content/aml-policy"
                >
                  {__("AML Policy")}
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setTopicTitle("About");
                  }}
                  className={topic === "about-us" ? "active" : ""}
                  to="/content/about-us"
                >
                  {__("About")}
                </Link>
              </li>
            </ul>
          </div>
          <ContentDetails contentPage={topic} />
        </div>
      </div>
    </section>
  );
}
