import React, { useState, useEffect, useRef } from "react";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Button, Dropdown, Collapse } from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

// custom component
import LeftSidebar from "elements/LeftSidebar";
import NotificationBar from "elements/NotificationBar";
// import LoginModal from "elements/LoginModal";
import RegistrationModal from "elements/RegistrationModal";
import WelcomeModal from "elements/WelcomeModal";
import CashierModals from "elements/dashboard_elements/CashierModals";
// import DashboardModal from "elements/dashboard_elements/DashboardModal";
import LanguageSwitcher from "elements/LanguageSwitcher";

import { __ } from "services/Translator";
const Header = (props) => {
  // const menuOpen = props.menuOpen;
  const params = useParams();
  const gamesCategory = params.category;
  const history = useHistory();
  const location = useLocation();

  //Auth State
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const { updateUserObj } = React.useContext(AppContext);
  const [userDetails, setUserDetails] = useState(auth.getUser);

  const [isLoggedIn, setIsloggedIn] = useState(false);
  // expand searcbar

  // userMenu
  const [userMenu, setUserMenu] = useState(false);
  const toggleUserMenu = () => {
    if (userMenu == false) {
      setUserMenu(true);
    } else {
      setUserMenu(false);
    }
  };

  const [showLeftSidebar, setShowLeftSidebar] = useState(false);

  // notification
  const notificationCount = props.notificationCount;

  const [showLoginModal, setShowLoginModal] = useState(false);

  const [showRegModal, setShowRegModal] = useState(false);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);

  const openWelcomeModal = () => {
    if (isLoggedIn) {
      setShowWelcomeModal(true);
    }
  };
  useEffect(() => {
    openWelcomeModal();
    setTimeout(function () {
      setShowWelcomeModal(false);
    }, 500);
  }, []);

  const [openDashboardItem, setOpenDashboardItem] = useState("index");
  //Header wallet functionality START's here

  const callOpenDeposit = (receiveVal) => {
    setShowDashboard(true);
    setTimeout(function () {
      setShowDashboard(false);
    }, 500);
    setOpenDashboardItem("deposit");
  };

  const [showInPlay, setShowInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setShowInPlay(playPath.includes("play"));
  }, [playPath]);

  return (
    <>
      <header className={showInPlay ? "inPlay" : ""}>
        <div className="container">
          <section className="header">
            <div className="row">
              <ul className="header_menu">
                {/* <li>
                  <Link
                    onClick={() => {
                      setShowLeftSidebar(true);
                      setTimeout(function () {
                        setShowLeftSidebar(false);
                      }, 500);
                    }}
                  >
                    <svg
                      width="18"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#D8D8D8"
                        fillRule="evenodd"
                        d="M0 1c0-.552.446-1 .998-1h7.004C8.552 0 9 .444 9 1c0 .552-.446 1-.998 1H.998A.997.997 0 0 1 0 1zm0 5c0-.552.45-1 1.007-1h12.986a1 1 0 1 1 0 2H1.007A1 1 0 0 1 0 6zm0 5c0-.552.456-1 1.002-1h9.996a.999.999 0 1 1 0 2H1.002A1 1 0 0 1 0 11z"
                      ></path>
                    </svg>
                  </Link>
                </li> */}
                <li>
                  {/* <Link className="zero_opacity logoContent" to="/">
                    <div className="logoFirst">Blizz</div>
                    <div className="logoSecond">Casino</div>
                  </Link> */}
                  <Link className="header_logo" to="/">
                    {!props.isMobileDevice && (
                      <img
                        src={
                          require("assets/images/logos/icrypto-logo.svg")
                            .default
                        }
                        alt="iCrypto Logo"
                      />
                    )}
                    {props.isMobileDevice && (
                      <img
                        src={
                          require("assets/images/logos/mobile-icrypto-logo.png")
                            .default
                        }
                        alt="iCrypto Logo"
                      />
                    )}
                  </Link>
                </li>
                {!isAuthenticated && (
                  <>
                    <li className="uppercase ">
                      <Link
                        className={gamesCategory ? "active" : ""}
                        to="/games/all"
                      >
                        <i className="fas fa-dice-d20 link_icon"></i>
                        {__("play now")}
                      </Link>
                    </li>

                    <li>
                      <LanguageSwitcher {...props} />
                    </li>
                  </>
                )}

                {isAuthenticated && (
                  <>
                    <li>
                      <Link
                        className={gamesCategory == "slots" ? "active" : ""}
                        to="/games/slots"
                      >
                        {__("Casino")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          gamesCategory == "live-casino" ? "active" : ""
                        }
                        to="/games/live-casino"
                      >
                        {__("Live Casino")}
                      </Link>
                    </li>
                    <li className="d-none d-lg-block">
                      <CashierModals {...props} />
                    </li>
                    {/* <li>
                      <Link
                        to="/dashboard/notifications"
                        className={
                          "notification_icon zero_opacity " +
                          (!!notificationCount ? "active" : "")
                        }
                        title={"Notification (" + notificationCount + ")"}
                      >
                        <i className="fas fa-bell"></i>
                        <span className="notifiction_counter">
                          {notificationCount}
                        </span>
                      </Link>
                    </li> */}
                    <li>
                      <LanguageSwitcher {...props} />
                    </li>
                    <li className="headerUserSection">
                      <Link className="chat_menu_area">
                        <Dropdown>
                          <Dropdown.Toggle>
                            <i className="far fa-comments"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {/*<Dropdown.Item
                              onClick={() => {
                                props.setSupportSidebarOpen(true);
                              }}
                            >
                              {__("Support Chat")}
                            </Dropdown.Item>*/}
                            <Dropdown.Item
                              onClick={() => {
                                props.setIsChatVisible(true);
                              }}
                            >
                              {__("Players Chat")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Link>

                      <Link
                        to="#"
                        className="userNameBtn"
                        onClick={() => {
                          props.setShowLeftSidebar(true);
                          setTimeout(function () {
                            props.setShowLeftSidebar(false);
                          }, 500);
                        }}
                      >
                        {/* <i class="fa fa-bars" aria-hidden="true"></i> */}
                        <i className="fal downarrow fa-caret-down"></i>
                        <span>
                          {props.userDetails.username &&
                          props.userDetails.username !== ""
                            ? props.userDetails.username
                            : props.userDetails.first_name}
                        </span>
                      </Link>

                      {/* <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          className="user_action_btn"
                        >
                          
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user_menu">
                          <i className="fas fa-caret-up caret_top"></i>
                          <ul>
                            <li className="with_border">
                              <Link
                                onClick={() => {
                                  setShowDashboard(true);
                                  setUserMenu(false);
                                  setTimeout(function () {
                                    setShowDashboard(false);
                                  }, 500);
                                }}
                                to="#"
                              >
                                <div className="user_menu_header">
                                  <div className="progress_round">
                                    {props.accountProgress}%
                                  </div>
                                  <div className="email_address">
                                    {userDetails.email}
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/dashboard/account-details">
                                My account
                              </Link>
                            </li>
                            <li>
                              <Link to="/dashboard/cashier">Deposit</Link>
                            </li>
                            <li>
                              <Link to="/dashboard/cashier">Withdraw</Link>
                            </li>
                            <li>
                              <Link to="/dashboard/my-games">My Games</Link>
                            </li>
                            <li className="with_border">
                              <Link to="/promotions">Bonuses</Link>
                            </li>
                            <li>
                              <Link to="#" onClick={logout}>
                                Log Out
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </li>
                    {/* <li>
                      <Button className="signup_btn d-none">
                        <svg
                          width="16"
                          height="16"
                          fill="rgba(255, 255, 255, .8)"
                          className="sc-eHgmQL kZgNoq d-block d-sm-none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <title>{__("add-solid")}</title>
                          <path d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
                        </svg>
                        <span className="d-none d-sm-block">
                          {__("deposit")}
                        </span>
                      </Button>
                    </li> */}
                  </>
                )}

                {!isAuthenticated && (
                  <>
                    <li>
                      <Button
                        onClick={() => {
                          props.setModalLoginPropsData(true);
                          setTimeout(function () {
                            props.setModalLoginPropsData(false);
                          }, 500);
                        }}
                        className="signup_btn"
                      >
                        {__("Sign In")}
                      </Button>
                    </li>
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowLoginModal(true);
                          setTimeout(function () {
                            setShowLoginModal(false);
                          }, 500);
                        }}
                        to="#"
                        className="d-none"
                      >
                        <svg
                          width="18"
                          height="18"
                          fill="rgba(255, 255, 255, .8)"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28 28"
                        >
                          <path d="M14 0c7.734 0 14 6.266 14 14 0 7.688-6.234 14-14 14-7.75 0-14-6.297-14-14C0 6.266 6.266 0 14 0zm9.672 21.109C25.125 19.109 26 16.656 26 14c0-6.609-5.391-12-12-12S2 7.391 2 14c0 2.656.875 5.109 2.328 7.109C4.89 18.312 6.25 16 9.109 16a6.979 6.979 0 0 0 9.782 0c2.859 0 4.219 2.312 4.781 5.109zM20 11c0-3.313-2.688-6-6-6s-6 2.688-6 6 2.688 6 6 6 6-2.688 6-6z"></path>
                        </svg>
                        {__("Sign In")}
                      </Link>
                    </li> */}
                  </>
                )}
              </ul>
            </div>
          </section>
        </div>
      </header>

      {/*For using left sidebar only have to replace right_sidebar to left_sidebar */}
      <LeftSidebar {...props} />
      {/* <LoginModal {...props} openLoginModal={showLoginModal} /> */}
      <RegistrationModal {...props} openRegModal={showRegModal} />
      {/* <WelcomeModal openWelcomeModal={showWelcomeModal} /> */}
    </>
  );
};

export default Header;
