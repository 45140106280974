import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import Slider from "react-slick";
import moneyFormatter from "services/moneyFormatter";
import { Tooltip, Overlay, OverlayTrigger, Button } from "react-bootstrap";
import swal from "sweetalert";
import { __ } from "services/Translator";

import QucikRegCampaigns from "elements/QucikRegCampaigns";

const Banner = (props) => {
  const history = useHistory();
  const [pageName, setPageName] = useState(props.pageName);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const [walletCurrencies, setWalletCurrencies] = useState([]);
  const getWalletCurrencies = async () => {
    var response = await api.get("/wallet-currencies");
    if (response.status === 200 && response.data) {
      setWalletCurrencies(response.data);
    }
  };

  //Quick registration
  const [spinner, setSpinner] = useState(false);
  const [quickRegForm, setQuickRegForm] = useState({
    email: "",
    password: "",
    // currency: "",
    username: "",
    agreeToTerms: 0,
    bonusOffer: props.defaultRegCampaign,
    promoCode: "",
  });
  const [quickRegFormErrors, setQuickRegFormErrors] = useState({
    email: "",
    password: "",
    // currency: "",
    username: "",
    agreeToTerms: "",
  });
  const [showPass, setShowPass] = useState(false);
  const updateQuickRegForm = (field, value) => {
    setQuickRegForm({ ...quickRegForm, [field]: value });
    console.log("updateRegistrationForm", quickRegForm);
  };

  useEffect(() => {    
    updateQuickRegForm('bonusOffer', props.defaultRegCampaign.toString());
  }, [props.defaultRegCampaign]);

  const updateQuickRegInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    if (type == "checkbox") {
      if (target.checked) {
        updateQuickRegForm(field, 1);
      } else {
        updateQuickRegForm(field, 0);
      }
    } else {
      updateQuickRegForm(field, value);
    }
  };

  const validateRegisterForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);

    if (
      quickRegForm.username.length < 2 ||
      quickRegForm.username.length > 250
    ) {
      errorsTmp = { ...errorsTmp, username: __("Please enter the user name") };
      valid = false;
    } else {
      var response = checkRegDuplicateUsername(quickRegForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            username: val.username,
          };

          valid = false;
          setQuickRegFormErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (quickRegForm.email.length < 2 || quickRegForm.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (!emailValidate(quickRegForm.email)) {
      errorsTmp = {
        ...errorsTmp,
        email: __("Please enter a valid email address"),
      };
      valid = false;
    } else {
      var response = checkRegDuplicateEmail(quickRegForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            email: val.email,
          };

          valid = false;
          setQuickRegFormErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (quickRegForm.password.length < 8 || quickRegForm.password.length > 30) {
      errorsTmp = {
        ...errorsTmp,
        password: __("Your password must contain between 8 and 30 characters"),
      };
      valid = false;
    }

    // if (quickRegForm.currency.length < 1) {
    //   errorsTmp = {
    //     ...errorsTmp,
    //     currency: __("Please select your default currency"),
    //   };
    //   valid = false;
    // }

    if (!quickRegForm.agreeToTerms) {
      errorsTmp = {
        ...errorsTmp,
        agreeToTerms: __("Please accept the terms and conditions"),
      };
      valid = false;
    }

    setQuickRegFormErrors(errorsTmp);
    return valid;
  };

  //API Call
  const checkRegDuplicateEmail = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-email", {
      email: quickRegForm.email,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const checkRegDuplicateUsername = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-username", {
      username: quickRegForm.username,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = validateRegisterForm();
    if (valid) {
      setSpinner(true);
      var response = await api.post("/quick-signup", quickRegForm);
      setSpinner(false);
      console.log(response.data);
      if (response.status == 200) {
        // alert('Have to add ')
        swal({
          title: __("Registration Complete!"),
          text: __(
            "A confirmation email has been sent to the email address you have listed in your profile. Please follow the instructions in that email in order to activate your account."
          ),
          icon: "success",
        });

        setQuickRegForm({
          email: "",
          password: "",
          // currency: "",
          username: "",
          agreeToTerms: 0,
          bonusOffer: "21",
          promoCode: "",
        });
        // setShowLoginModal(false);
        // history.push("/registration-complete");
      } else {
        if (response.data.errors) {
          setQuickRegFormErrors(response.data.errors);
        } else {
          alert(response.data.message);
        }
      }
    }
  };

  //Get Banners
  const [banners, setBanners] = useState([]);
  const getBanners = async (ev) => {
    var response = await api.get("/banners");
    setBanners(response.data);
  };

  useEffect(() => {
    // getAllCountries();
    // getLanguages();
    getBanners();
    getWalletCurrencies();
    // getRegCampaigns();
  }, []);

  return (
    <>
      <section className="banner_area">
        <div className="banner_top_submenu d-none">
          <nav className="secondary-header-nav d-none d-lg-flex">
            <div className="container">
              <ul className="d-flex order-2 order-lg-1">
                <li>
                  <Link to="#">{__("New")}</Link>
                </li>
                <li>
                  <Link to="#">{__("Slots")}</Link>
                </li>
                <li>
                  <Link to="#">{__("All games")}</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <div className="banner_login_area">
                <div className="register in_landing_banner">
                  {!props.isAuthenticated && (
                    <form onSubmit={registrationAction}>
                      <h4 className="headline">
                        {__("Sign up")}! {__("It's Free")}!
                      </h4>
                      <div className="input_item">
                        <input
                          className={
                            "form-control name_value " +
                            (quickRegFormErrors.email ? "error" : "")
                          }
                          placeholder={__("E-mail Address")}
                          type="email"
                          name="email"
                          onChange={updateQuickRegInput}
                          value={quickRegForm.email}
                        />
                        <div className="error_text">
                          {quickRegFormErrors.email}
                        </div>
                      </div>
                      <div className="input_item">
                        <div className="input">
                          <input
                            className={
                              "form-control name_value " +
                              (quickRegFormErrors.password ? "error" : "")
                            }
                            placeholder={__("Choose a Password")}
                            type={showPass ? "text" : "password"}
                            name="password"
                            onChange={updateQuickRegInput}
                            value={quickRegForm.password}
                          />
                          {!showPass && (
                            <a
                              className="show_hide_password"
                              onClick={() => {
                                setShowPass(true);
                              }}
                              href="javascript:"
                            >
                              <i className="fas fa-eye"></i>
                            </a>
                          )}

                          {showPass && (
                            <a
                              className="show_hide_password"
                              onClick={() => {
                                setShowPass(false);
                              }}
                              href="javascript:"
                            >
                              <i className="fas fa-eye-slash"></i>
                            </a>
                          )}
                        </div>
                        <div className="error_text">
                          {quickRegFormErrors.password}
                        </div>
                      </div>
                      <div className="input_item">
                        {/* <div className="name">
                          {__("Username")}
                          <span className="requried_icon">*</span>
                        </div> */}
                        <input
                          type="text"
                          name="username"
                          placeholder={__("Username")}
                          onChange={updateQuickRegInput}
                          value={quickRegForm.username}
                          className={
                            "form-control name_value " +
                            (quickRegFormErrors.username ? "error" : "")
                          }
                        />
                        <div class="errorMsg">
                          {quickRegFormErrors.username}
                        </div>
                      </div>
                      {/* <div className="input_item">
                        <select
                          className={
                            "form-control name_value " +
                            (quickRegFormErrors.currency ? "error" : "")
                          }
                          onChange={updateQuickRegInput}
                          name="currency"
                          value={quickRegForm.currency}
                        >
                          <option value="">Select Currency</option>
                          {walletCurrencies.length > 0 &&
                            walletCurrencies.map((value, index) => (
                              <option key={index} value={value.id}>
                                {value.currency}
                              </option>
                            ))}
                        </select>
                        <div className="error_text">
                          {quickRegFormErrors.currency}
                        </div>
                        
                      </div> */}
                      
                        <div className="reg_bouns_area QucikRegCampaigns">
                          <QucikRegCampaigns
                            onChange={(value) =>
                              updateQuickRegForm("bonusOffer", value)
                            }
                            onChangePromoCode={(value) =>
                              updateQuickRegForm("promoCode", value)
                            }
                            promoCode={quickRegForm.promoCode}
                            value={quickRegForm.bonusOffer}
                            {...props}
                          />
                        </div>
                      
                      <div className="reg_check_area input_item">
                        <input
                          type="checkbox"
                          className="stepTerms"
                          id="agreeToTerms"
                          value={quickRegForm.agreeToTerms}
                          onChange={updateQuickRegInput}
                          name="agreeToTerms"
                        />
                        <label
                          for="agreeToTerms"
                          className="check_area width_100"
                        >
                          <div className="check_check_and_text_area round_checkbox">
                            <div className="check_box">
                              <div className="check_checked_box"></div>
                            </div>
                            <div className="check_text">
                              {__(
                                "I certify that I am the minimum legal age for gambling in my jurisdiction, and that I agree to the"
                              )}{" "}
                              <Link
                                target="_blank"
                                to="/content/terms-conditions"
                              >
                                {__("Terms & Conditions")}
                              </Link>
                            </div>
                          </div>
                        </label>
                        <div className="error_text">
                          {quickRegFormErrors.agreeToTerms}
                        </div>
                      </div>
                      <div className="input_item">
                        <button type="submit" className="login_button">
                          {__("Create Account")}
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>

              <div className="crypto_label_area">
                <div className="crypto_group">
                  <div className="paragraph white no_margin">
                    {__("Play With")}
                  </div>

                  <div className="icons">
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>BTC</Tooltip>}
                    >
                      <Button className="currency_btn" variant="secondary">
                        {moneyFormatter.currencyIcon("BTC")}
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>ETH</Tooltip>}
                    >
                      <Button className="currency_btn" variant="secondary">
                        {moneyFormatter.currencyIcon("ETH")}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>USDTE</Tooltip>}
                    >
                      <Button className="currency_btn" variant="secondary">
                        {moneyFormatter.currencyIcon("USDTE")}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>DOGE</Tooltip>}
                    >
                      <Button className="currency_btn" variant="secondary">
                        {moneyFormatter.currencyIcon("DOGE")}
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip>LTC</Tooltip>}
                    >
                      <Button className="currency_btn" variant="secondary">
                        {moneyFormatter.currencyIcon("LTC")}
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* <div className="crypto_group">
                  <div className="paragraph white no_margin">No crypto?</div>
                  <Link className="load_more_btn uppercase  no_margin">
                    buy crypto with card
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="slider_area">
                <Slider {...settings}>
                  {/* banners */}

                  {banners?.map((banner, index) => (
                    <>
                      <div className="single_slide">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xl-6">
                            <img
                              className="slider_image"
                              alt=""
                              src={banner.banner}
                            />
                          </div>
                          <div className="col-md-12 col-lg-12 col-xl-6 align-self-center">
                            <h1 className="banner_heading">{banner.title}</h1>
                            <h2 className="banner_sub_heading">
                              {__("Play the classic Play N Go slot now!")}
                            </h2>
                            <Link
                              className="banner_play_now_btn"
                              to="/games/all"
                            >
                              {__("Play Now!")}
                            </Link>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </>
                  ))}

                  {/* <div className="single_slide">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-xl-6">
                        <img
                          className="slider_image"
                          alt=""
                          src={
                            require("assets/images/banners/sliders/Pragmatic_Bigger_Bass_Bonanza_sw-promo-casino.400x480.png")
                              .default
                          }
                        />
                      </div>
                      <div className="col-md-12 col-lg-12 col-xl-6 align-self-center">
                        <h2 className="banner_heading">
                          {__("Reel in some really big wins!")}
                        </h2>
                        <h2 className="banner_sub_heading">
                          {__("Play Bigger Bass Bonanza from Pragmatic now.")}
                        </h2>
                        <Link className="banner_play_now_btn" to="/games/all">
                          {__("Play Now!")}
                        </Link>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </section>

      <section
        className={
          "d-none " + (pageName === "vip" ? "banner vip_banner" : "banner")
        }
      >
        <div className="bg_layer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-8">
                <div className="banner_text_section">
                  {pageName === "vip" && (
                    <>
                      <div className="banner_title yellow">
                        {__("Exclusive VIP Space Journey")}
                      </div>
                      <div className="banner_title vip_title">
                        {__("Join our")} <strong>{__("VIP Team")}</strong>{" "}
                        {__(
                          "in the Ultimate Crypto Journey through Space, receive"
                        )}{" "}
                        <strong>{__("Astronomical Rewards")}</strong>{" "}
                        {__("that propel you to the MOON!")}
                      </div>
                      <div className="banner_intro">
                        <div className="single_intro">
                          <div className="intro_img">
                            <img
                              alt="intro"
                              src={
                                require("assets/images/icons/featured-rocket.svg")
                                  .default
                              }
                            />
                          </div>
                          <div className="intro_text text_heading">
                            {__("2,000+ games")}
                          </div>
                        </div>
                        <div className="single_intro">
                          <div className="intro_img">
                            <img
                              alt="intro"
                              src={
                                require("assets/images/icons/cloud.svg").default
                              }
                            />
                          </div>
                          <div className="intro_text text_heading">
                            {__("Lightning-fast withdrawals")}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {pageName !== "vip" && (
                    <>
                      <div className="banner_title uppercase">
                        🎁 {__("Exclusive Welcome Package")}
                      </div>
                      <div className="banner_headline">{__("5 BTC")}</div>
                      <div className="banner_subheadline">
                        {__("+300 FREE SPINS")}
                      </div>
                      <div className="banner_intro">
                        <div className="single_intro">
                          <div className="intro_img">
                            <img
                              alt="intro"
                              src={
                                require("assets/images/icons/prize.svg").default
                              }
                            />
                          </div>
                          <div className="intro_text text_heading">
                            {__("2,000+ games")}
                          </div>
                          <div className="intro_text">
                            {__("From well-known game providers")}
                          </div>
                        </div>
                        <div className="single_intro">
                          <div className="intro_img">
                            <img
                              alt="intro"
                              src={
                                require("assets/images/icons/clock.svg").default
                              }
                            />
                          </div>
                          <div className="intro_text text_heading">
                            {__("Lightning-fast withdrawals")}
                          </div>
                          <div className="intro_text">
                            {__("Cashout in under 10 mins on average")}
                          </div>
                        </div>
                      </div>
                      <div className="banner_button d-block d-sm-block d-md-none">
                        <Link to="#">{__("Create Account")}</Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="d-none d-md-block col-md-5 col-lg-4">
                <div className="banner_login_area">
                  {!props.isAuthenticated && (
                    <div className="register in_landing_banner">
                      <h4 className="headline">{__("Sign up! It's Free!")}</h4>
                      <div className="input_item">
                        <input
                          className="form-control name_value"
                          placeholder={__("E-mail Address")}
                          type="email"
                          name="email"
                        />
                      </div>
                      <div className="input_item">
                        <input
                          className="form-control name_value"
                          placeholder={__("Choose a Password")}
                          type="password"
                          name="password"
                        />
                      </div>
                      <div className="input_item">
                        <select className="form-control name_value">
                          <option>BTC</option>
                          <option>ETH</option>
                          <option>BCH</option>
                          <option>LTC</option>
                          <option>DOG</option>
                        </select>
                      </div>

                      <div className="input_item">
                        <label for="terms" className="terms">
                          <input id="terms" type="checkbox" />
                          <span className="forgot_password margin_left">
                            {__("I Agree to")}
                            <Link to="/terms-and-conditions">
                              {" "}
                              {__("Terms & Conditions")}
                            </Link>
                          </span>
                        </label>
                      </div>
                      <div className="input_item">
                        <button className="login_button">
                          {__("Create Account")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
