import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import GameItem from "elements/games_elements/GameItem";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import { __ } from "services/Translator";

const CategoryGameSlider = (props) => {
  // const menuOpen = props.menuOpen;

  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [categoryName, setCategoryName] = useState(props.categoryName);
  const [gameListsData, setGameListsData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(18);
  const [gameType, setGameType] = useState("");

  const getGames = async () => {
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [gameType],
      // specific_games: props.specificGames,
    });
    setGameListsData([]);
    if (response.status === 200 && response.data) {
      // setPaginationData(response.data.paginationData);
      // setPageActivate(page);

      if (gameType == "popular") {
        setGameListsData(response.data.games.popular.gamelist);
      } else if (gameType == "table_games") {
        setGameListsData(response.data.games.table_games.gamelist);
      } else if (gameType == "video_slots") {
        setGameListsData(response.data.games.video_slots.gamelist);
      } else if (gameType == "video_poker") {
        setGameListsData(response.data.games.video_poker.gamelist);
      } else if (gameType == "live_casino") {
        setGameListsData(response.data.games.live_casino.gamelist);
      } else if (gameType == "jackpot") {
        setGameListsData(response.data.games.jackpot.gamelist);
      }
    }
  };

  useEffect(async () => {
    if (categoryName == "New Games") {
      setGameType("popular");
    } else if (categoryName == "Table Games") {
      setGameType("table_games");
    } else if (categoryName == "Slots") {
      setGameType("video_slots");
    } else if (categoryName == "Jackpot Games") {
      setGameType("jackpot");
    } else if (categoryName == "Poker Games") {
      setGameType("video_poker");
    } else if (categoryName == "Live Casino") {
      setGameType("live_casino");
    }
    setPage(1);
  }, [categoryName]);

  useEffect(async () => {
    if (gameType) {
      getGames();
    }
  }, [gameType]);

  return (
    <>
      <section className="category_games flickity_games">
        <div className="container">
          <div className="category_games_header">
            <h4 className="game_headline">
              {categoryName === "New Games" && (
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/playing_card.svg").default}
                />
              )}
              {categoryName === "Recommended Games" && (
                <svg
                  fill="#4DA6EA"
                  width="36"
                  height="36"
                  viewBox="0 0 40 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{__("diamond")} (2)</title>
                  <g fillRule="nonzero" fill="none">
                    <path
                      d="M39.872 17.12l-6.405-9.767A.781.781 0 0 0 32.813 7H7.217a.781.781 0 0 0-.652.351L.149 17.09a.781.781 0 0 0 .045.973l3.252 3.713a.781.781 0 1 0 1.175-1.029L2.504 18.33h10.508l5.28 18.027-5.967-6.813a.781.781 0 1 0-1.175 1.03l8.265 9.436a.781.781 0 0 0 1.175 0l8.8-10.05a.78.78 0 1 0-1.176-1.028l-6.501 7.424 5.28-18.026h10.504l-4.858 5.548a.78.78 0 1 0 1.175 1.03l5.993-6.845a.781.781 0 0 0 .065-.943zm-37.64-.352l4.983-7.565 4.961 7.565H2.232zm6.431-8.206h9.893l-4.946 7.543-4.947-7.543zm11.34.644l4.958 7.562h-9.918l4.96-7.562zm0 27.433l-5.363-18.31h10.725l-5.363 18.31zm6.405-20.515l-4.96-7.562h9.919l-4.96 7.562zm1.446.644l4.96-7.562 4.958 7.562h-9.918z"
                      fill="#F5A623"
                    ></path>
                    <path
                      d="M12.28 25.548a3.659 3.659 0 0 1-3.654-3.654.781.781 0 0 0-1.562 0 3.659 3.659 0 0 1-3.655 3.654.781.781 0 0 0 0 1.563 3.659 3.659 0 0 1 3.655 3.654.781.781 0 0 0 1.562 0 3.659 3.659 0 0 1 3.654-3.654.781.781 0 0 0 0-1.563zm-4.435 2.474a5.258 5.258 0 0 0-1.692-1.693 5.257 5.257 0 0 0 1.692-1.692 5.258 5.258 0 0 0 1.692 1.692 5.258 5.258 0 0 0-1.692 1.693zM39.219 3.127A2.346 2.346 0 0 1 36.875.783a.781.781 0 0 0-1.562 0 2.346 2.346 0 0 1-2.344 2.344.781.781 0 0 0 0 1.562 2.346 2.346 0 0 1 2.344 2.344.781.781 0 0 0 1.562 0 2.346 2.346 0 0 1 2.344-2.344.781.781 0 0 0 0-1.562zm-3.125 1.565a3.94 3.94 0 0 0-.783-.784 3.94 3.94 0 0 0 .783-.784c.223.297.487.561.784.784-.297.223-.561.487-.784.784z"
                      fill="#F5A623"
                    ></path>
                    <path
                      d="M31.467 26.2a.783.783 0 0 0-1.104 0 .787.787 0 0 0-.229.553c0 .206.084.407.229.552a.787.787 0 0 0 .552.23.787.787 0 0 0 .552-.23.787.787 0 0 0 .23-.552.787.787 0 0 0-.23-.552z"
                      fill="#000"
                    ></path>
                  </g>
                </svg>
              )}
              {categoryName === "Slots" && (
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/slot_wheel.svg").default}
                />
              )}
              {categoryName === "Table Games" && (
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/table-games.svg").default}
                />
              )}
              {categoryName === "Live Casino" && (
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/live-dealer.svg").default}
                />
              )}
              {categoryName === "Game providers" && (
                <svg
                  width="36"
                  fill="#FF8C45"
                  height="36"
                  viewBox="0 0 40 29"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{__("Group")} 44</title>
                  <g fill="#4A90E2" fillRule="nonzero">
                    <path d="M25.372 6.389h-.666a.951.951 0 0 1 0-1.903h.666a.951.951 0 0 1 0 1.903zM14.352 15.816l-2.727.002v-2.846a.912.912 0 1 0-1.823 0v2.848l-2.308.002a.912.912 0 0 0 0 1.824h.002l2.306-.002v2.448a.912.912 0 1 0 1.823 0v-2.45l2.728-.003a.912.912 0 0 0 0-1.823zM29.117 20.573c-2.175 0-3.945-1.82-3.945-4.058 0-2.238 1.77-4.059 3.945-4.059s3.944 1.82 3.944 4.059c0 2.238-1.77 4.058-3.944 4.058zm0-6.214c-1.126 0-2.042.967-2.042 2.156 0 1.188.916 2.155 2.042 2.155 1.125 0 2.04-.967 2.04-2.155 0-1.19-.915-2.156-2.04-2.156z"></path>
                    <path d="M31.635 4.486h-3.331a.951.951 0 1 0 0 1.903h3.331a6.113 6.113 0 0 1 6.106 6.106v8.04a6.113 6.113 0 0 1-6.106 6.106H8.01a6.113 6.113 0 0 1-6.106-6.106v-8.04a6.113 6.113 0 0 1 6.106-6.106h14.098a.951.951 0 1 0 0-1.903h-1.373V.912a.912.912 0 1 0-1.824 0v3.574H8.01C3.593 4.486 0 8.079 0 12.495v8.04c0 4.416 3.593 8.009 8.009 8.009h23.626c4.416 0 8.009-3.593 8.009-8.01v-8.04c0-4.415-3.593-8.008-8.009-8.008z"></path>
                  </g>
                </svg>
              )}

              {categoryName}
            </h4>
            <div className="slider_controlls">
              <Button onClick={prevLoad} className="prev">
                <svg
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <title>left</title>
                  <path d="M12.586 27.414l-10-10a2 2 0 0 1 0-2.828l10-10a2 2 0 1 1 2.828 2.828L8.828 14H28a2 2 0 1 1 0 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 0 1-2.828 0z"></path>
                </svg>
              </Button>
              <Button onClick={nextLoad} className="prev next">
                <svg
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <title>{__("right")}</title>
                  <path d="M19.414 27.414l10-10a2 2 0 0 0 0-2.828l-10-10a2 2 0 1 0-2.828 2.828L23.172 14H4a2 2 0 1 0 0 4h19.172l-6.586 6.586c-.39.39-.586.902-.586 1.414s.195 1.024.586 1.414a2 2 0 0 0 2.828 0z"></path>
                </svg>
              </Button>
            </div>
          </div>
          <Flickity
            flickityRef={(carouselRef) => {
              flkty.current = carouselRef;
            }}
            options={{
              groupCells: true,
              cellAlign: "left",
              prevNextButtons: false,
              pageDots: false,
            }}
            static
            reloadOnUpdate={true}
            className="custom_slider"
          >
            {gameListsData?.map((game, index) => (
              <GameItem
                game={game}
                isAuthenticated={props.isAuthenticated}
                key={index}
              />

              // <div className="single_game" key={index}>
              //   {/* <div className="games_badge">hot</div> */}

              //   <img
              //     className="games_img"
              //     onError={(event) =>
              //       (event.target.parentElement.style.display = "none")
              //     }
              //     src={game.game_snapshot_url}
              //   />
              //   <div className="mask_layer">
              //     <div className="game_text">
              //       <div className="game_name" title={game.game_name}>
              //         {game.game_name}
              //       </div>
              //       <div className="providers_name">{game.category}</div>
              //     </div>
              //     <div className="game_btns">
              //       <Link
              //         className="play_btn"
              //         to={"/play/" + game.slug + "/real"}
              //       >
              //         {__("Real")}
              //       </Link>
              //       {!isAuthenticated && (
              //         <Link
              //           className="play_btn"
              //           to={"/play/" + game.slug + "/demo"}
              //         >
              //           {__("Fun")}
              //         </Link>
              //       )}
              //     </div>
              //   </div>
              // </div>
            ))}

            {/* <div className="single_game">
              <div className="games_badge">hot</div>

              <img
                src={require("assets/images/games/games1.png").default}
              />
              <div className="mask_layer">
                <div className="game_text">
                  <div className="game_name">Crazy time</div>
                  <div className="providers_name">BGaming</div>
                </div>
                <div className="game_btns">
                  <Link className="play_btn" to="#">
                    Real
                  </Link>
                  <Link className="play_btn" to="#">
                    Fun
                  </Link>
                </div>
              </div>
            </div> */}
          </Flickity>
        </div>
      </section>
    </>
  );
};

export default CategoryGameSlider;
