import React, { useState } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import { __ } from "services/Translator";
import SearchBox from "elements/SearchBox";

export default function GamesFilter(props) {
  const params = useParams();
  const gamesCategory = params.category;
  return (
    <div className="games_filter">
      <div className="container">
        <div className={"game_menus " + (props.searchBox ? "with_search" : "")}>
          <ul className={props.isMobileDevice && "mobileGameMenu"}>
            <li>
              <Link
                className={gamesCategory === "all" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/all"
              >
                {__("All Games")}
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "slots" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/slots"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/slot_wheel.svg").default}
                />
                {__("Slots")}
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "table-games" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/table-games"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/table-games.svg").default}
                />
                {__("Table Games")}
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "live-dealer" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/live-casino"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/live-dealer.svg").default}
                />
                {__("Live Casino")}
              </Link>
            </li>
            <li className="d-none">
              <Link
                className={gamesCategory === "jackpot" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/jackpot"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/jackpot.svg").default}
                />
                {__("Jackpot Games")}
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "blackjack" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/blackjack"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/blackjack.svg").default}
                />
                {__("Blackjack")}
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "roulette" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/roulette"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/roulette.svg").default}
                />
                {__("Roulette")}
              </Link>
            </li>

            {/* <li>
              <Link
                className={gamesCategory === "video-poker" ? "active" : ""}
                to="/games/video-poker"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/video-poker.svg").default}
                />
                {__("Video Poker")}
              </Link>
            </li> */}

            <li>
              <Link
                className={gamesCategory === "baccarat" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/baccarat"
              >
                <img
                  alt=""
                  className="icon"
                  src={require("assets/images/icons/baccarat.svg").default}
                />
                {__("Baccarat")}
              </Link>
            </li>
          </ul>
          {props.searchBox && <SearchBox {...props} />}
        </div>
      </div>
    </div>
  );
}
