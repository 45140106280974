import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/games_elements/GameItem";
import SearchBox from "elements/SearchBox";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";
import { Badge, Table, Modal, Collapse } from "react-bootstrap";
import api from "services/api";
import { __ } from "services/Translator";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const history = useHistory();
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };
  // const __ = (text) => text;

  return (
    <>
      <div className="container">
        <div className="category_games_header">
          <h4 className="game_headline">
            {category === "slots" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/slot_wheel.svg").default}
              />
            )}
            {category === "blackjack" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/blackjack.svg").default}
              />
            )}
            {category === "video-poker" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/video-poker.svg").default}
              />
            )}
            {category === "live-dealer" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/live-dealer.svg").default}
              />
            )}
            {category === "live-casino" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/live-dealer.svg").default}
              />
            )}
            {category === "roulette" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/roulette.svg").default}
              />
            )}
            {category === "baccarat" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/baccarat.svg").default}
              />
            )}
            {category === "jackpot" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/jackpot.svg").default}
              />
            )}
            {category === "table-games" && (
              <img
                alt=""
                className="icon"
                src={require("assets/images/icons/table-games.svg").default}
              />
            )}

            {category === "all" && "All Games"}
            {category == null && "All Games"}
            {category === "slots" && "Slots"}
            {category === "blackjack" && "Blackjack"}
            {category === "video-poker" && "Video Poker"}
            {category === "live-dealer" && "Live Dealer"}
            {category === "live-casino" && "Live Casino"}
            {category === "roulette" && "Roulette"}
            {category === "baccarat" && "Baccarat"}
            {category === "jackpot" && "Jackpot Games"}
            {category === "table-games" && "Table Games"}
            {category === "keno" && "Keno"}
            {category === "lottery" && "Lottery"}
            {category === "special-games" && "Special Games"}
          </h4>
          {!props.isMobileDevice && <SearchBox {...props} />}

          <div className="game_provider_dropdoun">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="provider_filter_btn"
              >
                <span className="name">{__("Providers")}</span>
                <span className="selected">
                  {selectedProviders.length ? "Selected" : "All"}
                </span>
                <div className="icon">
                  <svg
                    width="12"
                    height="12"
                    fill="rgba(255, 255, 255, .8)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <title>{__("expand-vertical")}</title>
                    <path d="M12 2.416l5.544 5.544 1.416-1.416-6.25-6.25a1.001 1.001 0 0 0-1.416 0l-6.25 6.25L6.46 7.96l5.541-5.544zM12 21.584l-5.544-5.541-1.416 1.416 6.25 6.25a.999.999 0 0 0 1.412 0l6.25-6.25-1.416-1.416-5.538 5.541z"></path>
                  </svg>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="provider_menu">
                <div className="provider_list">
                  {providers.length > 0 &&
                    providers.map((provider, index) => (
                      <div className="item" key={index}>
                        <div className="reg_check_area">
                          <input
                            type="checkbox"
                            className="stepTerms"
                            id={provider.provider_id}
                            value={provider.provider_id}
                            onChange={() =>
                              toggleSelectedProvider(provider.provider_id)
                            }
                          />
                          <label
                            for={provider.provider_id}
                            className="check_area width_100"
                          >
                            <div className="check_check_and_text_area">
                              <div className="check_box">
                                <div className="check_checked_box"></div>
                              </div>
                              <div className="check_text">{provider.name}</div>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {props.isMobileDevice && (
          <>
            <SearchBox {...props} />
            <br />
          </>
        )}

        <div className="game_list">
          {props.gameList.map((game, index) => (
            <GameItem
              game={game}
              isAuthenticated={props.isAuthenticated}
              key={index}
            />
          ))}
        </div>

        {props.showPagination && (
          <>
            <LoadMore
              data={props.pagination}
              gameCount={props.gameCount}
              perPage={props.perPage}
              onPageChange={props.onPageChange}
            />
          </>
        )}
      </div>
    </>
  );
}
