import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { __ } from "services/Translator";
import SupportSidebar from "elements/SupportSidebar";

const Footer = (props) => {
  // const menuOpen = props.menuOpen;

  return (
    <>
      <SupportSidebar {...props} />
      <footer>
        <section className="footer">
          <div className="links_section">
            <div className="single_block">
              <ul>
                <li>
                  {/* <Link className="zero_opacity logoContent" to="/">
                    <div className="logoFirst">Blizz</div>
                    <div className="logoSecond">Casino</div>
                  </Link> */}

                  <Link className="footer_logo" to="/">
                    <img
                      src={
                        require("assets/images/logos/icrypto-logo.svg").default
                      }
                      alt="iCrypto Logo"
                    />
                  </Link>
                </li>
                {/* <li className="text_dsc">
                  iCryptoGaming – The number 1 crypto casino.
                  <br />
                  All rights reserved.
                </li> */}
                {/*<li className="d-none d-sm-block">
                  <select>
                    <option>English</option>
                    <option>Deutsch</option>
                  </select>
                </li>*/}
              </ul>
            </div>
            <div className="single_block">
              <ul>
                <li className="uppercase list_heading">{__("COMPANY")}</li>
                <li>
                  <Link to="/content/about-us"> {__("About")}</Link>
                </li>
                <li>
                  <Link to="/vip-program"> {__("VIP Program")}</Link>
                </li>
                <li>
                  <Link to="/promotions"> {__("Promotions")}</Link>
                </li>

                <li className="d-none">
                  <Link to="/tournaments"> {__("Tournaments")}</Link>
                </li>
                {/* <li>
                  <Link to="/blogs"> Blog</Link>
                </li> */}
                <li>
                  <Link to="/affiliate-programme"> {__("Affiliates")}</Link>
                </li>
              </ul>
            </div>
            <div className="single_block">
              <ul>
                <li className="uppercase list_heading">{__("GAMES")}</li>
                <li>
                  <Link to="/games/slots">{__("Slots")}</Link>
                </li>
                <li>
                  <Link to="/games/live-casino"> {__("Live Casino")}</Link>
                </li>

                <li>
                  <Link to="/games/table-games">
                    {" "}
                    {__("Video Table Games")}
                  </Link>
                </li>
                <li>
                  <Link to="/games/jackpot"> {__("Jackpot")}</Link>
                </li>
                <li>
                  <Link to="/games/special-games"> {__("Special Games")}</Link>
                </li>

                {/* <li>
                  <Link to="/games/baccarat"> Bitcoin Baccarat</Link>
                </li>
                <li>
                  <Link to="/games/blackjack">Bitcoin Blackjack</Link>
                </li>

                <li>
                  <Link to="/games/lottery"> Bitcoin Lottery</Link>
                </li> */}
                {/* <li>
                  <Link to="/games/table-games"> Bitcoin Table Games</Link>
                </li> */}
                {/* <li>
                  <Link to="/games/keno"> Bitcoin Keno</Link>
                </li> */}
              </ul>
            </div>
            <div className="single_block">
              <ul>
                <li className="uppercase list_heading">{__("RESOURCES")}</li>
                {/* <li>
                  <Link to="/content/gaming-license"> Gaming License</Link>
                </li> */}
                <li>
                  <Link to="/content/terms-conditions">
                    {__("Terms & Conditions")}
                  </Link>
                </li>
                <li>
                  <Link to="/content/privacy-policy">
                    {" "}
                    {__("Privacy Policy")}
                  </Link>
                </li>
                <li>
                  <Link to="/content/bonus-terms">
                    {__("Bonus Terms & Conditions")}
                  </Link>
                </li>
                <li>
                  <Link to="/content/aml-policy">{__("AML Policy")}</Link>
                </li>
                <li>
                  <Link to="/content/responsible-gaming">
                    {__("Responsible Gaming")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/terms-and-conditions/affiliate-terms">
                    Affiliate Terms & Conditions
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/content/complaints"> Complaints</Link>
                </li> */}
                <li>
                  <Link
                    onClick={() => {
                      props.setSupportSidebarOpen(true);
                    }}
                  >
                    {__("FAQ")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/bitcoin-guide/index"> Bitcoin Guide</Link>
                </li> */}
                {/* <li>
                  <Link to="/payment-methods"> Payment Methods</Link>
                </li> */}
              </ul>
            </div>
            <div className="single_block">
              <ul className="social_menus">
                <li>
                  <Link
                    className="sc-jAaTju kLGCoN facebook"
                    title="facebook"
                    rel="nofollow noopener noreferrer"
                    to="#"
                    target="_blank"
                  >
                    <svg
                      width="16"
                      height="16"
                      className="sc-jDwBTQ cQFZBp"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 28"
                    >
                      <title>{__("facebook")}</title>
                      <path d="M14.984.187v4.125h-2.453c-1.922 0-2.281.922-2.281 2.25v2.953h4.578l-.609 4.625H10.25v11.859H5.469V14.14H1.485V9.515h3.984V6.109C5.469 2.156 7.891 0 11.422 0c1.687 0 3.141.125 3.563.187z"></path>
                    </svg>
                  </Link>
                </li>
                <li className="sc-cMljjf cKPUFa">
                  <Link
                    className="sc-jAaTju kLGCoN twitter"
                    title="twitter"
                    rel="nofollow noopener noreferrer"
                    to="#"
                    target="_blank"
                  >
                    <svg
                      width="16"
                      height="16"
                      className="sc-gPEVay kyRstH"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 26 28"
                    >
                      <title>{__("twitter")}</title>
                      <path d="M25.312 6.375a10.85 10.85 0 0 1-2.531 2.609c.016.219.016.438.016.656 0 6.672-5.078 14.359-14.359 14.359-2.859 0-5.516-.828-7.75-2.266.406.047.797.063 1.219.063 2.359 0 4.531-.797 6.266-2.156a5.056 5.056 0 0 1-4.719-3.5c.313.047.625.078.953.078.453 0 .906-.063 1.328-.172a5.048 5.048 0 0 1-4.047-4.953v-.063a5.093 5.093 0 0 0 2.281.641 5.044 5.044 0 0 1-2.25-4.203c0-.938.25-1.797.688-2.547a14.344 14.344 0 0 0 10.406 5.281 5.708 5.708 0 0 1-.125-1.156 5.045 5.045 0 0 1 5.047-5.047 5.03 5.03 0 0 1 3.687 1.594 9.943 9.943 0 0 0 3.203-1.219 5.032 5.032 0 0 1-2.219 2.781c1.016-.109 2-.391 2.906-.781z"></path>
                    </svg>
                  </Link>
                </li>
                <li className="sc-cMljjf cKPUFa">
                  <Link
                    className="sc-jAaTju kLGCoN instagram"
                    title="instagram"
                    rel="nofollow noopener noreferrer"
                    to="#"
                    target="_blank"
                  >
                    <svg
                      width="16"
                      height="16"
                      className="sc-jlyJG guAfkN"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.44 0C1.99 0 0 1.99 0 4.44v7.11C0 14.01 1.99 16 4.44 16h7.11c2.45 0 4.44-1.99 4.44-4.44V4.44A4.428 4.428 0 0 0 11.56 0H4.44zm8.89 1.78c.49 0 .89.4.89.89s-.4.89-.89.89-.89-.4-.89-.89.4-.89.89-.89zM8 3.56c2.45 0 4.44 1.99 4.44 4.44S10.45 12.44 8 12.44 3.56 10.45 3.56 8 5.55 3.56 8 3.56zm0 1.77c-1.47 0-2.67 1.2-2.67 2.67s1.2 2.67 2.67 2.67 2.67-1.2 2.67-2.67S9.47 5.33 8 5.33z"></path>
                    </svg>
                  </Link>
                </li>

                {/* <li className="sc-cMljjf cKPUFa">
                  <Link
                    className="sc-jAaTju kLGCoN youtube"
                    title="youtube"
                    rel="nofollow noopener noreferrer"
                    to="#"
                    target="_blank"
                  >
                    <svg
                      width="16"
                      height="16"
                      className="sc-iRbamj eAbMcA"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.55 4.1c-.15-.82-.85-1.41-1.67-1.6-1.22-.26-3.49-.45-5.94-.45s-4.75.19-5.98.45C1.15 2.69.45 3.25.3 4.1.15 5.03 0 6.33 0 8s.15 2.97.33 3.9c.15.82.85 1.41 1.67 1.6 1.3.26 3.53.45 5.98.45s4.68-.19 5.98-.45c.82-.19 1.52-.74 1.67-1.6.15-.93.33-2.27.37-3.9-.08-1.67-.26-2.97-.45-3.9zm-9.61 6.5V5.4L10.47 8l-4.53 2.6z"></path>
                    </svg>
                  </Link>
                </li> */}

                {/* <li className="sc-cMljjf cKPUFa">
                  <Link
                    className="sc-jAaTju kLGCoN discord"
                    title="discord"
                    rel="nofollow noopener noreferrer"
                    to="#"
                    target="_blank"
                  >
                    <svg
                      width="16"
                      height="16"
                      className="sc-gipzik grXtwL"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.54 2.91c-1.02-.47-2.11-.81-3.26-1.01-.02 0-.04.01-.05.02-.13.26-.29.58-.4.84a12.47 12.47 0 0 0-3.66 0c-.11-.26-.27-.58-.41-.83a.055.055 0 0 0-.05-.03c-1.14.2-2.24.54-3.26 1.01-.01 0-.02.01-.02.02C.36 6.03-.21 9.05.07 12.04c0 .01.01.03.02.04 1.37 1.01 2.69 1.62 4 2.02.02.01.04 0 .06-.02.31-.42.58-.86.82-1.33.01-.03 0-.06-.03-.07-.44-.17-.85-.37-1.25-.59-.04-.03-.04-.07-.02-.09l.25-.19c.01-.01.03-.01.05-.01 2.62 1.2 5.45 1.2 8.04 0 .02-.01.04-.01.05.01.08.07.16.13.25.19.03.02.03.07 0 .09-.4.23-.81.43-1.25.59-.03.01-.04.04-.03.07.24.47.51.91.82 1.33.01.02.04.03.06.02 1.31-.4 2.63-1.01 4-2.02.01-.01.02-.02.02-.04.33-3.45-.56-6.45-2.37-9.11 0-.01-.01-.01-.02-.02zm-8.19 7.31c-.79 0-1.44-.72-1.44-1.61 0-.89.64-1.61 1.44-1.61.8 0 1.45.73 1.43 1.61 0 .89-.63 1.61-1.43 1.61zm5.31 0c-.79 0-1.44-.72-1.44-1.61.01-.89.64-1.61 1.44-1.61.81 0 1.45.73 1.44 1.61 0 .89-.63 1.61-1.44 1.61z"></path>
                    </svg>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <hr></hr>
          <div className="brands">
            <ul>
              {/* <li>
                <Link to="#">
                  <img
                    className="brand_img"
                    src={
                      require("assets/images/common/dot-igaming-solid-white.svg")
                        .default
                    }
                  />
                </Link>
              </li> */}
              <li className="dotIgamingLogo">
                <a target="_blank" href="https://dot-igaming.com">
                  <img
                    className="solidImg brand_img"
                    src={
                      require("../assets/images/common/dot-igaming-solid-white.svg")
                        .default
                    }
                  />
                  <img
                    className="hoverImg brand_img"
                    src={
                      require("../assets/images/common/dot-igaming.svg").default
                    }
                  />
                </a>
              </li>
              <li>
                <img
                  className="brand_img"
                  src={
                    require("assets/images/brands/bitcoin-white.svg").default
                  }
                />
              </li>
              <li>
                <img
                  className="brand_img"
                  src={
                    require("assets/images/brands/ethereum-white.svg").default
                  }
                />
              </li>
              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/18-white.svg").default}
                />
              </li>
              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/amatic.svg").default}
                />
              </li>

              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/betsoft.svg").default}
                />
              </li>
              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/endorphina.svg").default}
                />
              </li>
              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/evolution.svg").default}
                />
              </li>

              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/gameart.svg").default}
                />
              </li>
              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/habanero.svg").default}
                />
              </li>

              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/pragmatic.svg").default}
                />
              </li>
              <li>
                <Link to="/content/responsible-gaming">
                  <img
                    className="brand_img"
                    src={
                      require("assets/images/brands/responsiblegaming.svg")
                        .default
                    }
                  />
                </Link>
              </li>
              <li>
                <img
                  className="brand_img"
                  src={require("assets/images/brands/safe_secure.svg").default}
                />
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
          <div className="license_and_info_text">
            <div className="company_info">
              {__(
                "Copyright © 2021 iCryptoGaming.com is owned and operated by Your Company N.V., a company registered and established under the laws of Curacao."
              )}
              <br />
              {__(
                "Your Company N.V. is licensed and regulated by The Curacao Gambling Authority. (license no. 1234LICENSE/123). Your company N.V.'s registration number is 012345 and its registered address is Abraham de Veerstraat 9, Willemstad, Curaçao."
              )}{" "}
              <br />
              {__(
                "It is the player's sole responsibility to inquire about the existing laws and regulations of the given jurisdiction for online gambling. If you have any questions, please contact us at support@iCryptoGaming.com"
              )}
            </div>
            <div className="license_seal">
              <iframe
                style={{
                  width: "150px",
                  height: "50px",
                  border: "none",
                  // marginLeft: "64px",
                  verticalAlign: "bottom",
                }}
                src="https://licensing.gaming-curacao.com/validator/?lh=8b48a5061e440c4277f4091fb4d24109&template=tseal"
              ></iframe>
            </div>
          </div>
          <div className="copyright_section">
            <div className="copyright_content">
              <div className="other_logos">
                <img
                  alt=""
                  className="percent_secure_img"
                  src={
                    require("../assets/images/footer/100-percent-secure-gr.svg")
                      .default
                  }
                />
              </div>

              <div className="other_logos">
                <img
                  alt=""
                  className="provably_fair_img"
                  src={
                    require("../assets/images/footer/provably-fair-gr.svg")
                      .default
                  }
                />
              </div>
              <div className="copyright_text">
                {__("All rights reserved")} 2022 © iCryptoGaming
              </div>

              <div className="other_logos">
                <img
                  alt=""
                  className="responsible_gaming_img"
                  src={
                    require("../assets/images/footer/responsible-gaming-gr.svg")
                      .default
                  }
                />
              </div>
              <div className="other_logos">
                <img
                  className="plus_age_img"
                  alt=""
                  src={
                    require("../assets/images/footer/18-plus-age-gr.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
